import { functionName } from '@carbonmaps/shared/utils/constants';

type ChatFormInput = {
	email: string;
	message: string;
};

export const sendMessageAction = async ({ email, message }: ChatFormInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.chat.sendMessage, { email, message });

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
