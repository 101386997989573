import { Typography } from '@mui/material';
type UserFieldProps = {
	value: any;
	row: any;
	column: any;
};

// const UserField = ({ value, row, column }: UserFieldProps) => {
// 	const userName = value
// 		? value?.has('lastName') || value?.has('firstName')
// 			? `${value?.get('firstName')} ${value?.get('lastName')}`
// 			: value.get('username')
// 		: '';
// 	return <Typography>{userName}</Typography>;
// };


const UserField = ({ value, row, column }: UserFieldProps) => {
	const userName = value
		? value?.lastName || value?.firstName
			? `${value?.firstName} ${value?.lastName}`
			: value?.username
		: '';
	return <Typography>{userName}</Typography>;
};

export default UserField;
