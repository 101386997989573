import { css, cx } from '@emotion/css';
import { HelpCircle } from 'lucide-react';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useCmapsModelization } from '../packagings.hooks';

const styles = {
	text: css({
		fontSize: '24px !important',
		lineHeight: '1.2 !important',
		color: `${siteColors.grey8} !important`,
	}),
	popoverContent: css({
		padding: '16px',
		width: '220px !important',
	}),
	popover: css({
		padding: '0px !important',
		minWidth: '0px !important',
	}),
};

type KpiValueProps = {
	value?: number;
	unit?: React.ReactNode;
	precision?: number;
	className?: string;
	withHelp?: boolean;
	message?: string;
	[x: string]: any;
};

const KpiValue = ({ value, unit, className, precision = 0, withHelp, message, ...rest }: KpiValueProps) => {
	const { t } = useTranslation();

	const { data } = useCmapsModelization({});

	return (
		<div>
			{withHelp
? (
				<div className="">
					<BPopover
						modeHover
						content={() => {
							return (
								<div className={styles.popoverContent}>
									<p>{!data ?  t('Vous devez passer à la nouvelle version pour avoir cette information'): message}</p>
								</div>
							);
						}}
						transformOriginHorizontal={'left'}
						transformOriginVertical={'top'}
						anchorOriginHorizontal={'left'}
						anchorOriginVertical={'bottom'}
						className={styles.popover}
					>
						<HelpCircle color={siteColors.grey7} />
					</BPopover>
				</div>
			)
: (
				<div className={cx('number', styles.text)}>
					<span className={className} {...rest}>
						{formatNumber(value, undefined, precision)}
					</span>
					{unit}
				</div>
			)}
		</div>
	);
};

export default KpiValue;
