import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { END_OF_LIFE_COLORS } from '@carbonmaps/shared/utils/constants';

import BlueCheck from '../../../../../apps/front/src/components/BlueCheck';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { displayValue } from '../../../utils/numberFormat';
import BPopover from '../BPopover';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			// color: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.common.black,
			boxShadow: theme.shadows[2],
			fontSize: 12,
		},
	};
});

const styles = {
	link: css({
		textDecoration: 'none !important',
	}),
	container: css({
		paddingTop: 8,
		paddingBottom: 8,
		gap: 8,
		width: 250,
		maxWidth: '100%',
	}),
	iconContainer: css({
		borderRadius: '8px',
		padding: '8px',
	}),
	content: css({
		gap: '8px',
		marginBottom: 3,
	}),
	lightText: css({
		maxWidth: '300px',
		textTransform: 'capitalize',
	}),
	marginText: css({
		marginLeft: 'var(--margin-text)',
	}),
	subtitle: css({
		maxWidth: '300px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),

	'scenario-notOriginal-ingredient-label': css({
		color: cn(siteColors.teal500, '!important'),
	}),
	'scenario-original-ingredient-notIsPresent-label': css({
		color: cn(siteColors.grey700, '!important'),
		textDecoration: 'line-through',
	}),

	'scenario-notOriginal-ingredient-iconContainer': css({
		backgroundColor: '#E2EFF1 !important',
		'& svg': {
			stroke: cn(siteColors.teal500, '!important'),
		},
	}),
	'scenario-original-ingredient-notIsPresent-iconContainer': css({
		backgroundColor: 'transparent !important',
		border: cn('1px dashed', siteColors.grey600, '!important'),
		'& svg': {
			stroke: cn(siteColors.grey600, '!important'),
		},
	}),

	text2: css({
		color: siteColors.grey700,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '12px',
	}),

	labelText: css({
		// color: 'red',
		fontWeight: '400 !important',
	}),
	progressBarContainer: css({
		gap: 2,
	}),
};

const today = dayjs();

const groupEndOfLife = (endOfLife: any) => {
	if (!endOfLife || !endOfLife?.length) return [];

	const result = endOfLife.flat();

	const groupedData = _.groupBy(result, 'type');
	const results = _.sortBy(
		_.mapValues(groupedData, (items) => {
			return {
				composition: items[0].composition ?  _.sumBy(items, 'composition'): items[0].y,
				type: items[0].type,
				labelCmaps: items[0].labelCmaps,
				codeCmaps: items[0].codeCmaps,
				order: items[0].order,
				carbonImpact: _.sumBy(items, 'carbonImpact'),
				waterImpact: _.sumBy(items, 'waterImpact'),
				tonnage: _.sumBy(items, 'tonnage'),
			};
		}),
		'order',
	);
	return Object.values(results) || [];
};

const EndOfLIfeMaterialDisplay = (props: any) => {
	const { value, row, column } = props;


const popoverField = column?.props?.popoverField;
const unit = column?.props?.unit;
const precision = column?.props?.precision;

	const origin =
		_.isFunction(row?.original?.origin?.includes) && !row?.original?.origin?.includes?.('non prise en compte')
			? row.original.origin
			: false;

	const emptyValue = column?.props?.emptyValue;

	const theme = useTheme();

	const ComponentLink = MUILink;

	const transFunction = () => {
		console.log('test');
	};

	const endOfLife = row?.original?.endOfLife;

	const t = column?.props?.trans || transFunction;

	const daysUpdateDifference = today.diff(row.original.updatedAt, 'day');

	const iconContainerStyle = useMemo(() => {
		let value = 'bgGrey400';

		if (column?.props?.page === 'simulation-scenario') {
			if (row.original?.isOriginal === false) {
				value = styles['scenario-notOriginal-ingredient-iconContainer'];
			} else if (row.original?.isOriginal === true) {
				if (row.original?.isPresent === false) {
					value = styles['scenario-original-ingredient-notIsPresent-iconContainer'];
				}
			}
		}

		return value;
	}, [column?.props?.page, row.original?.isOriginal, row.original?.isPresent]);

	const labelStyle = useMemo(() => {
		let value = '';

		if (column?.props?.page === 'simulation-scenario') {
			if (row.original?.isOriginal === false) {
				value = styles['scenario-notOriginal-ingredient-label'];
			} else if (row.original?.isOriginal === true) {
				if (row.original?.isPresent === false) {
					value = styles['scenario-original-ingredient-notIsPresent-label'];
				}
			}
		}

		return value;
	}, [column?.props?.page, row.original?.isOriginal, row.original?.isPresent]);

	return (
		<div className="flexRow alignCenter gap16">
			<div className={cx(styles.link, column?.props?.sx ? css(column?.props?.sx) : '')}>
				<div
					className={cx('flexRow nowrap alignCenter', styles.container)}
					{...(column.props?.page === 'simulation-scenario' && row.original.isOriginal && !row.original.isPresent
						? {
								'data-original-disabled-ingredient': 'true',
						  }
						: {})}
				>
					{row?.original?.logoUrl ?
					(
						<div className={cx(styles.iconContainer)}>
							<img src={row?.original?.logoUrl} width={24} alt="#" />
						</div>
					) :
					 (
						row.original?.icon ||
						(column?.icon && (
							<div
								className={cx(
									// 'bgGrey400',
									styles.iconContainer,
									iconContainerStyle,
								)}
							>
								{row.original?.icon || column?.icon}
							</div>
						))
					)}

					<div
						className={styles.marginText}
						style={{
							['--margin-text' as any]: column?.props?.gap ? `${column?.props?.gap}px` : '8px',
						}}
					>
						<div className={cx('flexRow nowrap alignCenter', styles.content)}>
							{row.original.isN2ModelizationType || row?.original?.isChecked
? (
								<BlueCheck
									withIcon={
										row.original.isN2ModelizationType || row?.original?.isChecked ? row?.original?.isChecked : false
									}
									iconSize={16}
									className={column?.props?.className}
								/>
							)
: null}

							<LightTooltip title={value}>
								<Typography
									className={cx(
										styles.lightText,
										column?.props?.className,
										column?.props?.style ? css(column?.props?.style) : '',
										labelStyle,
										styles.labelText,
									)}
									noWrap
								>
									{value !== 'no-category' ? value : emptyValue}
								</Typography>
							</LightTooltip>
						</div>

						{origin && (
							<Typography variant="h5" color={theme.palette.grey[700]} className={styles.subtitle}>
								{origin}
							</Typography>
						)}

						{column.props?.page === 'devis-table'
? (
							<div className={styles.text2}>{_.capitalize(t('modify-day', { dayCount: daysUpdateDifference }))}</div>
						)
: null}

						{row.original.reference && (
							<Typography variant="h5" className="grey700">
								{row.original.reference}
							</Typography>
						)}
					</div>
				</div>
			</div>
			<div className={cx('flex1 flexRow alignCenter gap2 nowrap', styles.progressBarContainer)}>
				{groupEndOfLife(endOfLife).map((item: any) => {
					console.log('item', item);
					console.log('popoverField', popoverField);
					return (
						<ProgressBar
							value={ item?.y ||  item?.composition}
							label={item?.type}
							color={(END_OF_LIFE_COLORS as any)[item?.type] || '#95AB76'}
							unit={unit}
							precision={precision}
							popoverValue={item[popoverField] || item?.composition}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default EndOfLIfeMaterialDisplay;

type ProgressBarProps = {
	color: string;
	value: number;
	label: string;
	unit?: React.ReactNode;
	precision?: number;
	popoverValue: number;
};

const progressBarStyles = {
	container: css({
		height: 8,
		borderRadius: 2,
		width: 'var(--width)',
		background: 'var(--background)',
		'.MuiButtonBase-root': {
			width: '100% !important',
		},
	}),
	popoverContent: css({
		padding: '16px',
		minWidth: '120px !important',
	}),
	label: css({
		fontWeight: '600 !important',
		lineHeight: '1 !important',
		fontSize: '14px !important',
	}),
	value: css({
		lineHeight: '1 !important',
	}),
};

export const ProgressBar = ({ value, color, label, popoverValue, precision=2, unit= '%' }: ProgressBarProps) => {
	const { t } = useTranslation();
	return (
		<BPopover
			modeHover
			content={() => {
				return (
					<div className={cx('flexColumn  nowrap gap8', progressBarStyles.popoverContent)}>
						<Typography className={progressBarStyles.label}>{t(label)}</Typography>
						<Typography className={cx(progressBarStyles.value, 'number')}>
							{_.isString(popoverValue) ? popoverValue : `${displayValue(popoverValue, undefined, precision)} `} {unit}
						</Typography>
					</div>
				);
			}}
			transformOriginHorizontal={'left'}
			transformOriginVertical={'top'}
			anchorOriginHorizontal={'left'}
			anchorOriginVertical={'bottom'}
			containerClass={progressBarStyles.container}
			style={{ ['--width' as any]: `${value}%`, ['--background' as any]: color }}
		>
			<div></div>
		</BPopover>
	);
};
