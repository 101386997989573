import { useCallback, useMemo, useRef, useState } from 'react';

import { cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pencil, Trash2, Wallet } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CARBON_INDICATOR, FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { STATUS_QUOTE } from '@carbonmaps/shared/utils/simulation';
import { ClientFormInput } from '@carbonmaps/shared/validations/client.validations';
import { QuoteFormInput } from '@carbonmaps/shared/validations/quote.validations';
import { createQuoteAction, deleteDevisAction } from '@carbonmaps/ui/actions/quote.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';

import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import {
	createClientAction,
	getQuoteOptionsAutocompleteClientAction,
} from '../../../lib/react-query/features/client/client.actions';
import { useFindQuoteByReportsClientTable } from '../../../lib/react-query/features/sales/sales.hooks';
import QuoteEditModal from '../../quoteSheet/QuoteEditModal';
import DevisModal from '../../simulation/quote/DevisModal';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},

		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(5)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(5))  div ':
		{
			textAlign: 'right',
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
		},
	};
};

const formatThenSetData = (res: any, t: any, indicator: 'carbon' | 'water') => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		return {
			...item,
			link: `${FRONT_PATH_NAMES.quote}/${item.objectId}`,
		};
	});
};

const DevisTable = () => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const theme = useTheme();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const [message, setMessage] = useState('');
	const queryClient = useQueryClient();
	const [openModal, setOpenModal] = useState(false);
	const [selectedDevis, setSelectedDevis] = useState<any>({});
	const [isNew, setIsNew] = useState(true);
	const [selectedOption, setSelectedOption] = useState<any>({});

	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table ---- //
	const fetchIdRef = useRef(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>({
		input: '',
		page: 1,
		size: 100,
		facetFilters: [],
		supplierIds: [],
		direction: 0,
	});

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	// mutate action confirm to delete simulation
	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteFacetFieldSelectedRow'],
		mutationFn: deleteDevisAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientGraph'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });

			//setMessage('');
		},
		onError: (error: any) => {
			// if (error.message) {
			// 	setMessage(error.message);
			// }
		},
	});

	// ---- action when delete row ---- //
	const handleClickDelete = useCallback(async (value: any) => {
		await deleteSelectedRow({ objectId: value } as any);
	}, []);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					...(pageIndex && { page: pageIndex + 1 }),
					...(pageSize && { size: pageSize }),
					...rest,
				});
			}
		},
		[paramsTable],
	);

	const navigate = useNavigate();

	const { mutate: updateQuoteStatus } = useMutation({
		mutationKey: ['updateStatusQuote'] as const,
		mutationFn: async ({ objectId, status }: { objectId: string; status: string }) => {
			try {
				const quote = new Parse.Object('Quote');
				quote.id = objectId;

				quote.set('status', status);

				await quote.save();
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
		},
	});

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('quote')} />;
				},
				accessor: 'name', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <Wallet size={16} color={siteColors.grey700} />,
				props: {
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					page: 'devis-table',
					trans: t,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('simulation-status')} />;
				},
				accessor: 'status',
				component: 'SimpleCell',
				editable: true,
				disableSortBy: true,
				type: 'status',
				callbackChangeStatus: (objectId: any, status: string) => {
					updateQuoteStatus({ objectId, status });
				},
				props: {
					isLoading: false,
					page: 'devis-table',
					trans: t,
					statusDictionary: STATUS_QUOTE,
				},
				isLoading: false,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				disableSortBy: false,
				component: 'Intensity',
				type: 'number',
				props: {
					color: {
						primary: indicator === WATER_INDICATOR ? '#046ae6' : siteColors.primary,
					},
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('impact-estimated')}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpact' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpact' : 'waterImpact',
				component: 'SimpleCell',
				editable: true,
				disableSortBy: false,
				props: {
					className: cx('flexRow justifyEnd'),
					nativeStyle: {
						color: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
					},
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="" />;
				},
				accessor: 'objectId',
				editable: true,
				disableSortBy: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 size={18} color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					handleClickDelete(value);
				},
				editIcon: <Pencil size={18} color={theme.palette.grey[700]} />,
				callbackEdit: (value: any) => {
					navigate(value.link);
				},
				callbackCopy: (value: any) => {
					handleClickAdd(value, 2);
				},
				props: {
					page: 'devisReportClient',
				},
				// eslint-disable-next-line quotes
				messageConfirm: t('delete-quote-confirm-modal-title'),
				subMessageConfirm: t('delete-quote-confirm-modal-subtitle'),
			},
		];
	}, [indicator, t, theme.palette.grey]);

	// ---- action when click add ---- //
	const handleClickAdd = useCallback((value: any, level: number) => {
		const currentClient = dataOptions?.find((o: any) => {
			return (o.objectId = tableResultData?.meta?.clientId);
		});
		setSelectedOption(currentClient);
		setSelectedDevis(value);
		setOpenModal(true);
		setIsNew(false);
	}, []);
	const params = useParams();

	const {
		key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindQuoteByReportsClientTable({
		params: {
			...paramsTable,
			indicator,
			viewMode: indicator,
			slugClient: params.slugClient,
		},
	});

	//----- action when submit quote form ----//
	const onSubmit: SubmitHandler<QuoteFormInput> = async (values) => {
		createQuote({
			...values,
			clientId: selectedOption?.objectId,
		} as any);
	};

	//----- mutation to create the client ----//
	const {
		mutate: createClient,
		isError: isErrorClient,
		isSuccess: isSuccessClient,
		isLoading: isLoadingCreateClient,
	} = useMutation({
		mutationKey: ['createClient'],
		mutationFn: createClientAction,
		onSuccess: async (returnedData: any) => {
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });

			// if (returnedData?.isUpdate) {
			// 	setSelectedOption({
			// 		label: returnedData?.client?.get('name'),
			// 		...returnedData?.client?.toJSON(),
			// 	});
			// }
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit client form  ----//
	const onSubmitClient: SubmitHandler<ClientFormInput> = async (values) => {
		createClient({
			...values,
		} as any);
		trackEvent('Simulate Carbon Quote Create Client', {
			ClientName: values?.name,
		});
	};

	const [search, setSearch] = useState<any>();

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingOption,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				// ...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});

	const {
		mutate: createQuote,
		isError,
		isSuccess,
		isLoading: isLoadingCreateQuote,
	} = useMutation({
		mutationKey: ['createQuote'],
		mutationFn: createQuoteAction,
		onSuccess: async (returnedData: any) => {
			setOpenModal(false);
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const [openQuoteEditModal, setOpenQuoteEditModal] = useState(false);

	return (
		<div css={{ marginBottom: 40 }}>
			<TableComponent
				isSelectable={false}
				skeleton={isTableLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={tableResultData?.meta?.last_page || 0}
				noDataMessage={
					paramsTable?.input !== '' || paramsTable.facetFilters.length
						? t('Aucun devis ne correspond à la sélection')
						: 'Pas de devis créé pour ce client'
				}
				pageSize={paramsTable.size}
				columns={tableColumns}
				// resetPage={resetPage}
				data={formatThenSetData(tableResultData?.data || [], t, indicator)}
				addStyles={stylesTable}
				stickyHeader
				transFunc={t}
				pagination={true}
			/>

			<QuoteEditModal
				open={openQuoteEditModal}
				onClose={() => {
					return setOpenQuoteEditModal(false);
				}}
				quote={selectedDevis}
				options={dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				onSaveData={() => {
					return setOpenQuoteEditModal(false);
				}}
			/>

			<DevisModal
				// eslint-disable-next-line quotes
				title={isNew === true ? t('form-create-quote-title') : t('form-update-quote-title')}
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateQuote}
				onSubmit={onSubmit}
				onSubmitClient={onSubmitClient}
				options={dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				setSearch={setSearch}
				isLoadingClient={isLoadingCreateClient}
				selectedSimulation={selectedDevis}
				setSelectedSimulation={setSelectedDevis}
				isNew={isNew}
				isLoadingOption={isLoadingOption}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
		</div>
	);
};

export default DevisTable;
