import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { addProductAction, deleteProductGroupAction } from '@carbonmaps/ui/actions/quote.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';

import { ProductRowData } from './_/ProductsTableSelect';

export const useAddProductDevis = () => {
	const queryClient = useQueryClient();

	const result = useMutation({
		mutationKey: ['addProductGroup'],
		mutationFn: addProductAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	return result;
};

export const useRemoveProductDevis = () => {
	const queryClient = useQueryClient();

	/* const { mutate: deleteProduct, isLoading: isLoadingDelete } = */
	const result = useMutation({
		mutationKey: ['deleteProduct'],
		mutationFn: deleteProductGroupAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			// handleCloseModal();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	return result;
};

export const useFindProductsForDevisInfinite = ({ sorting, enabled }: { sorting?: any; enabled: boolean; }) => {
	const { selectedYear } = useYearSelection();
	const [queryParams] = useSearchQueryParams();
	const { facetFilters, input } = queryParams;
	const { indicator } = useApp();

	const result = useInfiniteQuery({
		queryKey: [
			'findProductsForDevisInfinite',
			{
				sorting, //refetch when sorting changes
				input,
				facetFilters,
				viewMode: indicator,
				selectedYear,
			},
		] as const,
		queryFn: async ({ pageParam = 1, queryKey }) => {
			const { input, facetFilters, viewMode, selectedYear } = queryKey[1];
			const data = await Parse.Cloud.run(functionName.devis.findProductToAddToDevis, { pageParam, period: selectedYear, input, facetFilters, viewMode }) as {
				products: ProductRowData[];
				meta: {
					totalCount: number;
					totalPages: number;
					currentPage: number;
				};
			};
			return data;
		},

		// options
		enabled,

		keepPreviousData: true,
		getNextPageParam: (lastPage, allPages) => {
			// return allPages.length + 1;
			const nextPageCursor = lastPage.meta.currentPage + 1;

			if (nextPageCursor < lastPage.meta.totalPages) {
				return nextPageCursor;
			}

			return null;
		},
		getPreviousPageParam: (firstPage, allPages) => {
			// return allPages.length;
			const previousPageCursor = firstPage.meta.currentPage - 1;

			if (previousPageCursor < 0) {
				return null;
			}

			return previousPageCursor;
		},

		// initialPageParam: 0,
		// getNextPageParam: (_lastGroup, groups) => { return groups.length; },
		// refetchOnWindowFocus: false,
		// placeholderData: keepPreviousData,
	});

	return result;
};

// const sleep = <T = unknown>(timeout: number, value?: T) => {
// 	return new Promise<T>((resolve) => {
// 		// eslint-disable-next-line no-promise-executor-return
// 		return setTimeout(() => {
// 			resolve(value as never);
// 		}, timeout);
// 	});
// };

// const appendPage = (id: string, page = 0) => {
// 	return `${id}-page-${page}`;
// };

// const getDataPage = async (page = 0) => {
// 	await sleep(5000);
// 	return Array.from({ length: 30 }, (_, i) => {
// 		return {
// 			id: appendPage(nanoid(), page),
// 			uid: nanoid(),
// 			label: `Product ${nanoid()}`,
// 			intensity: 300,
// 			intensityPercent: 300,
// 		};
// 	});
// };
