import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';

import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { cn, siteColors } from '../../../lib/colors';
import CreateButton from './CreateButton';
import DevisTable from './DevisTable';
import QuoteGraph from './QuoteGraph';
import QuoteSearch from './QuoteSearch';

const styles = {
	btn1: css({
		borderRadius: '8px!important',
		padding: '8px 16px!important',
		'&:hover': {
			backgroundColor: cn(siteColors.primary, '!important'),
		},
	}),
	a: css({
		marginBottom: '24px',
	}),

	graphContainer: css({
		marginBottom: '16px',
	}),
};

const DevisList = () => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	const params = useParams();
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	const { data: reportClientInfo, isLoading } = useReportClientInfo({
		objectId: params.slugClient!,
	});

	return (
		<div>
			<div className={cx('flexRow justifyBetween w100 alignCenter', styles.a)}>
				<QuoteSearch />
				<div className="flexRow gap16">
					<CreateButton setTableParams={setTableParams} paramsTable={paramsTable} initialSelectedOption={reportClientInfo?.client} />
				</div>
			</div>

			<div className={cx('', styles.graphContainer)}>
				<QuoteGraph />
			</div>

			<div>
				<DevisTable />
			</div>
		</div>
	);
};

export default DevisList;

