import { useCallback, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Atom, HelpCircle, PackageOpen } from 'lucide-react';

import {
	ALL_YEAR_OPTION_VALUE,
	CARBON_INDICATOR,
	WATER_INDICATOR,
	WORDING_TERMS,
} from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';


import ImpactCarbonePopover from '../../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../../components/table/popover/WaterImpactPopover';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useMaterial } from '../../packagings.hooks';

const styles = {
	headerImpact: css({
		flexDirection: 'row-reverse !important',
		gap: '4px',
	} as any),
	impactContainer: css({
		flexDirection: 'row-reverse !important',
		justifyContent: 'flex-end',
	} as any),
	field: css({
		maxWidth: '200px !important',
	}),
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},

		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const formatData = (data: Array<any>) => {
	return (data || []).map((item) => {
		return {
			...item,
			volume: item?.tonnage,
		};
	});
};

const MaterialsTable = () => {
	// ---- define hooks ---- //
	const { t } = useTranslation();
	const { indicator } = useApp();
	const { selectedYear } = useYearSelection();
	// --- ref for fetch data table
	const fetchIdRef = useRef(0);
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
		},
	);

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('Emballage')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <PackageOpen size={20} color={siteColors.grey700} />,
				props: {
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					sx: {
						pointerEvents: 'none',
					},
				},
				disableSortBy: true,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.carbon500} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.water500} />,
						  }),
					decimal: 2,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('unites utilisées', {
								products: t('products'),
								products_lowercase: toLowerCase(t('products')),
							})}
							sortable={false}
						/>
					);
				},
				accessor: 'utilisation',
				editable: true,
				sortDescFirst: true,
				type: 'number',
				component: 'Ingredient',
				props: {
					page: 'packagings',
					iconColor: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
				},
				// disableSortBy: true,
			},
		];
	}, [indicator, selectedYear, t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	const { data, isLoading } = useMaterial({
		viewMode: indicator,
		tableOptions: paramsTable,
	});

	return (
		<TableComponent
			skeleton={isLoading}
			loading={false}
			fetchData={updateTableParams}
			pageCount={1}
			noDataMessage={t('Vous devez passer à la nouvelle version pour avoir cette information')}
			pageSize={paramsTable.size}
			columns={tableColumns}
			data={formatData(data)}
			onSortingColumn={handleSortingTable}
			stickyHeader
			transFunc={t}
			addStyles={stylesTable}
			pagination={false}
		/>
	);
};

export default MaterialsTable;
