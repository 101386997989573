import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Database, Send } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import IconContainer from '../../../../components/layout/list/IconContainer';
import ListHeader from '../../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useGetQuestionFormById } from '../../questionForms.hooks';


type QuestionFormDetailsHeaderProps = {
	subtitle?: string;
};

// const default_title = 'Campagnes de collecte';

const QuestionFormDetailsHeader = ({ subtitle }: QuestionFormDetailsHeaderProps) => {
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	const params = useParams();
	const qFormId = _.get(params, 'questionFormId');
	const languageCode = getIsoCountryCode(i18n.language);

	const { data: questionForm } = useGetQuestionFormById({ id: _.toString(params.questionFormId) });
	const qName = _.get(questionForm, `translation.${languageCode || 'fr'}.name`) || '---';
	return (
		<ListHeader
			title={qName}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Database size={32} color={theme.palette.common.white} />}
					color={siteColors.orange500}
				/>
			}
			idk={
				<Link to={`${FRONT_PATH_NAMES.questionForms}/prepare/${qFormId}`}>
				<BButton iconLeft={<Send size={20} />} label={t('send-new-campaign')} variant="primary" />
				</Link>
			}
		/>
	);
};

export default QuestionFormDetailsHeader;
