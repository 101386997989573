import { css } from '@emotion/css';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../../components/Typography';
import { siteColors } from '../../../lib/colors';

const styles = {
	value: css({
		fontSize: '24px !important',
		color: `${siteColors.grey7} !important`,
	}),
	label: css({
		color: `${siteColors.grey7} !important`,
	}),
};

type ProgressBarContentProps = {
	value?: number;
	label?: string;
	precision?: number;
};

const ProgressBarContent = ({ value, label, precision = 0 }: ProgressBarContentProps) => {
	return (
		<div className="flexCenter">
			<Typography className={styles.value}>{formatNumber(value, undefined, precision)} %</Typography>
			<Typography className={styles.label}>{label}</Typography>
		</div>
	);
};

export default ProgressBarContent;
