
import { useTranslation } from '../hooks/useTranslation';

import { cx } from '@emotion/css';
import { Leaf } from 'lucide-react';
import { stylesOtherIndicator } from '../containers/productSheet/synthesis/OtherIndicatorsBlock';
import { siteColors } from '../lib/colors';

const PefNote = () => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexRow gap16 nowrap', stylesOtherIndicator.blocBottom)}>
			<div>
				<Leaf color={siteColors.grey7} size={16} />
			</div>
			<div className={cx(stylesOtherIndicator.info)}>
				{t('PEF-info-note')}
			</div>
		</div>
	);
};

export default PefNote;
