import { CircularProgress } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { functionName } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import LightTooltipGlossary from '../../../../../../components/LightTooltipGlossary';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { useRseQFormTabQueryParam } from '../../../../general/tabs/rse/hooks/rse.hooks';
import { useGetInfoNoteData } from '../hooks/rse.hooks';

const ReOpenSurvey = () => {
	const { t } = useTranslation();
	const [questionFormId] = useRseQFormTabQueryParam();
	const { data, isLoading } = useGetInfoNoteData({ questionFormId: _.toString(questionFormId) });
	const queryClient = useQueryClient();

	const { mutate: reOpen, isLoading: isLoadingReOpen } = useMutation({
		mutationKey: ['reOpenSurveyForSupplier'],
		mutationFn: async ({ rseObjectId }: { rseObjectId: string; }) => {
			try {
				const result = await Parse.Cloud.run(functionName.questions.reOpenSurveyForSupplier, { rseObjectId });
				return result;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['findInfoNoteData']);
		},
	});

	if (isLoading || !data) {
		return null;
	}

	const answered = _.get(data, 'answered');

	if (_.isEqual(answered, false)) {
		return <div css={{ marginBottom: 32 }}>{t('text-opened-survey')}</div>;
	}

	return (
		<LightTooltipGlossary title={t('info-re-open-survey')} placement="top-start" sx={{ [`& .${tooltipClasses.tooltip}`]: { maxWidth: '500px' } }}>
			<div css={{ width: 'fit-content', height: 'fit-content' }}>
			<BButton variant="secondary" label={isLoadingReOpen ? <CircularProgress size={20} /> : t('re-open-survey')} addStyles={{ marginBottom: '54px' }} onClick={() => {
				reOpen({ rseObjectId: _.toString(_.get(data, 'objectId')) });
			}} />
			</div>
		</LightTooltipGlossary>
	);
};

export default ReOpenSurvey;
