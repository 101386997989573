import { use } from 'i18next';
import _ from 'lodash';

import type { IUser } from '@carbonmaps/shared/types/user.types';
import { classNames, functionName } from '@carbonmaps/shared/utils/constants';
import { getUserRoles, isSuperAdmin } from '@carbonmaps/shared/utils/parseRole.utils';
import { getSessionByToken } from '@carbonmaps/shared/utils/parseSession.utils';
import { getUserById } from '@carbonmaps/shared/utils/parseUser.utils';
import type { LogInInput } from '@carbonmaps/shared/validations/auth.validations';

import { queryClient } from '../../../../providers/QueryClientProvider';

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                       QUERIES                                        //
//                                                                                      //
// --------------------------------------------------------------------------------------//

export const getClientAuthKey = ['getClientAuth'] as const;

export const getTranslation = async () => {
	const results = await Parse.Cloud.run('getAllTranslations');
	return results;
};

export const getGlossary = async () => {
	const results = await Parse.Cloud.run('getAllGlossaries');
	return results;
};

export const getAllPeriod = async (storedUser: any) => {
	try {
		if (!storedUser) return [];

		const data = await Parse.Cloud.run(functionName.temporality.findPeriod);

		return data;
	} catch (error) {
		return [];
	}
};

const getCurrentUpdate = async () => {
	const update: Parse.Object | undefined = await Parse.Cloud.run(functionName.getCurrentUpdate);
	return update;
	// return update?.toJSON();
};

const getCurrentSupplier = async () => {
	const supplier: Parse.Object | undefined = await Parse.Cloud.run(functionName.getCurrentSupplier);
	return supplier;
	// return supplier?.toJSON();
};

const getDataForm = async () => {
	try {
		const result = await Parse.Cloud.run(functionName.getDataFormQuestionnaire);

		queryClient.setQueryData(['getDataFormQuestionnaire'], result);

		return result;
	} catch (error) {
		console.log(error);
	}
};

export const getUser = async (userId?: any) => {
	const results = await Parse.Cloud.run('getUserById', { userId });
	return results;
};

export const getClientAuthAction = async () => {
	try {
		const storedUser = await Parse.User.currentAsync();

		if (!storedUser) {
			throw new Error('Auth required');
		}

		const sessionToken = storedUser.getSessionToken();

		const _user: any = await new Parse.Query(Parse.User)
			.include(['companies.company'])
			.select(['companies', 'companies.company.deleted'])
			.get(storedUser.id, { json: true, sessionToken });

		const userCompanies = _user.companies?.filter((e: any) => {
			return _.get(e, 'company.deleted') !== true;
		});

		const isSuperAdminUser = await isSuperAdmin(storedUser);

		if (_.isEmpty(userCompanies) && !isSuperAdminUser) {
			throw new Error('noCompany');
		}

		// we don't need to choice a company for no many companies
		if (userCompanies?.length === 1) {
			const session = await Parse.Session.current();

			const objCompany = Parse.Object.fromJSON({
				className: classNames.COMPANY,
				objectId: userCompanies[0].company?.objectId,
			});

			// if the only one company is archived, do not let user access to the app
			const fetchedCompany = await new Parse.Query('Company').select(['deleted']).get(objCompany.id);

			if (fetchedCompany.get('deleted') === true) {
				// Parse.User.logOut();
				throw new Error('Company archived: cannot login');
			}

			session.set('company', objCompany);
			await session.save();
		} else {
			let lastCompany = storedUser.get('lastCompany');
			const session = await Parse.Session.current();

			// return;

			if (session.get('loginFrom')) {
				// do nothing
			} else {
				if (isSuperAdminUser) {
					// do nothing
				} else {
					let noLastCompany = false;

					if (!lastCompany) {
						noLastCompany = true;
						const _comp = userCompanies[0].company;
						lastCompany = new Parse.Object(classNames.COMPANY);
						lastCompany.id = _comp.objectId;
					}

					const isMemberOfLastCompany = userCompanies.some((company: any) => {
						return company.company?.objectId === lastCompany?.id;
					});

					if (!isMemberOfLastCompany) {
						storedUser.unset('lastCompany');
						await storedUser.save();
						throw new Error('noCompany (2)');
					} else {
						if (noLastCompany) {
							storedUser.set('lastCompany', lastCompany);
							await storedUser.save();
						}
					}

					// is last company archived? -> redirect to choice portal
					const fetchedCompany = await new Parse.Query(classNames.COMPANY).select(['deleted']).get(lastCompany.id);

					if (fetchedCompany.get('deleted') === true) {
						// Parse.User.logOut();
						throw new Error('Company archived: switch into another company');
					}

					session.set('company', lastCompany);
					await session.save();
				}
			}
		}

		const [user, roles, session, translations, glossaries, yearOptions, currentUpdate, supplier, dataForm] = await Promise.all([
			getUserById(storedUser.id, { sessionToken, json: true }),
			getUserRoles(storedUser, { json: true }),
			getSessionByToken(sessionToken, { json: true }),
			getTranslation(),
			getGlossary(),
			getAllPeriod(storedUser),
			getCurrentUpdate(),
			getCurrentSupplier(),
			getDataForm(),
		]);

		let userFrom;

		if (session?.loginFrom) {
			userFrom = (await getUser((session as any)?.loginFrom?.id)).toJSON();
			// console.log('==========userFrom========', userFrom?.toJSON());
		}

		// handle contributor login
		if (!session?.loginFrom ) {
			// do nothing
			if (dataForm) {
				Parse.Cloud.run('handleContributorLoginEffect', { ...dataForm });
			}
		}

		return {
			user,
			roles,
			session,
			sessionToken,
			translations,
			glossaries,
			userFrom,
			yearOptions,
			currentUpdate,
			supplier,
			dataForm,
		};
	} catch (error) {
		console.log('----- getAuthAction error ----------', error);
		logOutAction();
		return Promise.reject(error);
	}
};

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                      MUTATIONS                                       //
//                                                                                      //
// --------------------------------------------------------------------------------------//

export const logInAction = async (input: LogInInput) => {
	try {
		const { email, password } = input;
		const user = await Parse.User.logIn(email, password);

		await queryClient.prefetchQuery({
			queryKey: getClientAuthKey,
			queryFn: getClientAuthAction,
		});

		// ? should I return the logged in User?
		return user.toJSON() as unknown as IUser;
	} catch (error) {
		console.log('----- logInAction error ----------', error);
		return Promise.reject(error);
	}
};

export const logOutAction = async (): Promise<void> => {
	try {
		await Parse.User.logOut();

		console.log('----- logged Out -----');
		return await Promise.resolve();
	} catch (error) {
		console.log('----- logOutAction error ----------', error);
		return Promise.reject(error);
	}
};

export type LoginAsActionProps = { userId: string; companyId?: string };

export const logInAsAction = async (values: LoginAsActionProps): Promise<void> => {
	try {
		const { sessionToken } = (await Parse.Cloud.run('logInAs', {
			userId: values.userId,
			companyId: values.companyId,
		})) as { sessionToken: string };

		await Parse.User.become(sessionToken);
	} catch (error) {
		console.log(' ------ logInAsAction error: ', error);
		return Promise.reject(error);
	}
};

export type UpdateSessionCompanyActionProps = { companyId: string };

export const updateSessionCompanyAction = async (values: UpdateSessionCompanyActionProps) => {
	try {
		// check if company is archived or not
		// Parse.Cloud.run(functionName, { companyId: values.companyId });
		const objCompany = await new Parse.Query('Company').select(['deleted']).get(values.companyId);

		if (objCompany.get('deleted') === true) {
			throw new Error('Company archived: cannot switch to this company');
		}

		// const objCompany = Parse.Object.fromJSON({
		// 	className: 'Company',
		// 	objectId: values.companyId,
		// });

		const user = Parse.User.current();
		user?.set('lastCompany', objCompany); // las logged company

		const session = await Parse.Session.current();
		session.set('company', objCompany);

		await Promise.all([user?.save(), session.save()]);
	} catch (error) {
		console.log(' ------ updateCompanySessionAction error: ', error);
		return Promise.reject(error);
	}
};
