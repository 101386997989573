import React from 'react';

import { css, cx } from '@emotion/css';
import { HelpCircle } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import LightTooltipGlossary from '../LightTooltipGlossary';
import Skeleton from '../Skeleton';
import { stylesBlocSales } from './ContentCard';

type ContentCardProps = {
	title: React.ReactNode;
	value: React.ReactNode;
	className?: string;
	unit?: React.ReactNode;
	withInfo?: boolean;
	isLoading?: boolean;
	info?: React.ReactNode;
};
const styles = {
	content: css({
		position: 'relative',
		whiteSpace: 'nowrap',
	}),
	kpi: css({
		alignItems: 'baseline!important',
	}),
	icon: css({
		position: 'absolute',
		right: '8px',
		top: '8px'
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: '100px',
		flex: 1,
	} as any),
};

const KpiCard = ({ isLoading, title, value, unit, withInfo = false, info, className }: ContentCardProps) => {

	const { t } = useTranslation();

	return (
		<div className={cx('flexColumn gap16 flex1', stylesBlocSales.container, styles.content)}>
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			) : (
				<>
					<div className={cx(stylesBlocSales.title)}>
						{title}
					</div>
					<div className={cx('flexRow gap8', styles.kpi)}>
						{value}
						{unit ?? ''}
					</div>
					{withInfo &&
						<LightTooltipGlossary title={
							info ?? t('Impact par rapport à la référence pour le même catalogue')}

							>
							<div className={cx(styles.icon)}>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<g clip-path="url(#clip0_2009_3249)">
										<path d="M10.0001 13.3334V10.0001M10.0001 6.66675H10.0084M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z" stroke="#6B7280" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
									</g>
									<defs>
										<clipPath id="clip0_2009_3249">
											<rect width="20" height="20" fill="white" />
										</clipPath>
									</defs>
								</svg>
							</div>
						</LightTooltipGlossary>
					}</>)}
		</div>

	);
};

export default KpiCard;
