import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { Outlet } from 'react-router-dom';

import loginBg from '@carbonmaps/media/images/login-bg.jpg';
import { ReactComponent as CarbonMapsIcon } from '@carbonmaps/media/other/carbonmaps-icon.svg';
import { ReactComponent as CarbonMapsLogo } from '@carbonmaps/media/other/carbonmaps-logo.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { mobileTablet } from '@carbonmaps/ui/utils/Responsive';
import { Link } from 'react-router-dom';

import SwitchLanguage from '../../components/SwitchLanguage';
import { useTranslation } from '../../hooks/useTranslation';
import { FlagEn, FlagFr } from '../../layouts/_common/SideBar';
import BubbleChatButton from '../bubbleChat/BubbleChatButton';

const classes = () => {
	return {
		container: {
			display: 'flex',
			width: '100%',
			position: 'relative',
			alignItems: 'flex-end',
			justifyContent: 'flex-end',
		},
		content: {
			width: '50vw',
			height: '100vh',
			minWidth: 600,
			minHeight: 800,
			background: '#FFFFFF',
			position: 'absolute',
			top: 0,
			left: 0,
		},
		innerContent: {
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			alignItems: 'center',
		},
	};
};

const AuthLayout = () => {
	const styles = classes();
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<div css={styles.container as any}>
			<div css={styles.content as any}>
				<div css={styles.innerContent}>
					<Box css={{ width: '400px' }}>
						<div>
							<div>
								<Link to={FRONT_PATH_NAMES.logIn}>
									<CarbonMapsIcon />
								</Link>
							</div>
							<div css={{ width: '100%' }}>
								<Outlet />
							</div>
						</div>
						<Box
							css={{
								marginTop: 60,
							}}
						>
							<SwitchLanguage />
						</Box>
						<Box
							css={{
								display: 'flex',
								gap: '16px',
								a: {
									fontWeight: 400,
									fontSize: 12,
									color: theme.palette.grey[800],
									textDecoration: 'none',
								},
							}}
						>
							<a href="https://carbonmaps.io/" target="_blank" rel="noreferrer">
								{t('discover')}
							</a>
							<a href="mailto:support@carbonmaps.io">{t('help-support')}</a>
							<a href="mailto:support@carbonmaps.io">{t('terms_of_uses')}</a>
						</Box>
					</Box>
				</div>
			</div>
			<div
				css={{
					backgroundColor: 'transparent',
					width: '60%',
					[mobileTablet]: {
						width: '70%',
					},
				}}
			>
				<div
					css={{
						backgroundImage: `url(${loginBg})`,
						height: '100vh',
						minHeight: 800,
						width: '100%',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						zIndex: -1,
					}}
				/>
				<BubbleChatButton />
			</div>
		</div>
	);
};

export default AuthLayout;
