import Typography from '@mui/material/Typography';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../../hooks/useTranslation';
import Unit from '../../units/Unit';

import Popover from './Popover';
import PopoverItem from './PopoverItem';

const WaterImpactPopover = ({ item }: any) => {
	const { t } = useTranslation();
	return (
		<Popover>
			{item?.waterImpact < 0
? (
				<Typography fontWeight="600">{t('Volume incorrect')}</Typography>
			)
: (
				<PopoverItem
					label={item?.volume ? WORDING_TERMS.IMPACT : t('Volume non renseigné')}
					value={
						item?.volume
? (
							<>
								{formatNumber(item?.waterImpact || 0, undefined, 0)}
								{' '}
								<Unit
									measure="waterImpact"
								/>
							</>
						)
: (
							''
						)
					}
				/>
			)}
		</Popover>
	);
};

export default WaterImpactPopover;
