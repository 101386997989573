import { ReactNode, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { /* Navigate, */ Outlet /* , useLocation */ } from 'react-router-dom';

import { useImpactYear, useYearSelection } from '../../hooks/useImpactYear';
import BaseLayout from '../_common/BaseLayout';
import SideBar from '../_common/SideBar';
import TopBar from '../_common/TopBar';

type Props = {
	children?: ReactNode;
	disableIndicatorSwitch?: boolean;
	disableYearSelection?: boolean;
	disableAllYear?: boolean;
};

const MainLayout = ({ children, disableIndicatorSwitch, disableYearSelection, disableAllYear }: Props) => {
	const [openMenu, setOpenMenu] = useState(true);
	// const { data, isSuccess } = useImpactYear();

	const handleToggleSidebar = () => {
		return setOpenMenu(!openMenu);
	};

	return (
		<BaseLayout sidebar={<SideBar open={openMenu} />}>
			<TopBar
				open={openMenu}
				onToggle={handleToggleSidebar}
				disableIndicatorSwitch={disableIndicatorSwitch}
				disableYearSelection={disableYearSelection}
				disableAllYear={disableAllYear}
			/>

			{/* The actual content */}
			<Box component="main" flexGrow={1}>
				{children ? children : <Outlet />}
			</Box>
		</BaseLayout>
	);
};

export default MainLayout;
