import { z } from 'zod';

export const chatFormValidationSchema = z.object({
	email: z
		.string({ required_error: 'email obligatoire' })
		.email('Mettre une adresse email valide')
		.min(6, 'email obligatoire'),
	message: z.string({ required_error: 'message obligatoire' }).min(1, 'message obligatoire'),
});

export type ChatFormInput = z.infer<typeof chatFormValidationSchema>;
