// import Box from '@mui/material/Box';
// import styled from '@mui/material/styles/styled';
import { isValidElement } from 'react';

// import { classNames } from '@carbonmaps/shared/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { cn, siteColors } from '../lib/colors';

// const StyledBox = styled(Box)(({ theme }) => {
// 	return {
// 		padding: pxToRem(24),
// 		border: `solid 1px ${theme.palette.grey[500]}`,
// 		borderRadius: pxToRem(16),
// 		marginBottom: pxToRem(32),
// 		width: '100%',
// 	};
// });
const styles = {
	card: css({
		padding: pxToRem(24),
		marginBottom: pxToRem(32),
	}),
	bordered: css({
		border: cn(`solid 1px`, siteColors.grey500),
		borderRadius: pxToRem(16),
	}),
	mr3: css({
		marginRight: 9,
	}),
};

type BlockCard2Props = {
	iconTitle?: React.ReactNode;
	title?: string;
	children: React.ReactNode;
	boxClassName?: string;
	titleClassName?: string;
	titleBoxClassName?: string;
	cardClassName?: string;
	bordered?: boolean;
};

const BlockCard2 = ({
	iconTitle,
	title,
	children,
	boxClassName,
	titleClassName,
	titleBoxClassName,
	cardClassName,
	bordered = true,
}: BlockCard2Props) => {
	const hasIcon = isValidElement(iconTitle);

	return (
		<div className={cx('flexColumn width100 gap16', boxClassName)}>
			{hasIcon || title ? (
				<div className={cx('flexRow alignCenter', titleBoxClassName)}>
					{hasIcon ? <div className={cx('', /* hasIcon ? styles.mr3 : '' */ styles.mr3)}>{iconTitle}</div> : null}
					{title ? <Typography className={cx('fontWeight600 grey800', titleClassName)}>{title}</Typography> : null}
				</div>
			) : null}
			<div className={cx('width100', styles.card, bordered ? styles.bordered : '', cardClassName)}>{children}</div>
			{/* {bordered ? <StyledBox className={cx('', cardClassName)}>{children}</StyledBox> : children} */}
		</div>
	);
};

export default BlockCard2;
