import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { Recycle } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';


import CircularProgress from '../../../../components/card/CircularProgress';
import Skeleton from '../../../../components/Skeleton';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import ProgressBarContent from '../../components/ProgressBarContent';
import { useRecyclePercentComparaison } from '../../packagings.hooks';

const styles = {
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: 97,
		flex: 1,
	} as any),
	textNoWrap: css({
		whiteSpace: 'nowrap',
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};

const RecycledMaterialsBlock = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();
	// ---- fetch data ---- //
	const { data, isLoading } = useRecyclePercentComparaison({ viewMode: indicator });

	// console.log('*****', data);

	return (
		<Card
			title={t('Intégration des matériaux recyclés')}
			icon={<Recycle size={20} color={siteColors.recycledIcon} />}
			sx={{ padding: '32px 24px !important', flexWrap: 'nowrap', width: '100%', justifyContent: 'center' }}
		>
			{isLoading
? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			)
: (
				<>
					<CircularProgress
						width={194}
						percent={data?.recycledTonnagePercent || 0.01}
						otherColor={siteColors.grey3}
						fillColor={data?.recycledTonnagePercent ? siteColors.green500 : siteColors.grey3}
						containerWidth={'194px'}
						innerRadius={40}
						content={<ProgressBarContent value={data?.recycledTonnagePercent} label={t('du tonnage')} />}
						withBorder
						withTooltip
						renderTooltip={(item, indicator) => {
							return (
								<TooltipCard>
									<div>
										<div className={cx('', styles.textNoWrap, styles.fontBold)}>{_.get(item, 'label', '-')}</div>
										<div className={cx('', styles.textNoWrap)}>
											{displayValue(_.get(item, 'value', '-'), undefined, 0)} {_.get(item, 'unit', '-')}
										</div>
									</div>
								</TooltipCard>
							);
						}}
						data={[
							{
								label: t('recycled-tonnage'),
								value: _.get(data, 'recycledTonnage'),
								unit: <Unit measure="massTon" />,
							},
							{
								label: t('not-recycled-tonnage'),
								value: _.get(data, 'notRecycledTonnage'),
								unit: <Unit measure="massTon" />,
							},
						]}
					/>
					<CircularProgress
						width={194}
						percent={data?.recycledImpactPercent || 0.01}
						otherColor={siteColors.grey3}
						fillColor={data?.recycledTonnagePercent ? siteColors.carbon500 : siteColors.grey3}
						containerWidth={'194px'}
						innerRadius={40}
						content={<ProgressBarContent value={data?.recycledImpactPercent} label={t('de l’impact')} />}
						withBorder
						withTooltip
						renderTooltip={(item, indicator) => {
							return (
								<TooltipCard>
									<div>
										<div className={cx('', styles.textNoWrap, styles.fontBold)}>{_.get(item, 'label', '-')}</div>
										<div className={cx('', styles.textNoWrap)}>
											{displayValue(_.get(item, 'value', '-'), undefined, 0)} {_.get(item, 'unit', '-')}
										</div>
									</div>
								</TooltipCard>
							);
						}}
						data={[
							{
								label: t('recycled-impact'),
								value: _.get(data, 'recycledImpact'),
								unit: <Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />,
							},
							{
								label: t('not-recycled-impact'),
								value: _.get(data, 'notRecycledImpact'),
								unit: <Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />,
							},
						]}
					/>
				</>
			)}
		</Card>
	);
};

export default RecycledMaterialsBlock;

const TooltipCard = ({ children }: { children: ReactNode }) => {
	return (
		<div
			className="flexColumn"
			css={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '24px',
				gap: '24px',
				minWidth: '150	px',
			}}
		>
			{/* OOOOOOOOOOOOOOOOO */}
			{children}
		</div>
	);
};
