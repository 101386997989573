import { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation } from '@tanstack/react-query';
import { useToggle } from 'react-use';

import { ReactComponent as CloseChat } from '@carbonmaps/media/icons/closeChat.svg';
import { ReactComponent as LiveHelpIcon } from '@carbonmaps/media/icons/liveHelp.svg';
import { sendMessageAction } from '@carbonmaps/ui/actions/chat.actions';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { siteColors } from '../../lib/colors';

import BubbleChatCard from './BubbleChatCard';

const styles = {
	container: css({
		position: 'fixed',
		right: 20,
		bottom: 20,
		zIndex: 100000,
	}),
	button: css({
		backgroundColor: `${siteColors.green500} !important`,
		width: 52,
		height: 52,
		borderRadius: '50% !important',
		transition: 'all 0.1s',

		cursor: 'pointer',
			'&:hover': {
				filter: 'brightness(.9)',
			},
	}),

	modal: css({
		borderRadius: 8,
		border: '1px solid #E2DDEB',
		background: '#FFF',
		boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.45)',
		padding: '26px 24px',
		minWidth: 400,
		minHeight: 300,
	}),
};

const BubbleChatButton = () => {
	const [isOpenChat, toggleChat] = useToggle(false);

	const [isSuccess, setIsSuccess] = useState(false);
	const [email, setEmail] = useState('');

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Bubble chat' },
			});
		},
		[analytics],
	);

	// ---------------------------------- //
	// ----------- send message --------- //
	// ---------------------------------- //

	// ---- update ll quantity ---- //
	const { mutate: sendMessage, isLoading } = useMutation({
		mutationKey: ['sendMessage'],
		mutationFn: sendMessageAction,
		onSuccess: async () => {
			setIsSuccess(true);
		},
		onError: (error: any) => {/*  */},
	});

	const handleSendMessage = (data: { email: string; message: string }) => {
		sendMessage(data);
		setEmail(data.email);
		trackEvent('Support request sent');
	};

	const toggleChatModal = () => {
		if (!isOpenChat) {
			trackEvent('Opened support request popup');
		}

		toggleChat();
		setIsSuccess(false);
	};

	return (
		<div className={cx('flexColumn gap16 flexEnd', styles.container)}>
			{isOpenChat && (
				<BubbleChatCard
					onSendMessage={handleSendMessage}
					isSuccess={isSuccess}
					isLoading={isLoading}
					onClose={toggleChatModal}
					email={email}
				/>
			)}
			<div className={cx('flexCenter', styles.button)} onClick={toggleChatModal}>
				{isOpenChat ? <CloseChat /> : <LiveHelpIcon />}
			</div>
		</div>
	);
};

export default BubbleChatButton;
