import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle, ArrowLeft } from 'lucide-react';
import { SubmitHandler, useForm, UseFormHandleSubmit } from 'react-hook-form';

import { classNames } from '@carbonmaps/shared/utils/constants';
import { CustomMessageFormInput, MessageFormValidationSchema } from '@carbonmaps/shared/validations/questions.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import AlertStatic from '../../../../../../components/Toast/AlertStatic';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import { getClientAuthKey } from '../../../../../../lib/react-query/features/auth/auth.actions';
import { useGetClientAuthQuery } from '../../../../../../lib/react-query/features/auth/auth.hooks';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../../../../utils/constants';
import MessageForm from '../custom-messages/MessageForm';
import { stylesPrepareQuestion, topStyles } from '../suppliers-selection/QuestionFormSuppliers';

// import { useQuestionFormStore } from './hooks';

const languages = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

const styles = {
	main: css({
		alignItems: 'center!important',
	}),
};

type Props = {
	setScreen: (screen: number) => void;
};

const CustomMessageForm = ({ setScreen }: Props) => {
	const [error, setError] = useState<string | null>(null);
	const queryClient = useQueryClient();

	const initializeVisibleTextAreas = () => {
		return languages.reduce((acc: any, lang: string) => {
			acc[lang] = lang === 'fr' || lang === 'en';
			return acc;
		}, {});
	};

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const customEmailContent = authData?.session.company?.customEmailContent;

	const languageCodes = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

	const initialMessages = languageCodes.reduce((acc, lang) => {
		acc[lang] =  (customEmailContent as never)?.[lang] ?? DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang]?.value ?? '';
		return acc;
	}, {} as any);

	const [langCustomEmail, setLangCustomEmail] = useState(initializeVisibleTextAreas);
	const [customMessages, setCustomMessages] = useState(initialMessages);

	const _langCustomEmail = authData?.session.company?.langCustomEmail;

	useEffect(() => {
		if (!_langCustomEmail) return;
		setLangCustomEmail(_langCustomEmail as never);
		setCustomMessages( customEmailContent as never);
	}, [_langCustomEmail, customEmailContent, authData]);


	// useEffect(() => {
	// 	if (!customEmailContent) return;
	// 	const languageCodes = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);
	// 	const messages = languageCodes.reduce((acc, lang) => {
	// 		acc[lang] = (customEmailContent as never)?.[lang] ?? DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang]?.value ?? '';
	// 		return acc;
	// 	}, {} as any);
	// 	setCustomMessages(messages as never);
	// }, [authData, customEmailContent, setCustomMessages]);


	const messageForm = useForm<CustomMessageFormInput>({
		resolver: zodResolver(MessageFormValidationSchema),
		defaultValues: async () => {
			const result: any = {};
			languages.forEach((lang) => {
				result[`content_${lang}`] = (customMessages as any)?.[lang] ?? DEFAULT_CUSTOM_MESSAGE_OPTIONS?.[lang]?.value  ;
			});
			return result;
		},
		mode: 'onChange',
	});


	const { mutate: updateLangCustomEmail } = useMutation({
		mutationKey: ['updateLangCustomEmail'] as const,
		mutationFn: async ({ langCustomEmail, formattedValues }: { langCustomEmail: any, formattedValues: any }) => {
			try {
				const company = new Parse.Object(classNames.COMPANY);
				company.id = authData?.session.company?.objectId ?? 'none'; //todo change later

				company.set('langCustomEmail', langCustomEmail);
				company.set('customEmailContent', formattedValues);
				await company.save();

			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
		},
	});

	const onSubmit: SubmitHandler<CustomMessageFormInput> = async (values: any) => {
		const countChecked = Object.entries(langCustomEmail).reduce((count, [, isChecked]) => {
			return isChecked ? count + 1 : count;
		}, 0);

		if (countChecked < 1) {
			setError('At least one language must be selected');
			return;
		}

		const formattedValues = languages.reduce((acc, lang) => {
			(acc as any)[lang] = values[`content_${lang}`];
			return acc;
		}, {});

		 setCustomMessages(formattedValues as any);

		 await updateLangCustomEmail({ langCustomEmail, formattedValues });
		 setScreen(0);
	};

	return (
		<div>
			<TopBar setScreen={setScreen} handleSubmit={messageForm?.handleSubmit} onSubmit={onSubmit} />
			<div className={cx('flexColumn', stylesPrepareQuestion.main, styles.main)}>
				{error && <AlertStatic text={error} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}
				<MessageForm form={messageForm} langCustomEmail={langCustomEmail} setLangCustomEmail={setLangCustomEmail} customMessages={customMessages} />
			</div>
		</div>
	);
};

export default CustomMessageForm;

const TopBar = ({
	setScreen,
	handleSubmit,
	onSubmit,
}: {
	setScreen: (screen: number) => void;
	handleSubmit: UseFormHandleSubmit<CustomMessageFormInput>;
	onSubmit: SubmitHandler<CustomMessageFormInput>;
}) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexRow alignCenter', topStyles.main)}>
			<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
				<div
					css={{
						marginRight: 21,
					}}
				>
					<IconButton
						disableRipple
						onClick={() => {
							setScreen(0);
						}}
					>
						<ArrowLeft color={siteColors.grey700} size={20} />
					</IconButton>
				</div>
				<div className={cx('', topStyles.text1)}>{t('Personnaliser le message')}</div>
			</div>

			<div className={cx('flexRow alignCenter')}>
				<div className="flexRow gap16">
					<BButton label={t('Enregistrer')} variant="primary" onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};
