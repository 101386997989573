import { css, cx } from '@emotion/css';
import { Checkbox } from '@mui/material';
import _ from 'lodash';

import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../../../../utils/constants';

import MessageTranslation from './MessageTranslation';
import { useGlobalLoading } from './questionForms.hooks';

const styles = {
	hidden: css({
		display: 'none',
	}),

	globalDisabled: css({
		'& *': {
			cursor: 'not-allowed',
			pointerEvents: 'none',
			opacity: 0.9,
		},
	}),

	container: css({
		border:'1px solid #E5E5E5',
		borderRadius: 16,
	}),
};

const languages = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

const MessageForm = ({ form, langCustomEmail, setLangCustomEmail,customMessages }: { form: any, langCustomEmail: any, customMessages:any,setLangCustomEmail: any }) => {
	const { t } = useTranslation();
	const { isGlobalLoading } = useGlobalLoading();


	const handleCheckboxChange = (lang: string) => {
		setLangCustomEmail((prev: any) => {return {
			...prev,
			[lang]: !prev[lang],
		};});
	};

	return (

			<div
				css={{
					gap: 24,
					width:'100%',
					maxWidth:'650px',
					'& > *': {
						marginBottom: 24,
					},
				}}
				className={cx('', isGlobalLoading ? styles.globalDisabled : '')}
			>
			{languages.map((lang: any) => {
					return (
						<div key={`${lang  }-bloc`} className={cx('', styles.container)}>
							<BCheckbox
								style={{ padding: '8px 16px' }}
								key={`${lang  }-checkbox`}
								id="hasSupplierProduct"
								label={t(DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang]?.label)}
								name="hasSupplier"
								control={
									<Checkbox defaultChecked={langCustomEmail[lang]} checked={langCustomEmail[lang]} onChange={() => {return handleCheckboxChange(lang);}} />
								}
							/>
							{langCustomEmail[lang] && <MessageTranslation messageForm={form as never} lng={lang} customMessages={customMessages}/>}
						</div>
					);
				})}

			</div>

	);
};

export default MessageForm;

