import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, functionName } from '@carbonmaps/shared/utils/constants';
import LightTooltip from '@carbonmaps/ui/components/saas/Table/LightTooltip';

import SupplierScore from '../../../../../../../components/supplier/SupplierScore';

const SupplierLabel = ({ supplier }: { supplier: Record<string, any> }) => {
	const linkId = _.get(supplier, 'linkId', null);
	const uid = _.get(supplier, '_id', null);
	const params = useParams();
	const questionFormId = _.get(params, 'questionFormId');

	const { data } = useQuery({
		queryKey: ['findSupplierScoreForRow', { supplierUid: uid, questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUid, questionFormId } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.suppliers.findSupplierScoreByUid, { supplierUid, questionFormId });
			} catch (error) {
				console.log('--- findSupplierScore action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: !!uid,
	});

	return (
		<Link to={linkId ? `${FRONT_PATH_NAMES.suppliers.details}/${linkId}/synthesis` : '#'} className="width100" css={{ textDecoration: 'none', color: 'unset' }}>
		<div
		className="flexRow alignTop noWrap"
		css={{
			flexWrap: 'nowrap',
			gap: 16,
		}}
		>
			<div css={{
				position: 'relative',
				top: 6,
			}}>
			<SupplierScore score={_.toString(_.get(data, 'note'))} />
			</div>
				<LightTooltip title={_.get(supplier, 'name', '-')}>
			<div>
				<div css={{ whiteSpace: 'nowrap' }}>{_.get(supplier, 'name', '-')}</div>
				<div className="grey700" css={{ whiteSpace: 'nowrap', fontSize: 12 }}>{_.get(supplier, '_id', '-')}</div>
			</div>
				</LightTooltip>
		</div>
		</Link>
	);
};

export default SupplierLabel;
