import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getDataSBTiFlagAction } from '@carbonmaps/ui/actions/carbonFootprint.actions';

import DashboardSelect from '../../../../components/dashboard/DashboardSelect';
import LightTooltipGlossary from '../../../../components/LightTooltipGlossary';
import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/v3/Card';
import { useImpactYear, useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import DonutChart from '../../../packagings/components/DonutChart';

import CarbonFootprintLegend from './CarbonFootprintLegend';
import CarbonFootprintTitle from './CarbonFootprintTitle';
import CarbonFootprintTooltip from './CarbonFootprintTooltip';
import EmptyCarbonFootprint from './EmptyCarbonFootprint';

const SbtiFlag = () => {
	const { t } = useTranslation();
	// period selected
	const { selectedYear } = useYearSelection();

	const [currentYear, setCurrentYear] = useState<number | undefined>(undefined);
	const [yearSBTI, setYearSBTI] = useState<number | undefined>(undefined);

	const { data: yearOptions } = useImpactYear();

	// ---- fetch data graph for this period selected ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getDataSBTiFlag', { period: selectedYear, yearSelected: currentYear }],
		queryFn: getDataSBTiFlagAction,
	});

	const items = useMemo(() => {
		if (!data?.data)
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];

		const result = [];
		const gesAgricultureImpact = data?.data.gesAgricultureImpactTotal;
		const totalImpact = data?.data.carbonImpactTotal;
		const gesAgriculturePercent = (gesAgricultureImpact * 100) / totalImpact;



		result.push({
			label: t('emission-flag'),
			value: gesAgriculturePercent,
			valueNotPercent: gesAgricultureImpact,
			tonnage: gesAgricultureImpact,
			color: siteColors.green500,
		});

		// other
		result.push({
			label: t('emission-non-flag'),
			value: 100 - gesAgriculturePercent,
			valueNotPercent: totalImpact - gesAgricultureImpact,
			tonnage: totalImpact - gesAgricultureImpact,
			color: '#EEF1E9',
		});

		return result;
	}, [data, t]);

	useEffect(() => {
		if (!data) return;
		setYearSBTI(data?.year);
	}, [data, yearOptions]);

	return (
		<>
			<Card
				title={
					<CarbonFootprintTitle
						title={
							<div className="flexRow alignCenter gap4">
								<LightTooltipGlossary title={<div>{t('sbti-flag-content')}</div>}>
									<span className="lineHeight1">{t('SBTi FLAG')}</span>
								</LightTooltipGlossary>
								{yearOptions?.length ?
								(
									<DashboardSelect
										options={yearOptions?.map((item: any) => {
											return {
												...item,
												label: item?.toString(),
												value: item,
											};
										})}
										value={(yearSBTI || selectedYear) as any}
										onChange={(v: number) => {
											setCurrentYear(v);
											setYearSBTI(v);
										}}
										renderValue={(item: any) => {
											return <Typography className=" flexRow alignCenter lineHeight1 fontWeight500 grey7">{item?.label} (<Unit measure='carbonImpactTon' className="lineHeight1 fontWeight500 grey7"/>)</Typography>;
										}}
									/>
								) :
								 null}
							</div>
						}
						year={data?.year}
						withYear={false}
					/>
				}
				loading={isLoading}
			>
				{!data
? (
					<EmptyCarbonFootprint text={t('Vous n’avez pas encore complété vos ACVs')} />
				)
: (
					<DonutChart
						width={200}
						height={200}
						items={items}
						innerRadius={85}
						outerRadius={70}
						legend={<CarbonFootprintLegend items={items as any} />}
						withTooltip
						renderTooltip={(item, indicator) => {
							return <CarbonFootprintTooltip label={item?.label} value={item?.tonnage} />;
						}}
						data={items}
						className="flex1 justifyCenter width100 nowrap"
					/>
				)}
			</Card>
		</>
	);
};

export default SbtiFlag;
