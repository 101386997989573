import _ from 'lodash';

/**
 * @description Formats number to conventional string format.
 * @param {number} value
 * @param {string} unite
 * @param {number} precision
 * @param {boolean} round
 * @returns {string}
 */
export function formatNumber(
	value: any,
	unite?: string,
	precision = 2,
	round = true,
	noValue = '-',
	abbreviatedNumber = false,
): string {
	if (value === undefined || value === null) return noValue;

	value = parseFloat(value || 0);
	if (abbreviatedNumber === true && value > 999) {
		return abbreviateNumberT(value, precision).toLocaleString('fr');
	}

	const n = parseFloat(isNaN(value) ? 0 : value?.toFixed(precision)).toLocaleString('fr', {
		minimumFractionDigits: precision,
	});

	return n;
}

/**
 * @description Formats number to abbreviate Number format.
 * @param {number} value
 */
const abbreviateNumberT = (number: number, precision: number) => {
	const SI_PREFIXES = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];

	if (number === 0) return number;

	const tier = SI_PREFIXES.filter((n) => {
		return number >= n.value;
	}).pop();
	const numberFixed = (number / (tier?.value || 1))?.toFixed(precision);

	return `${numberFixed}${tier?.symbol}`;
};

export const displayValue = (value?: number, minValue = 0.01, precision = 2) => {
	// let toDisplay;
	if (_.isNil(value)) {
		return '-';
	}

	let isNegative = false;

	if (value < 0) {
		value = Math.abs(value);
		isNegative = true;
	}
	//const testNumber = !isNaN(value) ? parseFloat(value?.toFixed(2)) : value;
	let testNumber = value;
	if (!isNaN(value)) {
		if (value === 0 || value === null || value === undefined) {
			testNumber = 0;
		}
		else {
			try {
				testNumber = parseFloat(value?.toFixed(2));
			}
			catch (e) {
				testNumber = 0;
			}
		}
	}


	if (testNumber <= 0.01 && value !== 0) {
		return isNegative ? '> 0,01' : '< 0,01'; // todo: change in function of minValue
	} else if (value) {
		return formatNumber(isNegative ? -value : value, undefined, precision);
	} else if (value === 0) {
		return '0';
	} else {
		return '-';
	}
};

export const hasDecimal = (value: number) => {
	return value % 1 !== 0;
};



export function truncNumberFormat(
	value: any,
) {
	if (value < 0.01) {
		return '< 0,01'
	}
	const trunc = Math.floor((value as any) * 100) / 100;
	return (trunc.toString() as any).replace('.', ',');
}


export const displayAuditValue = (value: number) => {
	if (isNaN(value)) return '-';
	return parseFloat(value as any) < 100 && parseFloat(value as any) > 99 || parseFloat(value as any) < 1 ?
		truncNumberFormat(value) :
		formatNumber(value, undefined, (value < 100 && value > 99) || value < 1 ? 2 : 0)

}

// format compact number
export const formatCompactNumber = (number: number, unit = '') => {
	if (!number) return '';
	const options = {
		notation: 'compact',
		compactDisplay: 'short',
	} as any;
	const format = Intl.NumberFormat('fr-FR', options);
	return `${format.format(number)}${unit}`;
};
