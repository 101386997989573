import React from 'react';

import { css, cx } from '@emotion/css';
import { cn, siteColors } from '../../lib/colors';

type ContentCardProps = {
	overTitle: React.ReactNode;
	title: React.ReactNode;
	children: React.ReactNode;
	className?: string;
};
export const stylesBlocSales = {
	container: css({
		padding: 24,
		gap: 16,
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
	}),
	title: css({
		fontFamily: 'Inter',
		textTransform: 'uppercase',
		fontSize: '12px',
		fontWeight: 700,
		color: siteColors.grey7,
		padding: 0,
	}),

};

const ContentCard = ({ overTitle, title, children, className }: ContentCardProps) => {
	return (
		<div className={cx('flexColumn gap16 flex1', stylesBlocSales.container)}>
			{overTitle}

			<div className={cx('flexColumn gap4')}>
				<div className={cx(stylesBlocSales.title)}>{title}</div>
				{children}
			</div>

		</div>
	);
};

export default ContentCard;
