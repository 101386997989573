import { Card as MUICard, styled, type CardProps } from '@mui/material';

export const Card = styled<typeof MUICard>(({ elevation = 0, ...props }: CardProps) => { return <MUICard elevation={elevation} {...props} />; })(({ theme }) => {
	const styles = theme.parts.Card;
	return {
		borderRadius: styles.borderRadius,
		borderStyle: styles.borderStyle,
		borderWidth: styles.borderWidth,
		padding: styles.padding,
		borderColor: styles.borderColor,
	};
});
