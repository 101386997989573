import { css, cx } from '@emotion/css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';

import { ReactComponent as CarbonMapsLogoIcon } from '@carbonmaps/media/icons/m.svg';
import { EXPORT_TYPE } from '@carbonmaps/shared/utils/constants';
import { useQuery } from '@tanstack/react-query';
import { cn, siteColors } from '../../lib/colors';

import { getExportLogAction } from '@carbonmaps/ui/actions/export.actions';
import { useTranslation } from '../../hooks/useTranslation';

interface Props {
	open: boolean;
	content: string;
	exportKey?: string;
	type?: string;
	more?: string;
	handleClose?: () => void;
	timeInterval?: number;
}

const styles = {
	container: css({
		minWidth: '200px ',
	}),
	progressBar: css({
		height: '8px !important',
		borderRadius: '4px !important',
	}),
};

const LoadingDialog = ({ open, content, more, exportKey, type, handleClose, timeInterval = 4000 }: Props) => {
	const { t } = useTranslation();
	// ---- fetch data log ---- //
	const {
		data,
		isLoading,
		refetch: getExportLog,
	} = useQuery({
		queryKey: [
			'getExportLog',
			{
				uid: exportKey,
				type,
			},
		],
		queryFn: getExportLogAction,
	} as any);

	const percent = useMemo(() => {
		if (!(data as any)?.result) return 0;

		const { result } = data as any;
		const total = result?.total;
		const success = result?.success;

		return (success * 100) / total;
	}, [data]);



	useEffect(() => {
		if (!exportKey || !open || percent >= 100) return;
		const interval = setInterval(() => {
			const func = async () => {
				console.log('==========  fetch data log ============');
				getExportLog?.();
			};

			func();
		}, timeInterval);

		return () => {
			clearInterval(interval);
		};
	}, [data, getExportLog, exportKey, open]);

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						borderBottom: cn('4px solid', siteColors.primary),
						borderRadius: '24px',
						padding: '32px 116px',
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					textAlign={'center'}
					sx={{
						fontWeight: '500px',
						fontSize: '32px',
						lineHeight: '39px',
						color: siteColors.primary,
					}}
				>
					<CarbonMapsLogoIcon />
				</DialogTitle>

				<DialogContent
					sx={{
						fontWeight: 400,
						fontSize: 16,
						lineHeight: '21px',
						color: siteColors.text,
					}}
				>
					<DialogContentText id="alert-dialog-description">
						<Typography textAlign={'left'} color={siteColors.primary}>
							{content}
						</Typography>
						<Typography maxWidth={'250px'} fontSize={'12px'}>
							{more ? more : exportKey ? t('Veuillez patienter, le chargement peut prendre quelques minutes') : ''}
						</Typography>
						<Typography component="div" variant={'caption'}>
							{/* <Skeleton sx={{ bgcolor: siteColors.primary }} /> */}
							{!exportKey && <LinearProgress css={{ height: '6px', borderRadius: '4px' }} />}
							{exportKey && (
								<div className={cx(styles.container, 'flexRow alignCenter width100 gap4')}>
									<LinearProgress variant="determinate" value={percent} className={cx('flex1', styles.progressBar)} />
									<Typography variant="caption">
										{(data as any)?.result ? `${(data as any)?.result?.success} / ${(data as any)?.result?.total}` : ''}
									</Typography>
								</div>
							)}
						</Typography>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default LoadingDialog;
