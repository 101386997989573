import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';

import _ from 'lodash';

import { useForm } from 'react-hook-form';

import { ChatFormInput, chatFormValidationSchema } from '@carbonmaps/shared/validations/chat.validations';

import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { useTranslation } from '../../hooks/useTranslation';

const styles = {
	formContainer: css({
		width: '100%',
		gap: '12px',
		'.input-container': {
			width: '100%',
		},
	}) as any,
};

type BubbleChatFormProps = {
	onSaveData: (data: ChatFormInput) => void;
	isLoading?: boolean;
	isSuccess?: boolean;
	isError?: boolean;
	email?: string;
};

const BubbleChatForm = ({ isLoading, isSuccess, isError, onSaveData, email }: BubbleChatFormProps) => {
	const {
		register,

		handleSubmit,

		formState: { errors },
	} = useForm<ChatFormInput>({
		resolver: zodResolver(chatFormValidationSchema),
		defaultValues: async () => {
			return {
				email: email || '',
				message: '',
			};
		},
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	const handleSubmitForm = (data: any) => {
		onSaveData(data);
	};

	return (
		<FormContainer
			loading={isLoading}
			buttonTitle={t('Send a message')}
			onSubmit={handleSubmit(handleSubmitForm)}
			isSuccess={isSuccess}
			isError={isError}
			withSave
			addStyles={{ width: '100%' }}
			t={t}
		>
			<div className={cx('flexColumn ', styles.formContainer)}>
				<BInput
					id="email"
					fullWidth
					margin="dense"
					{...register('email')}
					name="email"
					error={errors.email ? true : false}
					errorMessage={t(errors?.email?.message)}
					autoComplete="off"
					placeholder={t('E-mail')}
				/>

				<BInput
					id="message"
					fullWidth
					margin="dense"
					{...register('message')}
					name="message"
					error={errors?.message ? true : false}
					errorMessage={t(errors?.message?.message)}
					autoComplete="off"
					isTextarea={true}
					placeholder={t('Comment pouvons-nous vous aider ?')}
				/>
			</div>
		</FormContainer>
	);
};

export default BubbleChatForm;
