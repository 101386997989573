import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { Cloudy, Droplets, PackageOpen } from 'lucide-react';

import { ALL_YEAR_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';
import { getTotalImpactByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';

import Unit from '../../../../../../components/units/Unit';
import { useYearSelection } from '../../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import KPICard from '../../../../../packagings/components/KPICard';
import KpiValue from '../../../../../packagings/components/KpiValue';

const styles = {
	impactValue: css({
		color: 'var(--color)',
	}),
	impactUnit: css({ paddingLeft: 2 }),

	container: css({
		minWidth: 201,
	}),
};

const ProductImpactKPI = () => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [searchQueryParams] = useSearchQueryParams();
	// period selected
	const { selectedYear } = useYearSelection();
	// ---- fetch count product by period ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getTotalImpactByPeriod', { period: selectedYear, facetFilters: searchQueryParams?.facetFilters || [] }],
		queryFn: getTotalImpactByPeriod,
	});

	return (
		<KPICard
			className={cx('flex1', styles.container)}
			isLoading={isLoading}
			value={
				<KpiValue
					value={data && data[`${indicator}Impact`]}
					unit={
						<Unit
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
							color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
							className={styles.impactUnit}
						/>
					}
					className={styles.impactValue}
					style={{ ['--color' as any]: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}
				/>
			}
			label={
				selectedYear !== ALL_YEAR_OPTION_VALUE ? t('impact_period', { period: selectedYear }) : t('impact_all_year')
			}
		/>
	);
};

export default ProductImpactKPI;
