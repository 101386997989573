import { css, cx } from '@emotion/css';
import _ from 'lodash';

import Skeleton from '../../../../../../components/Skeleton';
import SupplierScore from '../../../../../../components/supplier/SupplierScore';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useRseQFormTabQueryParam } from '../../../../../supplier/general/tabs/rse/hooks/rse.hooks';
import { useGetInfoNoteData } from '../hooks/rse.hooks';

const styles = {
	container: css({
		height: '120px',
		alignSelf: 'stretch',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),

		marginBottom: '32px',
	}),
	text1: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginBottom: 8,
	}),
	text2: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '150%',

		maxWidth: 713,
	}),
};

const InfoNote = () => {
	const { t } = useTranslation();
	const [questionFormId] = useRseQFormTabQueryParam();
	const { data, isLoading } = useGetInfoNoteData({ questionFormId: _.toString(questionFormId) });

	if (isLoading) {
		return <Skeleton sx={{ mb: '32px', borderRadius: '16px', height: '179px' }} />;
	}

	const unAnswered = _.get(data, 'unAnswered');

	return (
		<div className={cx('flexRow alignCenter justifyCenter', styles.container)} >
			<SupplierScore score={_.get(data, 'note')} width={96} height={64} radius={48} fontSize={30} awaitingResponse={_.isEqual(unAnswered, true)}/>
			<div css={{ marginLeft: 24 }}>
				<div className={cx('', styles.text1)}>
					{t('csr-performance-score-of-x', { x: _.get(data, 'supplierName') })}
				</div>
				<div className={cx('', styles.text2)}>{t('csr-score-info-note')}</div>
			</div>
		</div>
	);
};

export default InfoNote;
