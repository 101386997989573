import { useCallback, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { darken, emphasize, lighten } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LegendGraph from '../../containers/dashboard/LegendGraph';
import { siteColors } from '../../lib/colors';
import { orderBy } from '../../utils/array.utils';
import HorizontalGraph from '../dashboard/HorizontalGraph';

type ListProps = {
	data?: any;
	indicator?: 'carbon' | 'water';
	noPaddingTop?: boolean;
};

const styles = {
	content: css({
		padding: 0,
		// background: 'yellow'
	}),
};

const PackagingLineBar = ({ data = [], indicator = CARBON_INDICATOR, noPaddingTop }: ListProps) => {
	const theme = useTheme();
	const navigate = useNavigate();

	// ---- go to analyze page ---- //
	const goToProductPage = useCallback((item: any) => {
		if (item?.path) {
			navigate(item?.path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dataFinal = useMemo(() => {
		return data.map((item: any, index: number) => {
			const color = item.color;
			return {
				path: FRONT_PATH_NAMES.products.details + '/' + item.objectId,
				label: item.label,
				y: item.percentValue,
				percentValue: item.percentValue,
				color:
					index === 0
						? darken(color, 0.1)
						: index < 3
						? emphasize(color, (index * 1.5) / 10)
						: // : index % 2 === 0
						  // 	? lighten(color, 0.5)
						  lighten(color, 0.8),
			};
		});
	}, []);

	const isOneAndNoVolume = useMemo(() => {
		return dataFinal.length === 1 && dataFinal[0].percentValue === 0;
	}, [dataFinal]);

	return (
		<div className={cx('flexColumn width100 gap12', styles.content)}>
			{/* Hide the bar, the space it takes up and the padding around it when you have no volume.
						When you have no volume and only 1 product */}
			{isOneAndNoVolume ? (
				<div style={{ height: 0, padding: 0 }}></div>
			) : (
				<HorizontalGraph
					data={dataFinal}
					// color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
					onClick={goToProductPage}
					width={475}
				/>
			)}

			<div className="width100" style={{ paddingTop: isOneAndNoVolume || noPaddingTop ? 0 : 24 }}>
				<LegendGraph data={dataFinal} column={3} limit={9} />
			</div>
		</div>
	);
};

export default PackagingLineBar;
