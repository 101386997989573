import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import z from 'zod';

import { IUser } from '@carbonmaps/shared/types/user.types';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../hooks/useTranslation';

const resetPasswordSchema = z
	.object({
		password: z
			.string({ required_error: 'Veuillez entrer un nouveau mot de passe' })
			.min(8, 'Choisissez un mot de passe de 8 caractères ou plus')
			.regex(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'Minimum 8 caractères et un caractère spécial'),
		confirmPassword: z
			.string({ required_error: 'Vous devez confirmer votre mot de passe' })
			.min(1, 'Vous devez confirmer votre mot de passe'),
	})
	.refine((data) => {return data.password === data.confirmPassword;}, {
		message: "Le mot de passe saisi n'est pas identique",
		path: ['confirmPassword'],
	});

type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;

type Props = {
	user: IUser;
	token: string;
};

const ResetPasswordForm = ({ user, token }: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const theme = useTheme();

	const { t, i18n } = useTranslation();
	const languageCode = user.emailLanguage || getIsoCountryCode(i18n.language);

	const onSubmit: SubmitHandler<ResetPasswordInput> = async (values) => {
		try {
			setIsLoading(true);
			await Parse.Cloud.run('resetPassword', {
				username: user.username,
				token,
				newPassword: values.password,
				languageCode,
			});
			setIsLoading(false);
			navigate(FRONT_PATH_NAMES.logIn);
		} catch (error) {
			console.log('====================================');
			console.log(error);
			console.log('====================================');
			setIsLoading(false);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordInput>({
		resolver: zodResolver(resetPasswordSchema),
	});

	return (
		<Box px={0} py={2} maxWidth={'386px'}>
			<Typography
				variant="inherit"
				css={{
					fontWeight: 400,
					fontSize: 24,
				}}
			>
				{t('Réinitialiser le mot de passe')}
			</Typography>

			<Box mb="32px">
				<Typography mb={1.5}>
					{t('welcome')} {user.firstName || user.username},
				</Typography>
				<Typography mb={1.5}>
					{t('Entrez le nouveau mot de passe pour votre compte')} {user.email}
				</Typography>
				<Typography>{t('Vous pourrez ensuite vous y connecter')}.</Typography>
			</Box>

			<div css={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
				<BInput
					required
					id="password"
					label={t('password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('password')}
					name="password"
					error={errors.password ? true : false}
					errorMessage={t(errors?.password?.message)}
				/>
				<BInput
					required
					id="confirmPassword"
					label={t('confirm-password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('confirmPassword')}
					name="confirmPassword"
					error={errors.confirmPassword ? true : false}
					errorMessage={t(errors?.confirmPassword?.message)}
				/>
			</div>

			<Box mt={3} css={{ marginBottom: 48 }}>
				<BButton
					variant="primary"
					label={
						isLoading
? (
							<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
						)
: (
							t('register-and-login')
						)
					}
					onClick={handleSubmit(onSubmit)}
					sx={{ minWidth: '400px' }}
				/>
			</Box>
		</Box>
	);
};

export default ResetPasswordForm;
