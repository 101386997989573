import { useEffect } from 'react';

import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';

import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import SelectField from '../../../../../../components/form/fields/SelectField';
import Skeleton from '../../../../../../components/Skeleton';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useFindQuestionFormsTabs, useRseQFormMasterCategoryParam, useRseQFormTabQueryParam } from '../hooks/rse.hooks';

const styles = {
	selectedContainer: css({
		width: '100%',
		background: '#fff !important',
		color: cn(siteColors.grey700, '!important'),
		border: '1px solid rgb(228, 204, 247)',
		marginBottom: '24px',

		'.MuiInputBase-input': {
			paddingBottom: '0px !important',
			minHeight: '32px !important',
			paddingTop: '8px',
			minWidth: '386px !important',
		},
		svg: {
			width: '20px',
			height: '20px',
			color: cn(siteColors.grey700, '!important'),
		},
	}),
	textSelect: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
	}),
};

export const geTallCategoriesOption = (lang: string) => {
	return {
		value: '0',
		label: lang === 'en' ? 'All' : 'Toutes',
	};
};

const MasterCategorySelect = () => {
	const [masterCategory, setMasterCategory] = useRseQFormMasterCategoryParam();
	const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();
	const [questionFormId] = useRseQFormTabQueryParam();
	const { i18n } = useTranslation();

	const languageCode = getIsoCountryCode(i18n.language);

	// console.log('*****', /* tabsData */ languageCode);

	const optionValues = _.get(tabsData, `${questionFormId}.masterCategorySelectOptions`, []).map(
		(item: any, index: number) => {
			return {
				value: _.toString(index + 1),
				label: _.get(item, `label${_.capitalize(languageCode)}`, _.get(item, 'label', '---')),
				...item,
			};
		},
	);

	const allCategoriesOption = geTallCategoriesOption(languageCode);

	const _optionValues = [allCategoriesOption, ...optionValues];

	useEffect(() => {
		if (tabsData) {
			setMasterCategory(_.get(_optionValues, '0', allCategoriesOption));
		}
	}, [/* masterCategory */ tabsData]);

	const onValueChange = (value: any) => {
		// console.log('value', value, typeof value);
		setMasterCategory(_.get(_optionValues, `${value}`, allCategoriesOption));
	};

	if (isLoadingTabsData) {
		return <Skeleton width={400} height={48} sx={{ mb: '24px' }} /* variant="rectangular"  */ />;
	}

	if (_.isEmpty(_.get(tabsData, `${questionFormId}.masterCategorySelectOptions`))) {
		return null;
	}

	return (
		<SelectField
			value={_.get(masterCategory, 'value', '0')}
			items={_optionValues}
			renderValue={(item: any) => {
				return (
					<Typography className={styles.textSelect}>
						{/* {_.get(masterCategory || allCategoriesOption, 'label', '---')} */}
						{_.get(item, 'label', '---')}
					</Typography>
				);
			}}
			onChange={onValueChange}
			className={styles.selectedContainer}
			ExpandMoreRoundedIcon={ChevronDown}
			iconColor={siteColors.grey700}
		/>
	);
};

export default MasterCategorySelect;
