import { Fragment } from 'react';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Link, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, functionName } from '@carbonmaps/shared/utils/constants';
import { getUserFullName } from '@carbonmaps/shared/utils/utils';

import { Card } from '../../../../../../components/_comp/Card';
import Skeleton from '../../../../../../components/Skeleton';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';

const QuestionFormLatestStatusUpdates = () => {
	const params = useParams();

	const { data, isLoading } = useQuery({
		queryKey: [
			functionName.questions.getStatusTopHistoryForQuestionForm,
			{ questionFormId: _.toString(params.questionFormId) },
		] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				const result = await Parse.Cloud.run(functionName.questions.getStatusTopHistoryForQuestionForm, {
					questionFormId,
				});
				return result;
			} catch (error) {
				console.log('---- getStatusTopHistoryForQuestionFormAction ---', error);
				return Promise.reject(error);
			}
		},
	});

	const { t } = useTranslation();
	// const data: any = [];
	const isEmpty = !isLoading && _.isEmpty(data);

	const renderContent = (
		<>
			<div className="grey900 fontWeight500" css={{ marginBottom: '20px' }}>
				{t('latest-status-updates')}
			</div>
			<div className="flexColumn" css={{ gap: '12px' }}>
				{isEmpty
? (<div className="width100 height100 textCenter">
					{t('latest-updates-empty')}
				</div>)
: null}
				{_.map(data, (_item) => {
					const item = _item?.toJSON() || {};
					const statusType = _.get(item, 'statusType', '');
					const key = _.get(item, 'objectId', nanoid());

					const dateDiff = dayjs().diff(dayjs(_.get(item, 'createdAt')), 'day');

					if (_.isEqual('supplier_contacted_group', statusType)) {
						return (
							<div>
								<div>
									{t('survey-sent-to')}{' '}
									<span css={{ color: siteColors.text, fontWeight: 600 }}>
										{_.get(item, 'suppliersCount', 0)} {_.toLower(`${t('supplier')}(s)`)}
									</span>
								</div>
								<div className="grey700">{t('x-days-ago', { x: dateDiff })}</div>
							</div>
						);
					}

					if (_.isEqual('supplier_connected', statusType)) {
						const firstName = _.get(item, 'contact.firstName', '');
						const lastName = _.get(item, 'contact.lastName', '');

						const fullName = getUserFullName({ firstName, lastName });

						return (
							<div>
								<div>
									<Link
										css={{ textDecoration: 'none' }}
										to={`${FRONT_PATH_NAMES.questionForms}/details/${_.toString(
											params.questionFormId,
										)}/suppliers?input=${encodeURIComponent(firstName || lastName)}`}
									>
										<span css={{ color: siteColors.primary }}>{fullName}</span>
									</Link>{' '}
									{t('of')}{' '}
									<Link
										css={{ textDecoration: 'none' }}
										to={`${FRONT_PATH_NAMES.questionForms}/details/${_.toString(
											params.questionFormId,
										)}/suppliers?input=${encodeURIComponent(_.get(item, 'supplier.name', '-'))}`}
									>
									<span css={{ color: siteColors.primary }}>{_.get(item, 'supplier.name', '-')}</span>{' '}
									</Link>
									{t('connected-for-the-first-time')}
								</div>
								<div className="grey700">{t('x-days-ago', { x: dateDiff })}</div>
							</div>
						);
					}

					if (_.isEqual('survey_completed', statusType)) {
						const firstName = _.get(item, 'contact.firstName', '');
						const lastName = _.get(item, 'contact.lastName', '');

						const fullName = getUserFullName({ firstName, lastName });
						const supplierName = _.get(item, 'supplier.name', '-');

						return (
							<div>
								<div>
									<Link
										css={{ textDecoration: 'none' }}
										to={`${FRONT_PATH_NAMES.questionForms}/details/${_.toString(
											params.questionFormId,
										)}/suppliers?input=${encodeURIComponent(firstName || lastName)}`}
									>
										<span css={{ color: siteColors.primary }}>{fullName}</span>
									</Link>{' '}
									{t('of')}{' '}
									<Link
										css={{ textDecoration: 'none' }}
										to={`${FRONT_PATH_NAMES.questionForms}/details/${_.toString(
											params.questionFormId,
										)}/suppliers?input=${encodeURIComponent(supplierName)}`}
									>
										<span css={{ color: siteColors.primary }}>{supplierName}</span>
									</Link>{' '}
									{t('completed-the-survey')}
								</div>
								<div className="grey700">{t('x-days-ago', { x: dateDiff })}</div>
							</div>
						);
					}

					return <Fragment key={key}>{null}</Fragment>;
				})}
			</div>
		</>
	);

	const renderLoadingContent = <Skeleton>{renderContent}</Skeleton>;

	return <Card>{isLoading ? renderLoadingContent : renderContent}</Card>;
};

export default QuestionFormLatestStatusUpdates;
