import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { FormControl, OutlinedInput } from '@mui/material';
import { Check, Pencil } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../../../lib/colors';
import Typography from '../../../Typography';

type EstimatedFieldProps = {
	value: any;
	onSaved: (v: any, t: 'impact' | 'tonnage') => void;
	item: any;
	type?: 'impact' | 'tonnage';
	currentYear?: boolean;
};

const styles = {
	container: css({
		paddingLeft: '4px',
		paddingRight: '4px',
	}),
	inputContainer: css({
		borderRadius: '8px !important',
		'.MuiInputBase-input': {
			padding: '8px 10px !important',
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(228, 204, 247) !important',
		},
	}),
	saveButton: css({
		minWidth: '20px !important',
		height: '36px !important',
	}),
	editButton: css({
		minWidth: '20px !important',
		height: '36px !important',
	}),

	label: css({
		padding: '12px 12px',
	}),
};

const EstimatedField = ({ item, value, onSaved, type = 'impact', currentYear = false }: EstimatedFieldProps) => {
	const [fieldValue, setFieldValue] = useState(value);
	const [isFocused, setIsFocused] = useState(item?.isNew);

	const handleKeyPress = (evt: any) => {
		// force only input for number
		// eslint-disable-next-line no-useless-escape
		let val = evt.target.value?.replace(/[^0-9\,\.]/g, '').replace('.', ',');

		// remove the input like 004 to  4
		if (val.match(/\b0[0-9]\w*\b/g)) {
			val = val.substring(1);
		}

		setFieldValue(val);

		// eslint-disable-next-line no-useless-escape
	};

	const handleChange = (e?: any) => {
		setFieldValue(e?.target?.value);
	};

	const handleEdit = () => {
		setIsFocused(true);
	};

	const handleConfirm = () => {
		setIsFocused(false);
		onSaved(fieldValue, type);
	};

	useEffect(() => {
		setFieldValue(value);
	}, [value]);

	useEffect(() => {
		setIsFocused(item?.isNew);
	}, [item]);

	return (
		<div className={cx('flexRow alignCenter width100 gap4', styles.container)}>
			{isFocused ? (
				<FormControl variant="outlined" className={cx('flex1')}>
					<OutlinedInput
						className={styles.inputContainer}
						value={fieldValue}
						onChange={handleChange}
						onKeyUp={handleKeyPress}
					/>
				</FormControl>
			) : (
				<Typography className={cx('number flex1', styles.label)}>{value}</Typography>
			)}
			{!currentYear ? (
				!isFocused ? (
					<BButton
						label={<Pencil size={20} color={siteColors.grey700} />}
						className={styles.editButton}
						variant="tertiary"
						onClick={handleEdit}
					/>
				) : (
					<BButton
						label={<Check size={20} color="#fff" />}
						className={styles.saveButton}
						variant="primary"
						onClick={handleConfirm}
					/>
				)) : <></>}
		</div>
	);
};

export default EstimatedField;
