import { useEffect, useState } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetQuestionFormById } from '../../questionForms.hooks';

import CustomMessageForm from './screens/custom-messages/CustomMessageForm';
import PrepareQuestionFormSummary from './screens/quetionFrom-summary/PrepareQuestionFormSummary';
import QuestionFormSuppliers from './screens/suppliers-selection/QuestionFormSuppliers';


const PrepareQuestionForm = () => {
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const [screen, setScreen] = useState(0);
	const params = useParams();

	const { setBreadcrumbs } = useApp();

	const { data: questionForm } = useGetQuestionFormById({ id: _.toString(params.questionFormId) });

	useEffect(() => {
		setBreadcrumbs([
			{
				text: t('Collecter'),
				link: `${FRONT_PATH_NAMES.questionForms}/general`,
			},
			{
				text: 	_.get(questionForm, `translation.${languageCode || 'fr'}.name`) || '---',
				link: `${FRONT_PATH_NAMES.questionForms}/details/${params.questionFormId}`,
			},
			{
				text: t('Préparer un envoi'),
			},
		]);
	}, [t, setBreadcrumbs]);

	if (screen === 1) return <QuestionFormSuppliers setScreen={setScreen} />;
	if (screen === 2) return <CustomMessageForm setScreen={setScreen} />;
	return <PrepareQuestionFormSummary setScreen={setScreen} />;
};

export default PrepareQuestionForm;
