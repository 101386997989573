import { Fragment, ReactNode, SyntheticEvent, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import defaultAvatar from '@carbonmaps/media/images/default-avatar.png';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import AvatarContent from '@carbonmaps/ui/components/saas/AvatarContent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import TabsBar, { TabItem } from '../../components/TabsBar';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';
import Page404 from '../404/Page404';

import AccountForm from './AccountForm';
import EmailForm from './EmailForm';
import SecurityForm from './SecurityForm';

type ITab = {
	title: string;
	element: ReactNode;
};

const tabs: Record<string, ITab> = {
	account: {
		title: 'user-profil-tab',
		element: <AccountForm />,
	},
	email: {
		title: 'user-email-tab',
		element: <EmailForm />,
	},
	security: {
		title: 'user-security-tab',
		element: <SecurityForm />,
	},
	notifications: {
		title: 'user-notification-tab',
		element: null,
	},
	'personal-data': {
		title: 'user-data-tab',
		element: null,
	},
};

const UserSettings = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { setBreadcrumbs } = useApp();
	const location = useLocation();

	const { t } = useTranslation();

	useEffect(() => {
		if (!params.tab) {
			setBreadcrumbs([
				{
					text: t('Utilisateur'),
				},
			]);
			return;
		}

		setBreadcrumbs([
			{
				text: t('Utilisateur'),
				link: FRONT_PATH_NAMES.userSettings,
			},
			{
				text: t(tabs[params.tab]?.title),
			},
		]);
	}, [params.tab, setBreadcrumbs, t]);

	const handleTabChange = (_: SyntheticEvent, value: any) => {
		navigate(`${FRONT_PATH_NAMES.userSettings}/${value}`);
	};

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		result: { data: avatarUrl },
	} = useGetImgProxy({
		params: { filename: authData?.user.avatar || '' },
	});

	if (!params.tab) {
		return <Navigate replace state={{ from: location }} to={`${FRONT_PATH_NAMES.userSettings}/account`} />;
	}

	if (!Object.keys(tabs).includes(params.tab)) {
		return <Page404 />;
	}

	return (
		<div>
			<div
				className="flexRow alignCenter justifyCenter width100"
				css={{
					margin: '36px auto',
				}}
			>
				<AvatarContent
					url={authData?.user.avatar ? avatarUrl : defaultAvatar}
					addStyle={{ width: 80, height: 80, objectFit: 'cover' }}
				>
					<div css={{ maxWidth: 350 }}>
						<p
							css={{
								fontWeight: 600,
								fontSize: 24,
								color: siteColors.text,
								margin: 0,
							}}
						>
							{t('welcome')} {authData?.user.firstName || authData?.user.username}
						</p>
						<p
							css={{
								fontWeight: 500,
								fontSize: 16,
								color: siteColors.grey900,
								margin: 0,
							}}
						>
							{authData?.user.email}
						</p>
					</div>
				</AvatarContent>
			</div>

			<Box sx={{ margin: '0 24px' }}>
				<TabsBar value={params.tab} onChange={handleTabChange}>
					{Object.entries(tabs).map(([tabName, value]) => {
						if (['notifications', 'personal-data'].includes(tabName))
							return (
								<TabItem
									title="Section en cours de développement"
									key={tabName}
									label={t(value.title)}
									value={tabName}
									disabled
									role="not-tab"
								/>
							);

						return <TabItem css={{}} key={tabName} label={t(value.title)} value={tabName} />;
					})}
				</TabsBar>
			</Box>
			<Box maxWidth={535} margin={`${pxToRem(56)} auto`}>
				{Object.entries(tabs).map(([tabName, value]) => {
					return tabName === params.tab && <Fragment key={tabName}>{value.element}</Fragment>;
				})}
			</Box>
		</div>
	);
};

export default UserSettings;
