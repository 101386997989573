import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockTitle from '../../../../../components/BlockTitle';
import AchatsTable from '../../../../../components/supplier/AchatsTable';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { useSupplierSetup } from '../../hooks/supplier.hooks';

const SupplierDetailsAchats = () => {
	const { indicator } = useApp();
	const { ingredientsSupplier } = useSupplierSetup();
	const { t } = useTranslation();

	return (
		<div css={{ marginTop: '32px' }}>
			<BlockTitle>{t('Achats de ce fournisseur')}</BlockTitle>
			<AchatsTable data={ingredientsSupplier} indicator={indicator} withPercent />
		</div>
	);
};

export default SupplierDetailsAchats;
