import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';

import { Card } from '../../../components/_comp/Card';
import Skeleton from '../../../components/Skeleton';
import { siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		marginBottom: '32px',
	}),
	card: css({
		width: 'fit-content',
		padding: '16px 24px !important',
		// height: '',
	}),
	icon: css({
		marginRight: '16px',
	}),
	count: css({
		color: siteColors.text,
		fontSize: '24px',
		lineHeight: 1,
	}),
	text: css({
		color: siteColors.grey800,
	}),
};

const QuestionFormKPICard = ({
	icon,
	text,
	count,
	isLoading,
}: {
	icon: ReactNode;
	text: string;
	count: number | string;
	isLoading?: boolean;
}) => {
	const renderContent = (
		<Card className={cx('', styles.card)}>
			<div className={cx('flexRow alignCenter')}>
				<div className={cx('flexCenter', styles.icon)}>{icon}</div>
				<div>
					<div className={cx('', styles.count)}>{count}</div>
					<div className={cx(styles.text)}>{text}</div>
				</div>
			</div>
		</Card>
	);
	return (
		<div className={cx('', styles.container)}>{isLoading ? <Skeleton>{renderContent}</Skeleton> : renderContent}</div>
	);
};

export default QuestionFormKPICard;
