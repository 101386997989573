import { css, cx } from '@emotion/css';
import _, { divide } from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { Card } from '../../../../../../components/_comp/Card';
import HorizontalGraph from '../../../../../../components/dashboard/HorizontalGraph';
import Skeleton from '../../../../../../components/Skeleton';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import { useGetStatusPercentage } from '../../../../questionForms.hooks';

const styles = {
	row: css({
		padding: '8px 0',
	}),
	borderBottom: css({
		borderBottom: `1px solid ${siteColors.grey500}`,
	}),
	dot: css({
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		marginRight: '8px',
	}),
};

const getStatusPercentage = (statusMap: any, totalSuppliersCount: any, statusType: any) => {
	return ((_.get(statusMap, statusType, 0) as number) / (totalSuppliersCount || 1)) * 100;
};

const QuestionFormStatusRepartition = () => {
	const params = useParams();
	const { t } = useTranslation();

	const questionFormId = _.toString(params.questionFormId);

	const { data, isLoading } = useGetStatusPercentage({ questionFormId });

	const totalSuppliersCount = _.get(data, 'totalSuppliersCount', 0);
	const statusMap = _.get(data, 'statusMap', {}) as Record<string, number>;

	const dataReceivedPercent = getStatusPercentage(statusMap, totalSuppliersCount, 'survey_completed');
	const supplierConnectedPercent = getStatusPercentage(statusMap, totalSuppliersCount, 'supplier_connected');
	const supplierContactedPercent = getStatusPercentage(statusMap, totalSuppliersCount, 'supplier_contacted');

	const renderContent = (
		<>
		<div>
			<div className="grey900 fontWeight500">{t('progress-report')}</div><div className="grey800">{t('suppliers-current-state')}</div>
		</div>
			<div
				css={{
					paddingTop: '16px',
					paddingBottom: '16px',
				}}
			>
				<HorizontalGraph
					data={[
						{
							color: siteColors.orange500,
							y: supplierContactedPercent,
							percentValue: supplierContactedPercent,
							label: t('survey-sent'),
						},
						{
							color: siteColors.primary,
							y: supplierConnectedPercent,
							percentValue: supplierConnectedPercent,
							label: t('registration-done'),
						},
						{
							color: siteColors.green500,
							y: dataReceivedPercent,
							percentValue: dataReceivedPercent,
							label: t('survey-completed'),
						},
					]}
				/>
			</div>

			<div css={{ marginBottom: '16px' }} className="grey800">
				<div className={cx('flexRow spaceBetween', styles.row, styles.borderBottom)}>
					<div>{t('suppliers-in-the-campaign')}</div>
					<div className="grey900 fontWeight500">{totalSuppliersCount}</div>
				</div>
				<div className={cx('flexRow spaceBetween', styles.row, styles.borderBottom)}>
					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.orange500 }}>
						</div>
						{t('survey-sent')}
					</div>
					<div className="grey900 fontWeight500">{_.get(statusMap, 'supplier_contacted', 0)}</div>
				</div>
				<div className={cx('flexRow spaceBetween', styles.row, styles.borderBottom)}>
					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.primary }}></div>
						{t('registration-done')}
					</div>

					<div className="grey900 fontWeight500">{_.get(statusMap, 'supplier_connected', 0)}</div>
				</div>
				<div className={cx('flexRow spaceBetween', styles.row)}>
					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.green500 }}></div>
						{t('survey-completed')}
					</div>
					<div className="grey900 fontWeight500">{_.get(statusMap, 'survey_completed', 0)}</div>
				</div>
			</div>

			<div>
				<Link to={`${FRONT_PATH_NAMES.questionForms}/details/${questionFormId}/suppliers`}>
					<BButton label={t('see-all-suppliers')} variant="secondary" />
				</Link>
			</div>
		</>
	);

	const renderLoading = <Skeleton>{renderContent}</Skeleton>;

	return <Card sx={{ overflow: 'unset' }}>{isLoading ? renderLoading : renderContent}</Card>;
};

export default QuestionFormStatusRepartition;
