import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import { cn, siteColors } from '../../lib/colors';

type LabelLogoProps = {
	label: string;
	uid?: string;
	icon?: React.ReactNode;
	withLink?: boolean;
	getLink?: (info?: any) => string;
	onClick?: (info?: any) => void;
	popoverContent?: ReactNode;
};

const styles = {
	container: css({
		gap: '1px',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),

		padding: 24,
	}),
	flexGrow: css({
		flexGrow: 1,
		gap: '8px',
	}),
	email: css({
		padding: '8px 16px 8px 16px',
		background: siteColors.grey300,
		minWidth: '351px',
		borderRadius: '8px',
		color: siteColors.grey900,
	}),
	subtitle: css({
		maxWidth: '300px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		justifySelf: 'start',
	}),
	content: css({
		gap: '8px',
		marginBottom: 3,
	}),
	iconContainer: css({
		borderRadius: '8px',
		padding: '8px',
		width: '36px',
		height: '36px',
	}),
	mainLabel: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	}),

	decorationNone: css({
		textDecoration: 'none',
	}),
};

const LabelLogo = ({ label, uid, icon, withLink, getLink, onClick, popoverContent }: LabelLogoProps) => {
	const renderWithoutPopover = (
		<div className={cx('flexRow nowrap alignCenter', styles.flexGrow)}>
			<div className={cx('bgGrey400', styles.iconContainer)}>{icon}</div>
			<div>
				<div className={cx('flexRow nowrap alignCenter', styles.content)}>
					<Typography className={cx('labelLogoText', styles.mainLabel)} noWrap>
						{label}
					</Typography>
				</div>

				<Typography variant="h5" className={cx('labelLogoText grey700', styles.subtitle)}>
					{uid}
				</Typography>
			</div>
		</div>
	);
	const renderWithPopover = (
		<BPopover
			content={(onclose) => {
				return popoverContent;
			}}
			modeHover
			transformOriginHorizontal="center"
			transformOriginVertical="top"
			anchorOriginHorizontal="center"
			anchorOriginVertical="bottom"
		>
			{renderWithoutPopover}
		</BPopover>
	);

	const content = popoverContent ? renderWithPopover : renderWithoutPopover;

	if (withLink) {
		return (
			<Link to={getLink?.() || '#'} className={cx('', styles.decorationNone)} onClick={(onClick || (() => {})) as any}>
				{content}
			</Link>
		);
	}

	return content;
};

export default LabelLogo;
