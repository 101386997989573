import { useQuery } from '@tanstack/react-query';


import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getReportClientBasicInfoAction, getReportClientInfoAction, getReportClientPackagingAction } from '../actions/sales.actions';




export const useReportClientBasicInfo = ({
	objectId,
	onError,
	onSuccess,
}: {
	objectId: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const queryResult = useQuery({
		queryKey: ['getReportClientBasicInfo', {
			objectId,
		}],
		queryFn: getReportClientBasicInfoAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useReportClientInfo = ({
	objectId,
	// period,
	fromPath,
	onError,
	onSuccess,
	viewMode,
	sortingBy,
}: {
	objectId: string;
	viewMode?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {

	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getReportClientInfo', {
			objectId,
			period: selectedYear,
			viewMode
		}],
		queryFn: getReportClientInfoAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};


export const useReportClientPackaging = ({
	objectId,
	// period,
	fromPath,
	onError,
	onSuccess,
	viewMode,
	sortingBy,
}: {
	objectId: string;
	viewMode?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getReportClientPackaging', {
			objectId,
			period: selectedYear,
			// sortingBy,
			viewMode
		}],
		queryFn: getReportClientPackagingAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
