import { css, cx } from '@emotion/css';

import MaterialEndOfLifeBlock from './MaterialEndOfLifeBlock';
import MaterialEndOfLifeTable from './MaterialEndOfLifeTable';
import RecycledPackagingBlock from './RecycledPackagingBlock';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	container: css({
		paddingBottom: 85,
		width: CONTENT_WIDTH,
		maxWidth: CONTENT_MAX_WIDTH,
		margin: 'auto',
	}),
};

const PackagingsEndOfLife = () => {
	return (
		<div className={cx('flexColumn width100 gap24', styles.container)}>
			<MaterialEndOfLifeBlock />
			{/* <RecycledPackagingBlock /> */}
			<MaterialEndOfLifeTable />
		</div>
	);
};

export default PackagingsEndOfLife;
