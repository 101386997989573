import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { Eye, Send } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { Card } from '../../../../../../components/_comp/Card';
import Dialog from '../../../../../../components/dialogs/Dialog';
import QuestionPreview from '../../../../../../components/question/QuestionPreview';
import Typography from '../../../../../../components/Typography';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../../lib/react-query/features/auth/auth.hooks';


const styles = {
	title: css({
		color: '#140C27',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: 'normal',
	}),

	iconContainer: css({
		borderRadius: '8px',
		padding: '8px',
		width: '40px',
		height: '40px',
		background: siteColors.orange10,
	}),
};

export type QuestionFormValues = {
	objectId?: string;
	translation: {
		en: {
			typeFormId: string;
			name: string;
			url: string;
		};
		fr: {
			typeFormId: string;
			name: string;
			url: string;
		};
		it: {
			typeFormId: string;
			name: string;
			url: string;
		};
		es: {
			typeFormId: string;
			name: string;
			url: string;
		};
		de: {
			typeFormId: string;
			name: string;
			url: string;
		};
	};
	lang: string;
	published?: boolean;
	type: string;
};

const QuestionFormItem = ({ questionFormObject }: { questionFormObject: QuestionFormValues }) => {
	const { t } = useTranslation();
	const [isDialogOpen, toggleDialog] = useToggle(false);
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	return (
		<>
			<Card className="flexColumn" css={{ gap: '16px' }}>
				<div className={cx('flexCenter', styles.iconContainer)}>
					<Send size={32} color={siteColors.orange500} />
				</div>

				<div className="flexColumn" css={{ gap: '2px' }}>
					<div className={cx('', styles.title)}>
						{questionFormObject?.translation?.fr?.name ||
							questionFormObject?.translation?.en?.name ||
							questionFormObject?.translation?.it?.name ||
							questionFormObject?.translation?.es?.name}
					</div>

					<div>
						<Typography className="grey800">
						{t('x-envois', {
							x: _.toNumber(_.get(questionFormObject, `campaignCounts.${authData?.session.company?.code}`, 0)),
						})}</Typography>
					</div>
				</div>

				<div>
					<Link to={`${FRONT_PATH_NAMES.questionForms}/details/${questionFormObject.objectId}`}>
						<BButton label={<div className={cx('flexRow black')}>{t('campaign-details')}</div>} variant="secondary" />
					</Link>
					<BButton
						iconLeft={<Eye color="#9587AE" size={20} />}
						label={<div className={cx('flexRow black')}>{t('Aperçu')}</div>}
						variant="tertiary"
						onClick={toggleDialog}
					/>
				</div>
			</Card>

			<Dialog
				title={''}
				isLoading={false}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				closeButton={true}
				withCancelButton={false}
				paperProps={{
					maxWidth: '100vw',
				}}
			>
				<QuestionPreview
					title={
						questionFormObject?.translation?.fr?.name ||
						questionFormObject?.translation?.en?.name ||
						questionFormObject?.translation?.it?.name ||
						questionFormObject?.translation?.es?.name ||
						questionFormObject?.translation?.de?.name
					}
					translation={questionFormObject?.translation}
				/>
			</Dialog>
		</>
	);

	// return (
	// 	<div className={cx('flexRow', styles.container)}>
	// 		<div className={cx('', styles.flexGrow)}>
	// 			<div className={cx('', styles.title)}>
	// 				{questionFormObject?.translation?.fr?.name ||
	// 					questionFormObject?.translation?.en?.name ||
	// 					questionFormObject?.translation?.it?.name ||
	// 					questionFormObject?.translation?.es?.name}
	// 			</div>
	// 			<div>
	// 				{t('x-envois', {
	// 					x: _.toNumber(_.get(questionFormObject, `campaignCounts.${authData?.session.company?.code}`, 0)),
	// 				})}
	// 			</div>
	// 		</div>

	// 		<div>
	// 			<BButton
	// 				iconLeft={<Eye color="#9587AE" size={20} />}
	// 				label={<div className={cx('flexRow black')}>{t('Aperçu')}</div>}
	// 				variant="tertiary"
	// 				sx={{ marginRight: '16px' }}
	// 				onClick={toggleDialog}
	// 			/>
	// 			<Link to={`${FRONT_PATH_NAMES.questionForms}/prepare/${questionFormObject.objectId}`}>
	// 				<BButton label={<div className={cx('flexRow black')}>{t('Préparer un envoi')}</div>} variant="secondary" />
	// 			</Link>
	// 		</div>

	// 		<Dialog
	// 			title={''}
	// 			isLoading={false}
	// 			description={''}
	// 			open={isDialogOpen}
	// 			toggle={toggleDialog}
	// 			closeButton={true}
	// 			withCancelButton={false}
	// 			paperProps={{
	// 				maxWidth: '100vw',
	// 			}}
	// 		>
	// 			<QuestionPreview
	// 				title={
	// 					questionFormObject?.translation?.fr?.name ||
	// 					questionFormObject?.translation?.en?.name ||
	// 					questionFormObject?.translation?.it?.name ||
	// 					questionFormObject?.translation?.es?.name ||
	// 					questionFormObject?.translation?.de?.name
	// 				}
	// 				translation={questionFormObject?.translation}
	// 			/>
	// 		</Dialog>
	// 	</div>
	// );
};

export default QuestionFormItem;
