import { useCallback, useEffect } from 'react';

import { useJuneTrack } from '../../../../../hooks/useJuneTrack';

import SuppliersDetailsTable from './blocks/SupliersDetailsTable';
import SuppliersFilter from './blocks/SuppliersFilter';

const QuestionFormDetailsSuppliers = () => {
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Question Form' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Question Form Suppliers Land');
	}, [trackEvent]);
	return (
		<div css={{
			'& .filterContainer-wrapper': {
				paddingLeft: 0,
				paddingRight: 0,
			},
		}}>
			<SuppliersFilter withMore={false} />
			<SuppliersDetailsTable />
		</div>
	);
};

export default QuestionFormDetailsSuppliers;
