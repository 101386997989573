import React, { forwardRef, useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { useExpanded, usePagination, /* useRowSelect, */ useSortBy, useTable } from 'react-table';

import Skeleton from '../../../../../apps/front/src/components/Skeleton';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { IFacetFilter } from '../../../actions/product.actions';
import { useRowTableExpandedOpen, useRowTableSelection } from '../../../hooks/useRowTableSelection';

import Action from './Action';
import ActionsCell from './ActionsCell';
import AcvDelta from './AcvDelta';
import AcvDiff from './AcvDiff';
import AcvIntensity from './AcvIntensity';
import AcvLabel from './AcvLabel';
import AcvPopoverField from './AcvPopoverField';
import AuditContentField from './AuditContentField';
import BarField from './BarField';
import CursorComponent from './CursorComponent';
import DateField from './DateField';
import DeleteVersionField from './DeleteVersionField';
import EditSupplierUser from './EditSupplierUser';
import { EditableCheckBox } from './EditableCheckBox';
import EditableEmailInput from './EditableEmailInput';
import { EditableInput } from './EditableInput';
import EndOfLIfeMaterialDisplay from './EndOfLIfeMaterialDisplay';
import ErrorTypeField from './ErrorTypeField';
import FinalProduct from './FinalProduct';
import ImpactEnv from './ImpactEnv';
import ImpactPortion from './ImpactPortion';
import Ingredient from './Ingredient';
import InputField from './InputField';
import InputFieldQuote from './InputFieldQuote';
import Intensity from './Intensity';
import IntensityField from './IntensityField';
import LineBar from './LineBar';
import LinkField from './LinkField';
import MaterialLabel from './MaterialLabel';
import Modelization from './Modelization';
import { Pagination } from './Pagination';
import PercentField from './PercentField';
import Percentage from './Percentage';
import PortionField from './PortionField';
import RecycledPercentField from './RecycledPercentField';
import Reference from './Reference';
import RoleCell from './RoleCell';
import RoleCellNew from './RoleCellNew';
import RowUserForm from './RowUserForm';
import Scoring from './Scoring';
import SimpleCell from './SimpleCell';
import SoldItemsField from './SoldItemsField';
import Supplier from './Supplier';
import SwitchCell from './SwitchCell';
import TableActions from './TableActions';
import TagLabel from './TagLabel';
import Text from './Text';
import TextAuditDisplay from './TextAuditDisplay';
import TruncatedSimpleCell from './TruncatedSimpleCell';
import UserField from './UserField';
import VSReference from './VSReference';
import VsMarket from './VsMarket';
import WaterPercentagePopOver from './WaterPercentagePopOver';
import { useRowSelect } from './useRowSelect';

export const getStickyHeaderStyle = (breakpoint = 1354, top = 148) => {
	return css({
		[`@media screen and (max-width: ${breakpoint}px)`]: {
			maxWidth: '100%!important',
			overflow: 'auto!important',
		},
		[`@media screen and (min-width: ${breakpoint + 1}px)`]: {
			'& thead:first-of-type tr': {
				position: 'sticky',
				zIndex: 99,
				top: `${top}px`,
			},
		},
	});
};

export const getStickyRowStyle = (breakpoint = 1354, top = 154) => {
	return css({
		[`@media screen and (max-width: ${breakpoint}px)`]: {
			maxWidth: '100%!important',
			overflow: 'auto!important',
		},
		[`@media screen and (min-width: ${breakpoint + 1}px)`]: {
			position: 'sticky',
			zIndex: 99,
			top: `${top}px`,
		},
	});
};

const tableStyles = {
	content: css({
		display: 'block',
		maxWidth: '100%',
	}),

	innerContainer: css({
		maxWidth: '100%',
		overflow: 'auto',
	}),

	table: css({
		borderSpacing: 0,
		color: siteColors.grey900,
		width: '100%',
	}),

	trContainer: css({
		'&:hover .custom-tooltip-actions': { visibility: 'visible', display: 'block' },
	}),

	actionTable: css({
		minWidth: 65,
		':hover': {
			'.custom-tooltip-actions': { visibility: 'visible', display: 'block !important' },
		},
	} as any),

	tooltipActions: css({
		display: 'block',
		visibility: 'hidden',
		height: '100%',
	}),

	thContainer: css({
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '16px !important',
		lineHeight: '100%!important',
		background: 'white!important',
	}),

	selectedTypeTh: css({
		padding: '0px 0px!important',
		width: 20,
	}),
	otherThType: css({
		padding: '0px 16px!important',
		fontWeight: 400,
	}),

	headerThContent: css({
		paddingTop: '12px',
		gap: 10,
	}),

	headerText: css({
		color: siteColors.grey700,
		padding: '0',
		margin: '0',
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '60%!important',
		textAlign: 'right',
		marginBottom: '12px',
	}),

	skeletonTd: css({
		borderBottom: 'none!important',
		padding: '8px 0 0 0 !important',
	}),

	skeleton: css({
		flexGrow: 1,
		height: '38px !important',
	}),

	td: css({
		fontStyle: 'normal !important',
		fontSize: '16px !important',
		lineHeight: '100%!important',
	}),
	tr: css({
		overflow: 'hidden !important',
	}),

	subRowsTitle: css({
		padding: '10px 13px',
		color: '#000',
		fontSize: '14px',
		fontWeight: '500',
		marginLeft: 98,
	}),

	disabledRow: css({
		pointerEvents: 'none !important',
		opacity: 0.8,
		'p, span, div': {
			color: cn(siteColors.grey700, '!important'),
		},
		'.percent-progress, svg': {
			opacity: 0.2,
		},
	} as any),
	headerAction: css({
		width: 85,
	}),

	buttonExpanded: css({
		border: 'none',
		background: 'none',
		padding: 0,
		margin: 0,
		cursor: 'pointer',
	}),
};

const classes = () => {
	return {
		table: {
			borderSpacing: 0,
			color: siteColors.grey900,
			width: '100%',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			width: '100%',
			gap: 10,
			'.bordered': {
				border: '1px solid #CCCCCC',
				borderRadius: '6px 6px 6px 6px',
				paddingBottom: 3,
			},

			'.bordered th:first-of-type': {
				borderRadius: '6px 0 0 0',
			},

			'.bordered th:last-child': {
				borderRadius: '0 6px 0 0',
			},

			'.bordered td:first-of-type, .bordered th:first-of-type': {
				borderLeft: 'medium none',
			},

			'.bordered th': {
				backgroundColor: siteColors.grey400,
				borderTop: 'none!important',
			},
			'.bordered td, .bordered th': {
				borderLeft: '1px solid #CCCCCC',
				borderTop: '1px solid #CCCCCC',
				textAlign: 'left',
			},
			'.bordered td:not(.editable), .bordered th': {
				//padding: '10px',
			},
			'.pagination': {
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 10,
			},

			// hover states
			'& table tbody > tr:hover > td': {
				background: cn(siteColors.grey200, '!important'),
			},
		},
		td: {
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '100%!important',
		},
		tr: {
			overflow: 'hidden',
		},
	};
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
	Cell: EditableInput,
	Checkbox: EditableCheckBox,
	EditableInput,
	InputField,
	InputFieldQuote,
	PortionField,
	EditableEmailInput,
	SimpleCell,
	SwitchCell,
	ActionsCell,
	RoleCell,
	RoleCellNew,
	ImpactEnv,
	FinalProduct,
	Intensity,
	VSReference,
	EditSupplierUser,
	Reference,
	Ingredient,
	Action,
	CursorComponent,
	TagLabel,
	Supplier,
	Percentage,
	WaterPercentagePopOver,
	TruncatedSimpleCell,
	PercentField,
	IntensityField,
	SoldItemsField,
	Modelization,
	Scoring,
	Text,
	TextAuditDisplay,
	ErrorTypeField,
	AuditContentField,
	AcvIntensity,
	AcvDiff,
	VsMarket,
	AcvPopoverField,
	LinkField,
	LineBar,
	AcvLabel,
	UserField,
	DateField,
	ImpactPortion,
	DeleteVersionField,
	AcvDelta,
	BarField,
	MaterialLabel,
	RecycledPercentField,
	EndOfLIfeMaterialDisplay,
};

// type FacetFilter = {
// 	path: string;
// 	values: string[];
// };

interface TableComponentProps {
	loading?: boolean;
	columns: any[];
	defaultSortBy?: { id: string; desc: boolean }[];
	noDataMessage?: string;
	resetPage?: number;
	fetchData?: (fetchOptions: {
		pageIndex: number;
		pageSize: number;
		id: string;
		direction: number;
		input?: string;
		sortingBy?: any;
		facetFilters?: IFacetFilter[];
	}) => void;
	data: any[];
	pageCount: any;
	pageSize?: number;
	addStyles?: any;
	isSelectable?: boolean;
	// confirmDelete?: (confirmOptions: { ev: object }) => void;
	confirmDelete?: any;
	updateRole?: (options: { ev: object }) => void;
	updateEmail?: (options: { ev: object }) => void;
	setSelectedRow?: (e: any) => void;
	tableName?: string;
	isOpenRowAdd?: boolean;
	manualSortBy?: boolean;
	onSortingColumn?: (v: any) => any;
	pagination?: boolean;
	manualPagination?: boolean;
	skeleton?: boolean;
	className?: string;
	rowSelection?: any;
	stickyHeader?: boolean;
	stickyHeaderStyles?: string;
	// stickyHeaderOffsetTop?: string;
	isExpanded?: boolean;
	withActionToggle?: boolean;
	withEmptyData?: boolean;
	updateMyData?: (index: number, id: string, val: any) => void;

	onEditRow?: (row: any) => void;
	onDeleteRow?: (row: any) => void;
	onRestoreRow?: (row: any) => void;
	nbColumns?: number;
	defaultExpanded?: any;
	headerRow?: React.ReactNode;
	showRowEditButton?: boolean;
	setIsOpenRowAdd?: any;
	dialogTitle?: string;
	transFunc?: any;
	tableId?: string;

	trackingOptions?: {
		name: string;
		category: string;
		options: any;
	};

	onNextClick?: () => void;
}
interface SortValue {
	id: string;
	direction: number;
}

export const IndeterminateCheckbox = forwardRef(({ indeterminate, stopPropagation, ...rest }: any, ref) => {
	const defaultRef = useRef();
	const resolvedRef: any = ref || defaultRef;

	// console.log('rest', rest);

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<Checkbox
				ref={resolvedRef}
				{...rest}
				onClick={(e) => {
					if (stopPropagation) e.stopPropagation();
					rest.onClick && rest.onClick(e);
				}}
			/>
		</>
	);
});

export const TableComponent = ({
	columns,
	data = [],
	fetchData,
	confirmDelete,
	pageCount: controlledPageCount,
	addStyles,
	isSelectable = true,
	isExpanded = false,
	pageSize: initialPageSize,
	updateRole,
	noDataMessage = '',
	updateEmail,
	resetPage = 0,
	tableName = '',
	isOpenRowAdd = false,
	manualSortBy = false,
	onSortingColumn,
	defaultSortBy = [],
	pagination = true,
	manualPagination = true,
	skeleton = false,
	nbColumns,
	rowSelection,
	className = '',
	stickyHeader = false,
	stickyHeaderStyles = 'should-be-a-className',
	updateMyData,
	onEditRow,
	onDeleteRow,
	onRestoreRow,
	showRowEditButton = true,
	withActionToggle,
	withEmptyData = true,
	defaultExpanded,
	headerRow,
	setIsOpenRowAdd,
	dialogTitle,
	transFunc,
	tableId,
	onNextClick,
}: TableComponentProps) => {
	const styles = classes();

	const { onSelectionChange, selectedRows } = useRowTableSelection();
	const { onSelectionExpandedOpen, expandedRows } = useRowTableExpandedOpen();
	const { i18n } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,

		// Get the state from the instance
		prepareRow,
		state: { pageIndex, pageSize, sortBy },
		...rest
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: initialPageSize,
				rowSelection,
				expanded: defaultExpanded ? defaultExpanded : {},
			}, // Pass our hoisted table state
			manualPagination: manualPagination, // Tell the usePagination
			autoResetPage: false,
			pageCount: controlledPageCount,
			// ====
			defaultColumn,
			defaultSortBy,
			defaultSortDesc: false,
			manualSortBy: manualSortBy,
			updateMyData,
		} as any,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				return [
					...(isExpanded
						? [
								{
									id: 'expander', // Make sure it has an ID
									Header: ({
										getToggleAllRowsSelectedProps,
									}: any) => {
										return (
											<>
												{['simulation-table', 'devisCarboneTable'].includes(tableId as any)
													? (
													<div>
														<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
													</div>
												)
													: null}
											</>
										);
									},
									Cell: ({ row }: any) => {
										return row.canExpand
										? (
											<div {...row.getToggleRowExpandedProps({})} className="flexRow alignCenter justifyCenter">
												<button
													className={tableStyles.buttonExpanded}
													onClick={() => {
														// add row in the list of expanded rows else remove from the expanded rows
														if (row.canExpand && !row.isExpanded) {
															onSelectionExpandedOpen([...expandedRows, row?.original?.objectId]);
														} else {
															onSelectionExpandedOpen([
																...expandedRows.filter((item: any) => {
																	return item !== row?.original?.objectId;
																}),
															]);
														}
													}}
												>
													{row.isExpanded
? (
														<ChevronDown color={siteColors.grey700} />
													)
: (
														<ChevronRight color={siteColors.grey700} />
													)}
												</button>
											</div>
										)
										: null;
									},
								},
						  ]
						: []),

					// Let's make a column for selection
					...(isSelectable && !['simulation-table', 'devisCarboneTable'].includes(tableId as any)
						? [
								{
									id: 'selection',
									// The header can use the table's getToggleAllRowsSelectedProps method
									// to render a checkbox
									Header: ({ getToggleAllRowsSelectedProps }: any) => {
										return (
											<div>
												<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
											</div>
										);
									},
									// The cell can use the individual row's getToggleRowSelectedProps method
									// to the render a checkbox
									Cell: ({ row }: any) => {
										// console.log('row', row);

										return row.depth > 0
											? null
											: (
											<div>
												<IndeterminateCheckbox
													// {...row.getRowProps()}
													{...row.getToggleRowSelectedProps()}
													// {...(row.subRows.length ? row.getToggleRowExpandedProps() : row.getToggleRowSelectedProps())}
													// {...(row.subRows.length && !row.isSomeSelected ? { isSelected: false } : {})}
												/>
											</div>
										);
									},
									disableSortBy: true,
									type: 'select',
								},
						  ]
						: []),
					...columns,

					...(withActionToggle
						? [
								{
									id: 'row-action', // Make sure it has an ID
									Header: () => {
										return <div className={showRowEditButton ? tableStyles.headerAction : ''}></div>;
									},
									Cell: ({ row }: any) => {
										return <span>Div</span>;
									},
								},
						  ]
						: []),
				];
			});
		},
	) as any;

	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		if (!fetchData) return;

		fetchData({ pageIndex, pageSize } as any);

		if (pageIndex > pageCount) {
			gotoPage(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageIndex, pageSize, gotoPage]);

	useEffect(() => {
		if (resetPage > 0) {
			// if reset the search params filters then go to the first page
			gotoPage(0);
		}
	}, [resetPage]);

	// const prevRef = useRef();

	useEffect(() => {
		// setSelectedRow && setSelectedRow(selectedFlatRows);
		// onSelectionChange(selectedFlatRows);
		if (_.isEqual(selectedFlatRows, selectedRows)) return;

		onSelectionChange((_oldValue: any) => {
			return selectedFlatRows;
		});
		// // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [/* onSelectionChange,  */ selectedFlatRows]);

	// ------------------------------------------------------- //
	// -------------- trigger manual sorting table ----------- //
	// ------------------------------------------------------- //

	useEffect(() => {
		if (!onSortingColumn || !sortBy.length) return;
		onSortingColumn(sortBy[0]);
	}, [sortBy]);

	// Render the UI for your table

	// useEffect(() => {
	// 	// if (!stickyHeader) return;
	// 	if (skeleton) return;

	// 	const thArray = [...document.querySelectorAll<HTMLTableCellElement>('.th-xxx-table')];
	// 	console.log('thArray', thArray);

	// 	const tdArray = [...document.querySelectorAll<HTMLTableCellElement>('tbody:first-of-type > tr:first-of-type  td')];
	// 	console.log('tdArray', tdArray);

	// 	if (tdArray.length < columns.length) return;

	// 	tdArray.forEach((e, index) => {
	// 		thArray[index].style.width = `${e.getBoundingClientRect().width}px`;
	// 	});
	// }, [columns.length, skeleton]);

	const renderBody = () => {
		return skeleton
			? (
			<tbody>
				{Array.from({ length: 10 }).map((_, index) => {
					return (
						<tr key={index}>
							<td colSpan={nbColumns ?? columns.length + 1} className={tableStyles.skeletonTd}>
								<Skeleton className={cx('width100', tableStyles.skeleton)} />
							</td>
						</tr>
					);
				})}
			</tbody>
		)
		: data && data.length === 0 && withEmptyData
			? (
			<tbody>
				<tr>
					<td css={{ textAlign: 'center', height: 50, paddingLeft: '15px' }} colSpan={columns.length + 1}>
						{noDataMessage}
					</td>
				</tr>
			</tbody>
		)
		 : (
			<tbody {...getTableBodyProps()}>
				{headerRow}
				{tableName === 'user' && <RowUserForm setIsOpenRowAdd={setIsOpenRowAdd} isOpenRowAdd={isOpenRowAdd} />}

				{page.map((row: any, i: number) => {
					prepareRow(row);

					return (
						<React.Fragment key={i}>
							<tr
								{...row.getRowProps()}
								className={cx(
									row?.original?.isDisabled ? tableStyles.disabledRow : '',
									row?.original?.isSticky ? row?.original?.stickyRowStyle : 'positionRelative',
									tableStyles.trContainer,
								)}
							>
								{row.cells.map((cell: any, indexKey: number) => {
									if (cell.column.id === 'delete' || cell.column.type === 'booleanStatus') {
										return (
											<td
												{...cell.getCellProps({ className: cell.column.className })}
												className="textAlignLeft"
												style={styles.td}
												key={indexKey}
											>
												{cell.render('Cell', { callback: confirmDelete })}
											</td>
										);
									}

									if (cell.column.id === 'email' && cell.column.editable) {
										return (
											<td
												{...cell.getCellProps({ className: cell.column.className })}
												className="textAlignLeft bgWhite td-xxx-table"
												key={indexKey}
											>
												{cell.render('EditableEmailInput', { callback: updateEmail })}
											</td>
										);
									}

									if (cell.column.id === 'role' && cell.column.editable) {
										return (
											<td
												{...cell.getCellProps({ className: cell.column.className })}
												className={cx('textAlignLeft bgWhite ', tableStyles.td)}
												key={indexKey}
											>
												{cell.render('RoleCellNew', { callback: updateRole })}
											</td>
										);
									}

									if (cell.column.id === 'row-action') {
										return (
											<td {...cell.getCellProps()} className={cx('bgWhite', tableStyles.actionTable)} key={indexKey}>
												<div className={cx('custom-tooltip-actions ', `row-action-${i}`, tableStyles.tooltipActions)}>
													{/* {row.original.isOriginal && row.original.isPresent ? (
														) : null} */}
													<TableActions
														row={row}
														onEditRow={() => {
															return onEditRow && onEditRow(row?.original);
														}}
														onDeleteRow={() => {
															console.log('row?.original', row?.original);
															return onDeleteRow && onDeleteRow(row?.original);
														}}
														onRestoreRow={() => {
															console.log('row?.original', row?.original);
															return onRestoreRow && onRestoreRow(row?.original);
														}}
														dialogTitle={dialogTitle}
														showRowEditButton={showRowEditButton}
													/>
												</div>
											</td>
										);
									}

									return (
										<td
											{...cell.getCellProps()}
											className={cx(
												cell.column.editable ? 'editable' : '',
												cell.column.editable ? 'bgWhite' : '',
												cell.column.type === 'number' ? 'textAlignRight' : 'textAlignLeft',
												cell.column.type === 'select' ? tableStyles.selectedTypeTh : tableStyles.otherThType,
												'noBorderRight',
												tableStyles.td,
											)}
											key={indexKey}
										>
											{cell.column.editable || cell.column.id === 'action'
												? cell.render(cell?.column?.component || 'Checkbox')
												: cell.column.id === 'selection' || cell.column.id === 'expander'
												? cell.render('Cell')
												: cell.value}
										</td>
									);
								})}
							</tr>
							{row.original?.footer
? (
								<tr>
									<td colSpan={columns.length + 1}>
										<div className={tableStyles.subRowsTitle}>
											{i18n?.language === 'en' ? row.original?.footerEn : row.original?.footerFr}
										</div>
									</td>
								</tr>
							)
: null}
						</React.Fragment>
					);
				})}
			</tbody>
		);
	};

	return (
		<div className={className} css={{ ...styles.container, ...addStyles }}>
			<div className={cx(stickyHeader ? stickyHeaderStyles : tableStyles.innerContainer)}>
				<table {...getTableProps()} className={cx('bordered', tableStyles.table)}>
					<thead>
						{headerGroups.map((headerGroup: any) => {
							return (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column: any) => {
										return (
											<th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												className={cx(
													tableStyles.thContainer,
													column?.type === 'select' ? tableStyles.selectedTypeTh : tableStyles.otherThType,
												)}
											>
												<div
													className={cx(
														'flexRow alignCenter nowrap headerRow',
														tableStyles.headerThContent,
														column?.props?.headerClassName,
													)}
													style={{
														justifyContent:
															column.type === 'string' ||
															column.type === 'select' ||
															column.type === 'action' ||
															column.type === 'boolean' ||
															column.type === 'booleanStatus'
																? 'flex-start'
																: column.type === 'percent'
																? 'flex-end'
																: 'center',
													}}
												>
													{column.render('Header')}
												</div>
												{column?.unity && (
													<p className={tableStyles.headerText}>
														{column.unity !== 'KGEQCO2/KG' && <>{column.unity}</>}
														{column.unity === 'KGEQCO2/KG' && (
															<>
																KG <sub>EQ</sub>CO<sub>2</sub> /KG
															</>
														)}
													</p>
												)}
											</th>
										);
									})}
								</tr>
							);
						})}
					</thead>

					{renderBody()}
				</table>
			</div>

			{pagination && (
				<Pagination
					isLoading={false}
					gotoPage={gotoPage}
					nextPage={nextPage}
					previousPage={previousPage}
					setPageSize={setPageSize}
					canNextPage={canNextPage}
					canPreviousPage={canPreviousPage}
					pageCount={pageCount}
					pageOptions={pageOptions}
					pageIndex={pageIndex}
					pageSize={pageSize}
					transFunc={transFunc}
					onNextClick={onNextClick}
				/>
			)}
		</div>
	);
};
