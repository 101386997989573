import { ComponentProps, Fragment } from 'react';

import { css, cx } from '@emotion/css';
import {
	type ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	type RowData,
	type SortingState,
	type TableOptions,
	useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';

import { cn, siteColors } from '../../lib/colors';
import Skeleton from '../Skeleton';

import { Pagination } from './Pagination';

type Props<TData extends RowData> = {
	tableId?: string;
	columns: TableOptions<TData>['columns'];
	data: TableOptions<TData>['data'];
	isLoading?: boolean;
	tableProps?: ComponentProps<'table'>;
	manualSorting?: TableOptions<TData>['manualSorting'];
	onSortingChange?: TableOptions<TData>['onSortingChange'];
	sortingState?: SortingState;
	manualPagination?: TableOptions<TData>['manualPagination'];
	pageCount?: TableOptions<TData>['pageCount'];
	rowCount?: TableOptions<TData>['rowCount'];
	// pagination props
	pagination?: boolean;
	gotoPage?: (a?: any) => void;
	nextPage?: (a?: any) => void;
	previousPage?: (a?: any) => void;
	setPageSize?: (a?: any) => void;
	canNextPage?: boolean;
	canPreviousPage?: boolean;
	currentPage?: number;
	// totalPageCount?: number;
	// pageOptions?: any;
	// pageIndex?: any;
	pageSize?: any;
	// transFunc?: any;
	// onNextClick?: () => void;
	expanded?: ExpandedState;
	setExpanded?: TableOptions<TData>['onExpandedChange'];
	thStyle?: Object;
};

const rowHoverStyle = css({
	// hover states
	'& tbody > tr:hover > td': {
		background: cn(siteColors.grey200, '!important'),
	},
});

const skeletonStyles = {
	skeletonTr: css({
		borderTop: 'none!important',
		padding: '0!important',
		height: '38px !important',
		// padding: '8px 0 0 0 !important',
	}),

	skeletonTd: css({
		borderBottom: 'none!important',
		padding: '0!important',
		height: '38px !important',
		paddingBottom: '12px!important',
		// padding: '8px 0 0 0 !important',
	}),

	skeleton: css({
		flexGrow: 1,
		height: '38px !important',
	}),
};

const Table = <TData extends RowData = RowData>({
	tableId,
	columns,
	data,
	isLoading,
	tableProps,
	manualSorting,
	onSortingChange,
	sortingState,
	manualPagination,
	pageCount,
	rowCount,
	// pagination props
	pagination,
	gotoPage,
	nextPage,
	previousPage,
	setPageSize,
	canNextPage,
	canPreviousPage,
	currentPage,
	// totalPageCount,
	// pageOptions,
	// pageIndex,
	pageSize,
	expanded,
	setExpanded,
	thStyle,
}:
	Props<TData>) => {
	// isLoading = true;
	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(), //client-side sorting
		getExpandedRowModel: getExpandedRowModel(),
		manualSorting,
		onSortingChange,
		state: {
			sorting: sortingState,
			expanded,
		},
		onExpandedChange: setExpanded,
		getSubRows: (row) => {return _.get(row, 'subRows') as never; },
		manualPagination,
		pageCount,
		rowCount,
	});

	const voidFunction = (() => { return null; });

	// return <div>Table</div>;
	return (
		<div className={cx('flex flexColumn flexEnd width100')}>
			<table
				id={tableId}
				{...tableProps}
				className={cx('width100 flexStartSelf', !isLoading ? rowHoverStyle : '', tableProps?.className)}
			>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => {
						return (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<th key={header.id} style={thStyle ?? {}}>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									);
								})}
							</tr>
						);
					})}
				</thead>
				{isLoading
				? (
					<tbody>
						{Array.from({ length: 10 }).map((_, index) => {
							return (
								<tr key={index} className={cx('', skeletonStyles.skeletonTr)}>
									<td colSpan={columns.length + 1} className={skeletonStyles.skeletonTd}>
										<Skeleton className={cx('width100', skeletonStyles.skeleton)} />
									</td>
								</tr>
							);
						})}
					</tbody>
				)
				: (
					<tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<Fragment key={row.id}>
									<tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
										})}
									</tr>
								</Fragment>
							);
						})}
					</tbody>
				)}
			</table>
			{pagination && (
				<Pagination
					isLoading={isLoading || false}
					gotoPage={gotoPage || voidFunction}
					nextPage={nextPage || voidFunction}
					previousPage={previousPage || voidFunction}
					setPageSize={setPageSize || voidFunction}
					canNextPage={canNextPage || false}
					canPreviousPage={canPreviousPage || false}
					totalPageCount={pageCount}
					currentPage={currentPage}
					pageSize={pageSize}
				/>
			)}
		</div>
	);
};

export default Table;
