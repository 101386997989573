import React, { ElementRef, ReactNode, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Popover from '../../components/Popover';

import { cn, siteColors } from '../../lib/colors';
import Difference from './Difference';

type Props = {
	text: ReactNode;
	value?: number;
	difference?: number;
	percentValue?: number;
	percentDiff?: number;
	withDifference?: boolean;
	decimal?: number;
	indicator?: string;
	withEquals?: boolean;
	showLte?: boolean;
	showZero?: boolean;
	diffByPercent?: number;
};

const styles = {
	main: css({
		gap: '16px',
		marginBottom: '16px',
	}),
	labelContent: css({
		width: '92px',
		fontSize: 12,
	}),
	label: css({
		fontSize: 12,
	}),
	bar: css({
		flexGrow: 1,
	}),
	boxDiff: css({
		width: 105,
	}),
};

const LineDisplay = ({
	withEquals = true,
	text,
	value = 0,
	difference,
	percentValue,
	percentDiff,
	diffByPercent,
	withDifference = true,
	decimal = 2,
	indicator = 'carbon',
	showLte = false,
	showZero = false,
}: Props) => {
	return (
		<div className={cx('flexRow alignCenter', styles.main)}>
			<div className={cx(styles.labelContent)}>
				<div className={cx('grey900', styles.label)}>{text}</div>
				<div className="grey700 number">
					{/* {formatNumber(percentValue, '', decimal)} */}
					{displayValue(percentValue, undefined, decimal)} %
				</div>
			</div>
			<div className={cx('', styles.bar)}>
				<DisplayBar
					percent={percentValue}
					percentDiff={percentDiff}
					indicator={indicator}
					label={text || ''}
					// value={formatNumber(value, '', decimal)}
					value={displayValue(value, undefined, decimal)}
					differencePercent={difference}
				/>
			</div>
			{withDifference && <Difference value={difference ?? 0} className={styles.boxDiff} withEquals={withEquals} showZero={showZero} showLte={showLte} />}
		</div>
	);
};

export default LineDisplay;

const mainColor = siteColors.primary;
const negativeColor = '#009F36';
const positiveColor = '#C70000';

const barStyles = {
	main: css({
		height: '8px',
		borderRadius: '16px',
		overflow: 'hidden',
		gap: '2px',
	}),
	purple: css({
		background: mainColor,
		width: 'var(--width)',
		borderRadius: '16px 0 0 16px',
	}),
	blue: css({
		background: siteColors.water500,
		width: 'var(--width)',
		borderRadius: '16px 0 0 16px',
	}),
	diffContainer: css({
		borderRadius: '0 16px 16px 0',
		width: 'var(--width-diff)',
		background: 'var(--background)',
	}),
};

type DisplayBarProps = {
	percent?: number;
	percentDiff?: number;
	indicator?: string;
	label: ReactNode;
	value?: string;
	tooltip?: any;
	differencePercent?: number;
};

export const DisplayBar = ({ differencePercent, percent, percentDiff, indicator = 'carbon', label, value, tooltip }: DisplayBarProps) => {
	const [showToolTip, setShowToolTip] = useState(false);
	const valeurTotal = percent || 0;
	const valeur2 = valeurTotal * (differencePercent || 0) / 100;
	const valeur1 = valeurTotal - Math.abs(valeur2);

	return (
		<div css={{ position: 'relative' }}>
			<div
				onMouseEnter={() => {
					return setShowToolTip(true);
				}}
				onMouseLeave={() => {
					return setShowToolTip(false);
				}}
				className={cx('flexRow width100 bgGrey300 XXX-TEST', barStyles.main)}
			>
				<div
					className={cx(indicator === 'carbon' ? barStyles.purple : barStyles.blue, 'height100')}
					style={{
						['--width' as any]: `${Math.abs(valeur1 || 0)}%`

					}}
				/>
				{percent !== percentDiff && percentDiff !== 0 &&

					<div
						className={cx(barStyles.diffContainer, 'height100')}
						style={{
							['--width-diff' as any]: `${Math.abs(valeur2 || 0)}%`,
							['--background' as any]: percentDiff && percentDiff < 0 ? negativeColor : positiveColor,
						}}
					/>
				}
			</div>
			<div
				className="custom-tooltip"
				css={{
					position: 'absolute',
					top: '-110px',
					zIndex: 3000,

					// display: 'inline-flex',
					padding: '24px',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: '16px',
					borderRadius: '8px',
					border: cn('1px solid', siteColors.grey500),
					background: siteColors.common.white,
					boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				}}
				style={{
					display: showToolTip ? 'block' : 'none',
				}}
			>
				{tooltip || (
					<>
						<div css={{ fontWeight: 'bold' }}>{label}</div>
						{/* <br /> */}
						<div>
							{value} kg<sub>eq</sub>CO<sub>2</sub>/kg
						</div>
					</>
				)}
			</div>
		</div>
	);
};
