import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';

import { ReactComponent as ImageIcon } from '@carbonmaps/media/icons/image-icon.svg';

import { siteColors } from '../../../../apps/front/src/lib/colors';

const classes = (theme: Theme) => {
	return {
		container: {
			width: 234,
			height: 42,
			background: theme.palette.grey[400],
			borderRadius: 8,
			position: 'relative',
			input: {
				height: '100%',
				opacity: 0,
				position: 'absolute',
			},
			':hover': {
				background: siteColors.grey400,
			},
		},
		title: {
			gap: 10,
			p: {
				margin: 0,
				fontWeight: 600,
				fontSize: 16,
				textAlign: 'center',
				color: siteColors.text,
			},
		},
	};
};

interface BInputFileProps {
	onChange: (e: any) => void;
	value: any;
	icon?: React.ReactNode;
	label?: string;
	accept?: string;
	loading?: boolean;
}

const BInputFile = ({
	value,
	onChange,
	icon = <ImageIcon />,
	label = 'Télécharger une photo',
	loading = false,
	accept,
}: BInputFileProps) => {
	const theme = useTheme();
	const styles = classes(theme);

	const onChangeFile = (e: any) => {
		onChange(e.target.files[0]);
	};

	return (
		<div className="flexRow alignStart">
			<div className="flexRow alignCenter justifyCenter" css={styles.container as any}>
				<input accept={accept} type="file" onChange={onChangeFile} name="fileLogo" />

				<div className="flexRow alignCenter" css={styles.title as any}>
					{loading ? <CircularProgress color="inherit" size={20} /> : icon}
					<p>{label}</p>
				</div>
			</div>
		</div>
	);
};

export default BInputFile;
