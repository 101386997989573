import useTheme from '@mui/material/styles/useTheme';
import { Database } from 'lucide-react';

import IconContainer from '../../../../components/layout/list/IconContainer';
import ListHeader from '../../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';

type QuestionFormsGeneralHeaderProps = {
	subtitle?: string;
};

const default_title = 'Campagnes de collecte';

const QuestionFormsGeneralHeader = ({ subtitle }: QuestionFormsGeneralHeaderProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<ListHeader
			title={t(default_title)}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Database size={32} color={theme.palette.common.white} />}
					color={siteColors.orange500}
				/>
			}
		/>
	);
};

export default QuestionFormsGeneralHeader;
