import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { AlertCircle, Key } from 'lucide-react';
import { useForm } from 'react-hook-form';
import z from 'zod';

import { ReactComponent as EditIcon } from '@carbonmaps/media/icons/edit.svg';
import { ReactComponent as HourglassIcon } from '@carbonmaps/media/icons/hourglass.svg';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import AlertStatic from '../../../../../apps/front/src/components/Toast/AlertStatic';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';
import BInput from '../BInput';
import DeleteConfirm from '../DeleteConfirm';

const styles = {
	boxContainer: css({
		padding: '8px 0 8px 8px',
		flex: '2 1 auto',
		gap: '4px',
	}),
	boxHourGlass: css({
		padding: '24px',
		width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
		gap: '16px',
	}),
	textPopup: css({
		fontWeight: 600,
		fontSize: '16px !important',
	}),

	contentPopup: css({
		marginTop: '12px',
	}),
	buttonPopover: css({
		'&:hover': { backgroundColor: 'unset !important' },
	}),

	iconHourGlass: css({
		height: '18px',
		width: '18px',
		'& path': {
			fill: siteColors.grey700,
		},
	}),
	errorContent: css({
		bottom: '30px',
		zIndex: 10000,
	}),
	alert: css({
		color: '#C74600 !important',
	}),
};

const classes = (theme: Theme) => {
	return {
		popupHourGlass: css({
			fontSize: '16px',
			fontWeight: 600,
			textTransform: 'none',
			gap: 10,
			color: theme.palette.common.white,
			background: siteColors.primary,
			borderRadius: '8px',
			padding: '8px 16px',
			':hover': {
				background: siteColors.primaryHover,
			},
			'&.Mui-disabled': {
				color: theme.palette.common.white,
				background: siteColors.primary,
			},
		}),
	};
};

const sendEmailUpdateEmailSchema = z.object({
	email: z.string({ required_error: 'Entrez votre adresse e-mail' }).email('Entrez un adresse e-mail valide'),
	currentEmail: z.string(),
});

type SendUpdateEmailFormInput = z.infer<typeof sendEmailUpdateEmailSchema>;

const EditableEmailInput = ({ value: initialValue, row, callback }: any) => {
	const theme = useTheme();
	const [, setSelected] = useState(false);
	const [isLoading] = useState(false);
	const [value, setValue] = useState(initialValue);
	const [toggle, setToggle] = useState(true);

	const stylesTheme = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const currentEmail = useMemo(() => {
		return row.original.email;
	}, [row.original.email]);

	const handleClick = () => {
		setToggle(!toggle);
	};

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		const a = row.original.newEmail ? row.original.newEmail : row.original.email;
		setValue(a);
		// setCurrentEmail(row.original.email);
	}, [row.original]);

	const emailLanguageForContributor = _.get(row, 'original.emailLanguage', 'fr');

	const onSubmit = async (values: any) => {
		setValue(values.email);
		await callback({
			currentEmail: values.currentEmail,
			email: values.email,
			type: 'changeEmail',
			emailLanguageForContributor,
		});
		setToggle(!toggle);
	};

	const resendEmailNewAccount = async (values: any) => {
		await callback({
			currentEmail: row.original.email,
			email: row.original.newEmail,
			type: 'sendEmailNewAccount',
			roleLabel: row.original?.role?.label,
			emailLanguageForContributor,
		});
	};

	const resendEmailNewEmail = async () => {
		await callback({
			currentEmail,
			email: row.original.newEmail,
			type: 'sendEmailNewEmail',
			emailLanguageForContributor,
		});
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<SendUpdateEmailFormInput>({
		defaultValues: {
			email: '',
			currentEmail,
		},
		mode: 'onChange',
		resolver: zodResolver(sendEmailUpdateEmailSchema) as any,
	});

	const handleBlur = () => {
		setSelected(false);
	};

	const { t } = useTranslation();

	const renderButton = (
		<BButton
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onClick={handleSubmit(async () => {})}
			addStyles={{
				minWidth: '44px',
				width: '44px',
				height: '44px',
			}}
			label={isLoading ? <CircularProgress size={20} className="whiteColor" /> : <CheckIcon sx={{ color: 'white' }} />}
			variant="primary"
			variantDisabled="secondary"
		/>
	);

	return (
		<form>
			{toggle
? (
				<div className={cx('flexRow alignCenter nowrap spaceBetween', styles.boxContainer)}>
					{row.original.email}

					<div className="flexRow nowrap">
						{row.original.isActive === true &&
							row.original.newEmail &&
							row.original.email &&
							row.original.newEmail !== '' &&
							row.original.newEmail !== row.original.email && (
								<>
									<BPopover
										anchorOriginVertical={'bottom'}
										anchorOriginHorizontal={'right'}
										transformOriginVertical={'top'}
										transformOriginHorizontal={'right'}
										content={(_onClose) => {
											return (
												<div className={styles.boxHourGlass}>
													<Typography className={cx('alignLeft', styles.textPopup)}>Changement en attente</Typography>

													<Typography className="alignLeft">Lien de validation envoyé à {value}</Typography>

													<div className={styles.textPopup}>
														<Button onClick={resendEmailNewEmail} className={stylesTheme.popupHourGlass}>
															{isLoading && (
																<CircularProgress size={20} style={{ color: theme.palette.common.white }} />
															)}
															Renvoyer l'email
														</Button>
													</div>
												</div>
											);
										}}
									>
										<Button className={cx('width100', styles.buttonPopover)}>
											<HourglassIcon className={styles.iconHourGlass} />
										</Button>
									</BPopover>
								</>
							)}
						{row.original.isActive === false
? (
							<BPopover
								anchorOriginVertical={'bottom'}
								anchorOriginHorizontal={'right'}
								transformOriginVertical={'top'}
								transformOriginHorizontal={'right'}
								content={(_onClose) => {
									return (
										<div className={styles.boxHourGlass}>
											<Typography className={cx('alignLeft', styles.textPopup)}>
												Création du compte en attente
											</Typography>

											<Typography className="alignLeft">Lien de validation envoyé à {row.original.email}</Typography>

											<div className={styles.contentPopup}>
												<Button onClick={resendEmailNewAccount} className={stylesTheme.popupHourGlass}>
													{isLoading && <CircularProgress size={20} style={{ color: theme.palette.common.white }} />}
													Renvoyer l'email
												</Button>
											</div>
										</div>
									);
								}}
							>
								<Button className={cx('width100', styles.buttonPopover)}>
									<HourglassIcon className={styles.iconHourGlass} />
								</Button>
							</BPopover>
						)
: (
							<BPopover
								anchorOriginVertical={'bottom'}
								anchorOriginHorizontal={'right'}
								transformOriginVertical={'top'}
								transformOriginHorizontal={'right'}
								content={(onClose) => {
									return (
										<div className={styles.boxHourGlass}>
											<Typography className={cx('alignLeft', styles.textPopup)}>
												Réinitialisation de mot de passe
											</Typography>

											<Typography className="alignLeft">
												Envoyer un mail de réinitialisation de mot de passe à {row.original.email}
											</Typography>

											<div className={styles.contentPopup}>
												<Button
													onClick={async () => {
														try {
															await Parse.User.requestPasswordReset(row.original.email);
														} catch (e) {
															console.error(e);
														}

														onClose();
													}}
													className={stylesTheme.popupHourGlass}
												>
													{isLoading && <CircularProgress size={20} style={{ color: theme.palette.common.white }} />}
													Renvoyer l'email
												</Button>
											</div>
										</div>
									);
								}}
							>
								<Button className={cx('width100', styles.buttonPopover)}>
									<Key className={styles.iconHourGlass} color={siteColors.grey700} />
								</Button>
							</BPopover>
						)}

						<Button disableRipple className={styles.buttonPopover} onClick={handleClick}>
							<EditIcon
								css={{
									height: '18px',
									width: '18px',
									'& path': {
										fill: theme.palette.grey[700],
									},
								}}
							/>
						</Button>
					</div>
				</div>
			)
: (
				<ClickAwayListener
					onClickAway={() => {
						setToggle(!toggle);
					}}
				>
					<div className={cx('flexRow alignCenter spaceBetween', styles.boxContainer)}>
						<BInput
							type="text"
							required
							id="email"
							disabled={isLoading}
							placeholder={value}
							label=""
							{...register('email')}
							name="email"
							error={errors.email ? true : false}
							onBlur={handleBlur}
							addStyles={{
								minWidth: '344px',
							}}
						/>

						{isValid
? (
							<DeleteConfirm
								title={t('ask-change-mail')}
								onClick={(close?: VoidFunction): void => {
									handleSubmit(onSubmit)();
								}}
								subMessageConfirmDelete={t('explain-change-email')}
								confirmText={t('send')}
							>
								<div>{renderButton}</div>
							</DeleteConfirm>
						)
: (
							renderButton
						)}
					</div>
				</ClickAwayListener>
			)}

			{errors.email && errors?.email?.message && (
				<div className={cx('fixed', styles.errorContent)}>
					<AlertStatic
						text={errors.email ? errors?.email?.message : ''}
						type="error"
						icon={<AlertCircle className={styles.alert} />}
					/>
				</div>
			)}
		</form>
	);
};

export default EditableEmailInput;
