import { css, cx } from '@emotion/css';
import { IconButton } from '@mui/material';
import _ from 'lodash';
import { Edit2, Plus, Trash2 } from 'lucide-react';

import { isWhitespace } from '@carbonmaps/shared/utils/utils';

import SupplierContactRowForm from '../../../../../../components/question/SupplierContactRowForm';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useQuestionFormStore } from '../../../../questionForms.hooks';

export type SupplierContactSubRowData = {
	id: string;
	supplierName: string;
	supplierUid: string;

	contactFirstName?: string;
	contactLastName?: string;
	contactEmail?: string;
	contactLanguage?: string;

	isEdited?: boolean;
};

export type SupplierContactRowData = {
	id: string;
	supplierUid: string;
	supplierName: string;
	subRows: SupplierContactSubRowData[];
};

export type CustomMessageRowData = {
	lang: string;
	message?: string;
};

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: '1px solid #E2DDEB',
		},
	}),

	hiddenVisibility: css({
		visibility: 'hidden',
	}),
};

export type UpdateContactData = (options: {
	supplierId: string;
	contactId: string;
	values: Omit<SupplierContactSubRowData, 'supplierName' | 'supplierUid' | 'id'>;
}) => void;

export const getContactStringValue = (row: SupplierContactSubRowData) => {
	return `${row.contactFirstName || ''} ${row.contactLastName || ''} ${row.contactEmail || ''}`;
};

const QuestionFormSuppliersTable = () => {
	const {
		addContactForSupplier,
		updateContactData,
		removeSupplierContactById,
		supplierContacts: data,
		removeSupplierById,
	} = useQuestionFormStore();

	const { t } = useTranslation();

	return data.length > 0
? (
		<div className={cx('width100')}>
			{data.map((row) => {
				const emptyContacts = row.subRows.filter((subRow) => {
					return isWhitespace(getContactStringValue(subRow));
				});

				return (
					<div key={row.id} css={{ marginBottom: 32 }}>
						<div
							className={cx(
								'flexRow justifyBetween alignCenter',
								styles.supplierLabelWrapper,
								_.isEmpty(row.subRows) ? styles.supplierLabelWrapperBorderBottom : '',
							)}
						>
							<div className={cx(styles.supplierLabel)}>
								<div>
									<div>{row.supplierName}</div>
									{_.isEmpty(row.subRows) || emptyContacts.length === row.subRows.length
? (
										<span
											css={(theme) => {
												return {
													color: theme.palette.error.light,
													fontStyle: 'italic',
													fontSize: 10,
												};
											}}
										>
											{t('Ajoutez un contact à ce fournisseur')}
										</span>
									)
: null}
								</div>
							</div>

							<div>
								<div className={cx('flexRow alignCenter justifyCenter width100')}>
									<IconButton
										onClick={() => {
											addContactForSupplier({ supplierId: row.id, isEdited: true });
										}}
									>
										<Plus size={20} color="#9587AE" />
									</IconButton>
									<IconButton
										onClick={() => {
											removeSupplierById({
												supplierId: row.id,
											});
										}}
									>
										<Trash2 size={20} color="#9587AE" />
									</IconButton>
								</div>
							</div>
						</div>

						{row.subRows.map((subRow) => {
							return (
								<div
									key={subRow.id + _.toString(subRow.isEdited)}
									className={cx('flexRow')}
									css={{
										paddingTop: 14,
										paddingBottom: 14,
										paddingRight: 6,
										borderTop: cn('1px solid', siteColors.grey500),
									}}
								>
									<div css={{ flexGrow: 1 }}>
										<SupplierContactRowForm
											data={subRow}
											onSubmit={(values) => {
												updateContactData({
													supplierId: row.id,
													contactId: subRow.id,
													values: {
														...values,
														isEdited: false,
													},
												});
											}}
										/>
									</div>

									<div className={cx('flexRow')}>
										<div
											className={cx(
												'',
												subRow.isEdited || isWhitespace(getContactStringValue(subRow))
													? tableStyles.hiddenVisibility
													: '',
											)}
										>
											<IconButton
												onClick={() => {
													console.log(row.id, subRow.id, data);
													updateContactData({
														supplierId: row.id,
														contactId: subRow.id,
														values: {
															isEdited: true,
														},
													});
												}}
											>
												<Edit2 size={20} color="#9587AE" />
											</IconButton>
										</div>
										<IconButton
											onClick={() => {
												removeSupplierContactById({
													supplierId: row.id,
													contactId: subRow.id,
												});
											}}
										>
											<Trash2 size={20} color="#9587AE" />
										</IconButton>
									</div>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	)
: null;

	// return data.length > 0 ? (
	// 	<Table<SupplierContactRowData>
	// 		columns={columns}
	// 		data={data}
	// 		tableProps={{
	// 			className: tableStyles.main,
	// 		}}
	// 		expanded
	// 		setExpanded={() => {}}
	// 	/>
	// ) : null;
};

const styles = {
	supplierLabelWrapper: css({
		paddingBottom: 12,
		paddingTop: 9,
		paddingRight: 6,
	}),
	supplierLabelWrapperBorderBottom: css({
		borderBottom: cn('1px solid', siteColors.grey500),
	}),
	supplierLabel: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 500,
		// lineHeight: '150%',
	}),
};

export default QuestionFormSuppliersTable;
