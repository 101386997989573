import { arrayMove } from '@dnd-kit/sortable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { create } from 'zustand';

import { classNames } from '@carbonmaps/shared/utils/constants';

export const useGetSectorSheetById = ({ sheetId }: { sheetId: string }) => {
	const key = ['getSectorSheetById', { sheetId }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (ctx) => {
			try {
				const p = ctx.queryKey[1];

				// const sheet = await new Parse.Query(classNames.SECTOR_SHEET).equalTo('objectId', p.sheetId || '').first();
				const sheet = await Parse.Cloud.run('getSectorSheetById', { sheetId: p.sheetId });

				if (sheet.notFound) {
					return null;
				}

				return sheet;
			} catch (error) {
				console.log('----- getSectorSheetById error', error);
				return Promise.reject(error);
			}
		},
	});

	return {
		key,
		result,
	};
};

export const useMoveSectorSheetOrder = () => {
	const key = ['swapSectorSheetOrder'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { sheetIdFrom: string; sheetIdTo: string; oldIndex: number; newIndex: number; categoryId: string; }) => {
			try {
				const category = new Parse.Object(classNames.SECTOR_SHEET_CATEGORY);
				category.set('objectId', p.categoryId);
				await category.fetch();

				const sheets = category.get('sheets') || [];

				// _.move(sheets, p.oldIndex, p.newIndex);
				const newSheets = arrayMove(sheets, p.oldIndex, p.newIndex);
				category.set('sheets', newSheets);

				await category.save();
				// const sheetsBefore = sheets.slice(0, p.oldIndex);
				// const sheetsMiddle = sheets.slice(p.oldIndex + 1, p.newIndex + 1);
				// const sheetsAfter = sheets.slice(p.newIndex + 1);

				// const [sheetFrom, sheetTo] =
				// 	await Promise.all([new Parse.Query(classNames.SECTOR_SHEET).select([]).get(p.sheetIdFrom),
				// 	new Parse.Query(classNames.SECTOR_SHEET).select([]).get(p.sheetIdTo)]);

				// sheetFrom.set('order', p.newIndex);
				// sheetTo.set('order', p.oldIndex);

				// await Promise.all([sheetFrom.save(), sheetTo.save()]);

			} catch (error) {
				console.log('----- swapSectorSheetOrder error', error);
				return Promise.reject(error);
			}
		},
		onSuccess(_data: any, _variables: any, _context: any) {
			setGlobalLoading(false);
			queryClient.invalidateQueries(['findSectorSheetGroups']);
		},
		onError(_error: any, _variables: any, _context: any) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return {
		result,
		key,
	};
};

export const useSaveSectorSheet = ({
	onSuccess,
}: { onSuccess: any }) => {
	const key = ['saveSectorSheet'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { categoryId: string; sheetId?: string; title_en: string, title_fr: string }) => {
			try {
				const category = new Parse.Object(classNames.SECTOR_SHEET_CATEGORY);
				category.set('objectId', p.categoryId);

				const fetchedCategory = category.fetch();

				const sheet = new Parse.Object(classNames.SECTOR_SHEET);

				if (p.sheetId) {
					sheet.set('objectId', p.sheetId);
				}

				sheet.set('category', category);
				sheet.set('translation', {
					en: {
						title: p.title_en,
						content: '',
					},
					fr: {
						title: p.title_fr,
						content: '',
					},
				});
				// sheet.set('translation.en.title', p.title);
				// sheet.set('translation.fr.title', p.title);

				const saved = await sheet.save();

				const _fetchedCategory = await fetchedCategory;
				const sheets = _fetchedCategory.get('sheets');

				_fetchedCategory.set('sheets', [saved, ...(sheets || [])]);

				await _fetchedCategory.save();

				return saved.toJSON();
			} catch (error) {
				console.log('----- saveSectorSheet error', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			onSuccess?.(data, variables, context);
		},
		onError(_error, _variables, _context) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return {
		key, result,
	};
};

export const useSaveSheetGroup = ({ onSuccess }: { onSuccess: any }) => {
	const key = ['saveSheetGroup'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { categoryId?: string; name_en: string; name_fr: string }) => {
			try {
				return Parse.Cloud.run('saveSectorSheetCategory', p);
			} catch (error) {
				console.log('------ saveSheetGroup error', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			onSuccess?.(data, variables, context);
		},
		onError(_error, _variables, _context) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return {
		key, result,
	};
};

export const useGlobalLoading = create((set: any) => {
	return {
		isGlobalLoading: false,
		setGlobalLoading: (newValue: boolean) => {
			return set((state: any) => {
				return {
					...state,
					isGlobalLoading: newValue,
				};
			});
		},
	};
});

export const useMoveGroupOrder = () => {
	const { setGlobalLoading } = useGlobalLoading();
	const key = ['moveGroupOrder'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { oldIndex: number; newIndex: number, categoryId: string }) => {
			try {
				//
				await Parse.Cloud.run('moveSectorSheetCategoryOrder', p);
			} catch (error) {
				console.log('----- moveGroupOrder error', error);
				return Promise.reject(error);
			}
		},
		onSettled(data, error, variables, context) {
			setGlobalLoading(false);
		},
	});

	return { key, result };
};

export const useUpdateGroupPublishStatus = () => {
	const key = ['updateGroupPublishStatus'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { categoryId: string; published: boolean }) => {
			try {
				const category = new Parse.Object(classNames.SECTOR_SHEET_CATEGORY);
				category.set('objectId', p.categoryId);

				category.set('published', p.published);

				await category.save();
			} catch (error) {
				console.log('----- updateGroupPublishStatus error', error);
				return Promise.reject(error);
			}
		},
		onSettled(data, error, variables, context) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return { key, result };
};


export const useUpdateSheetPublishStatus = () => {
	const key = ['updateSheetPublishStatus'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { sheetId: string; published: boolean }) => {
			try {
				const category = new Parse.Object(classNames.SECTOR_SHEET);
				category.set('objectId', p.sheetId);

				category.set('published', p.published);

				await category.save();
			} catch (error) {
				console.log('----- updateSheetPublishStatus error', error);
				return Promise.reject(error);
			}
		},
		onSettled(data, error, variables, context) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return { key, result };
};
