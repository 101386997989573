import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Box as BoxIcon, Contact2 } from 'lucide-react';
import { Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useReportClientBasicInfo, useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';

import { MOD_QUOTE } from '@carbonmaps/shared/utils/module';
import { getPluginMap } from '@carbonmaps/ui/utils/utils';
import SEO from '../../components/SEO';
import ScrollTop from '../../components/ScrollTop';
import SheetPageLayout, { ITab } from '../../components/layout/page/SheetPageLayout';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import Page404 from '../404/Page404';
import DevisList from './quote/DevisList';
import ReportClientSales from './sales/ReportClientSales';
import SynthesisReportClient from './synthesis/SynthesisReportClient';


const ReportClientSheet = () => {
	const { setBreadcrumbs, indicator } = useApp();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const plugins = useMemo(() => {
		return authData?.session.company?.plugins || [];
	}, [authData?.session.company]);

	const { t } = useTranslation();

	const analytics = useJuneTrack();

	const { data: clientInfo, isLoading } = useReportClientBasicInfo({
		objectId: params.slugClient!,
	});

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Report Client Switch Tab', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (!clientInfo) {
			setBreadcrumbs([{ text: t('Valoriser') }]);
			return;
		}

		setBreadcrumbs([
			{ text: t('Valoriser'), link: `${FRONT_PATH_NAMES.sales}?${searchParams}` },
			{ text: _.capitalize(clientInfo?.name) },
		]);
	}, [clientInfo, setBreadcrumbs, searchParams, isLoading, t]);

	const tabs: Record<string, ITab> = useMemo(() => {
		const isProspect = queryParams?.['isProspect']?.[0] ?? false;

		const baseTabs: Record<string, ITab> = {};

		//Hide the “Sales” tab for Prospects
		if (!isProspect) {
			baseTabs.synthesis = {
				title: t('synthesis-tab'),
				element: <SynthesisReportClient />,
				tabName: 'synthesis',
			};
			baseTabs.sales = {
				title: t('sales-benchmark-tab'),
				element: <ReportClientSales />, //todo rename to sales benchmark
				tabName: 'sales',
			};
		}


		baseTabs.quote = {
			title: t('sales-quote-tab'),
			element: <DevisList />,
			tabName: 'Quote',
			unauthorized: !getPluginMap(plugins).get(MOD_QUOTE)?.active,
		};

		return baseTabs;
	}, [t, queryParams, plugins]);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		trackEvent({
			ProductTabTarget: (tabs[value] as any)?.tabName,
		});

		navigate(`${FRONT_PATH_NAMES.sales}/${params.slugClient}/${value}?${searchParams}`);
	};

	if (!params.tab) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.sales}/${params.slugClient}/${Object.keys(tabs)[0]}?${searchParams}`}
			/>
		);
	}

	if ((clientInfo as never) === 'PRODUCT_NOT_FOUND') {
		return <Page404 message={<h2>Produit ({params.slugClient}) introuvable</h2>} />;
		// return <ProductNotFound />;
	}

	return (
		<>
			<SEO
				title={clientInfo?.name ? `${_.capitalize(clientInfo?.name)} - CarbonMaps` : 'CarbonMaps'}
			/>
			<ScrollTop key={params.tab} behavior="auto" />
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab}
				headerProps={{
					variant: 'fixed',
					icon: <Contact2 size={40} color={siteColors.grey700} />,
					title: clientInfo?.name || '',
					overTitle: t('Client') || '',
					sx: { background: siteColors.water10 },
				}}
				onTabChange={handleTabChange}
				tabs={tabs}
			/>
		</>
	);
};

export default ReportClientSheet;
