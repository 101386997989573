import { css, cx } from '@emotion/css';


import { ReactComponent as Avatar1 } from '@carbonmaps/media/icons/avatarChat1.svg';
import { ReactComponent as Avatar2 } from '@carbonmaps/media/icons/avatarChat2.svg';
import { ReactComponent as Avatar3 } from '@carbonmaps/media/icons/avatarChat3.svg';
import zIndex from '@mui/material/styles/zIndex';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from '../../hooks/useTranslation';
import Typography from '../Typography';

const styles = {
	container: css({
		'& > *': {
		marginLeft:'-10px',
		},
		marginBottom: 8,
	}),
};

const BubbleChatHeader = () => {
	const { t } = useTranslation();

	return (
		<div className="flexCenter width100 gap8">
		<Tooltip	title="Justine, Lobna & Julien 👋" placement="top" PopperProps={{style:{zIndex:100000}}}>
			<div className={cx('flexRow', styles.container)}>
				<Avatar1 />
				<Avatar2 />
				<Avatar3 />
			</div>
			</Tooltip>
			<Typography variant="h3">{t("Avez-vous besoin d'aide?")}</Typography>
			<div className="textAlignCenter">
				<Typography>{t('Please send us a message.')}</Typography>{' '}
				<Typography>{t('We usually answer in an open day')}</Typography>
			</div>
		</div>
	);
};

export default BubbleChatHeader;
