import {
	FRONT_PATH_NAMES,
} from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useReportClientPackaging } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '../../../components/Typography';
import Card from '../../../components/v3/Card';
import CategoryTable from '../../../components/v3/CategoryTable';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import CarbonFootprintTitle from '../../dashboard/v3/carbon_footprint/CarbonFootprintTitle';

const styles = {
	content: css({
		minHeight: 164,
	}),

	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '164px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};

const PackagingImpactClient = () => {
	// ---- hooks ---- //
	const { indicator } = useApp();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const { t } = useTranslation();
	const params = useParams();
	// ------------------------------------ //
	// --------- state -------------------- //
	// ------------------------------------ //
	const [currentSegment, setCurrentSegment] = useState('perSupplier');

	// ------------------------------------ //
	// ---- fetch data  ------------------- //
	// ------------------------------------ //
		const { data: dataPackaging, isLoading } = useReportClientPackaging({
		objectId: params.slugClient!,
		//viewMode: indicator,
	});


	// ---- repartition graph data ---- //
	const items = useMemo(() => {
		if (!dataPackaging) return [];

		const data = dataPackaging?.map((obj: any) => {
			return {
				...obj,
				_id: obj?.name,
				label: obj?.name,
				value: obj[`${indicator}ImpactPercent`],
				volume: obj[`${indicator}Impact`],
				link: `${FRONT_PATH_NAMES.packagings.details}/${obj?.objectId}/synthesis`,

			};
		});

		return data;
	}, [dataPackaging, currentSegment, indicator, authData?.session.company]);


	return (
		<Card
			title={<CarbonFootprintTitle title={t('client-packaging-impact-title')} subTitle={t('Par impact total')}/>}
			loading={isLoading}
		>
			<div className={cx(styles.content, 'width100')}>
				{items?.length ? <CategoryTable data={items || []} /> : null}
				{!isLoading && !items?.length ? (
					<Typography className={styles.emptyText}>{t('dashboard-empty-packaging')}</Typography>
				) : null}
			</div>
		</Card>
	);
};

export default PackagingImpactClient;
