import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Carrot, PieChart } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import IngredientModel from '../../../models/Ingredient.model';
import ProductModel from '../../../models/Product.model';

import DonutProductChart from './DonutProductChart';

const ProductDonutBlock = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();
	const { indicator } = useApp();

	const { t } = useTranslation();

	const handleViewDetailsClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		navigate(`${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`);
	};

	const { data: dataModelization, isLoading } = useModelizationProduct({
		productId: params.productId!,
	});

	const productObject = new ProductModel(_.isString(dataModelization) ? {} : dataModelization);
	const total = productObject?.getIntensity(indicator === 'carbon' ? 'gesTotal' : 'waterUseTotal');

	const isRecetteType = ['n1_semantic', 'n1_typical', 'typical_ingredients'].includes(
		_.toLower(dataModelization?.typeModelizationCompoIngredient),
	);

	const gesIngredients = isRecetteType
		? productObject.getIntensity('gesAgriculture')
		: productObject.getGesIngredientV2();

	const waterUseIngredients = isRecetteType
		? productObject.getIntensity('waterUseAgriculture')
		: productObject.getWaterIngredientV2();

	const data = dataModelization?.ingredients?.map((ingredient: any) => {
		const ingredientObject = new IngredientModel(ingredient);

		const gesIngredient =
			indicator === 'carbon'
				? ingredientObject.getCarboneWeightedIntensity()
				: ingredientObject.getWaterWeightedIntensity();

		return {
			value: (gesIngredient * 100) / total,
			name: ingredient?.ingredient?.get('label'),
			isBlueCheck: ingredientObject.isN2ModelizationType(),
		};
	});

	return (
		<>
			<BlockTitle icon={<Carrot size={20} color={theme.palette.grey[700]} />}>
				{/* {t('breakdown_by_ingredient_graph_title', { ingredient: toLowerCase(t('ingredient')) })} */}
				{t('ingredients')}
			</BlockTitle>
			<BlockCard>
				<Box display="flex" justifyContent="flex-end">
					<BButton
						variant="secondary"
						label={t('see_more_recipe_details', { recipe: toLowerCase(t('recipe')) })}
						onClick={handleViewDetailsClick}
					/>
				</Box>
				{!isLoading && (
					<DonutProductChart
						key={indicator}
						data={(data || []).filter((item: any) => {
							return item.value > 0.08;
						})}
						data2={{
							value: indicator === 'carbon' ? gesIngredients : waterUseIngredients,
							name: 'KG',
							// noCenterValue: isRecetteType,
						}}
						indicator={indicator}
					/>
				)}
			</BlockCard>
		</>
	);
};

export default ProductDonutBlock;
