import { css, cx } from '@emotion/css';
import { emphasize } from '@mui/material/styles';
import { ArrowDownRight, ArrowUpRight, BarChart2, Cloud, Edit2, Equal, FileEdit } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { siteColors } from '../../lib/colors';

const negativeColor = '#6C8C40';
export const positiveColor = '#C70000';

const styles = {
	box: css({
		border: '1px solid #00000008',
		borderRadius: '4px!important',
		padding: '0 4px!important',
		fontWeight: '500!important',
	}),
	span: css({
		display: 'inline-block',
		marginRight: '3px',
		fontWeight: '500!important',
	}),
	negative: css({
		color: negativeColor,
		background: emphasize(negativeColor, 0.9),
	}),
	positive: css({
		color: positiveColor,
		background: emphasize(positiveColor, 0.9),
	}),
	equals: css({
		background: 'none',
		color: siteColors.grey700,
	}),
	puces: css({
		marginTop: '-3px',
	}),
};

type DifferenceProps = {
	value?: number;
	decimal?: number;
	diffMainClass?: string;
	className?: string;
	withEquals?: boolean;
	showZero?: boolean; // for affecting 0 value
	withPercent?: boolean;
	// showLte?: boolean;
};

const Difference = ({
	value = 0,
	decimal = 2,
	diffMainClass = '',
	className,
	withEquals = false,
	showZero = false,
	withPercent = true,
}: DifferenceProps) => {
	const isNegative = value <= 0;
	const isEqual = formatNumber(Math.abs(value), '', decimal) === '0.00' || value === 0;
	const isMathAbsLte = Math.abs(value) < 0.01;

	if (isEqual && !showZero) {
		return <div
			className={cx(
				diffMainClass,
				styles.equals,
				className,
			)}
		>
			<Equal style={{ visibility: withEquals ? 'visible' : 'hidden' }} size={22} />
		</div>
	}

	return (
		<div
			className={cx(
				styles.box,
				diffMainClass,
				isEqual ? styles.equals : isNegative ? styles.negative : styles.positive,
				'flexRow alignCenter justifyCenter gap4 nowrap',
				className,
			)}
		>
			{isEqual ? (
				showZero ? (
					0
				) : (
					<>
						<Equal style={{ visibility: withEquals ? 'visible' : 'hidden' }} size={22} />
					</>
				)
			) : (
				<>
					{!isMathAbsLte && (
						<div className={cx(styles.puces)}>
							{value < 0 ? (
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
									<path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="#6C8C40" />
								</svg>
							) : (
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
									<path d="M5 0L0.669873 4.5H9.33013L5 0Z" fill={positiveColor} />
								</svg>
							)}
						</div>
					)}
					<div>
						<span className={cx(styles.span, 'number')}>
							{isMathAbsLte ? '< 0,01' : `${formatNumber(Math.abs(value), '', decimal)}`}
							{withPercent ? '%' : null}
						</span>
					</div>
				</>
			)}
		</div>
	);
};

export default Difference;
