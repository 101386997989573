import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { positiveColor } from './Difference';

type Props = {
	value: any;
	withPercent?: boolean;
	color?: string;
	precision?: number;
	withCursor?: boolean;
};
const styles = {
	content: css({
		color: 'var(--color)',
		fontSize: 24,
		fontWeight: 400,
		lineHeight: '82.4%', /* 19.776px */
		letterSpacing: '-0.48px',
		height: 24,
	}),
};

const Percent = ({ value, withPercent = true, color = siteColors.green500, precision = 2, withCursor = false }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const _value = formatNumber(value, undefined, precision);

	if (_value === '0') {
		color = siteColors.grey9;
	}

	return (
		<div className={cx('flexRow nowrap gap2 number', styles.content)} style={{ ['--color' as any]: color }}>
			{value === 100 ? 100 : _value} {withPercent ? '%' : ''}
		</div>
	);
};

export default Percent;
