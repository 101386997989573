import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import SectionRepartition from '../../../../general/tabs/rse/blocks/SectionRepartition';
import SupplierRepartition from '../../../../general/tabs/rse/blocks/SupplierRepartition';
import { useRseQFormTabQueryParam } from '../../../../general/tabs/rse/hooks/rse.hooks';
import { useFindSupplierQuestionFormsTabs, useGetInfoNoteData } from '../hooks/rse.hooks';

import InfoNote from './InfoNote';
import ReOpenSurvey from './ReOpenSurvey';
import SupplierAnswers from './SupplierAnswers';

const styles = {
	gridContainer: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
		marginBottom: 20,
	}),
};

const ContentRseDetails = ({ isContributor }: { isContributor?: boolean }) => {
	const [questionFormId] = useRseQFormTabQueryParam();
	const params = useParams();

	const { data } = useGetInfoNoteData({ questionFormId: _.toString(questionFormId) });

	const unAnswered = _.get(data, 'unAnswered');

	const { isLoading: isLoadingTabsData } = useFindSupplierQuestionFormsTabs({
		supplierId: _.toString(_.get(params, 'supplierId')),
	});

	return (
		<div className={cx('width100')}>
			<InfoNote />
			{_.isEqual(unAnswered, true)
		? null
		: (<>
				<div className={cx(styles.gridContainer, 'width100')}>
				<SectionRepartition fullWidth />
			</div>
			<div className={cx(styles.gridContainer, 'width100')}>
				<SupplierRepartition isLoading={isLoadingTabsData} fullWidth highlightNote={_.get(data, 'note')} />
			</div>
			<div>
				<SupplierAnswers />
				{!isContributor ? <ReOpenSurvey /> : null}
			</div>
		</>
			)}
		</div>
	);
};

export default ContentRseDetails;
