import { css, cx } from '@emotion/css';

import { useGetClientAuthQuery } from '../../../../../../lib/react-query/features/auth/auth.hooks';
import { useFindQuestionForm } from '../../../../../superAdmin/questionForms/hooks';
import QuestionFormItem from '../components/QuestionFormItem';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '23px',
	}),
};

const QuestionFormsList = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { data } = useFindQuestionForm({
		fromBo: false,
		companyCode: authData?.session.company?.code,
	});

	const qForms = data || [];

	return (
		<div className={cx('', styles.container)}>
			{qForms.map((qForm: any, index: number) => {
				return <QuestionFormItem key={index} questionFormObject={qForm} />;
			})}
		</div>
	);
};

export default QuestionFormsList;
