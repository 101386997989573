import { useState } from 'react';

import { css } from '@emotion/css';
import { use } from 'echarts';
import { Pencil } from 'lucide-react';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '../../Typography';
import Unit from '../../units/Unit';
import DashboardGraphTitle from '../v2/DashboardGraphTitle';

import { siteColors } from '../../../lib/colors';
import TemporalityModal from './modal/TemporalityModal';

const styles = {
	button: css({
		padding: '0px !important',
		height: 'auto !important',
		minWidth: '20px !important',
		background: '#fff !important',
	}),
};

const TemporalityTitle = () => {
	const [open, setOpen] = useState(false);

	const { t } = useTranslation();

	const { indicator } = useApp();
	return (
		<>
			<DashboardGraphTitle
				title={t('Trajectoire par année')}
				// measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
				// disable because 3287
				// rightContent={
				// 	<BButton
				// 		label=""
				// 		iconLeft={<Pencil size={20} color={siteColors.grey700} />}
				// 		variant="tertiary"
				// 		className={styles.button}
				// 		onClick={() => {
				// 			return setOpen(true);
				// 		}}
				// 	/>
				// }
			/>
			<TemporalityModal
				open={open}
				onClose={() => {
					return setOpen(false);
				}}
			/>
		</>
	);
};

export default TemporalityTitle;
