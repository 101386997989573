import _ from 'lodash';

import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import { useFindQuestionForm } from '../../../../superAdmin/questionForms/hooks';

// import QuestionFormsGeneralKPIs from './blocks/QuestionFormsGeneralKPIs';
import QuestionFormsList from './blocks/QuestionFormsList';
import EmptyQFormsList from './components/EmptyQFormsList';

const QuestionFormsSynthesis = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { data, isLoading: isLoadingFindQuestionForm } = useFindQuestionForm({
		fromBo: false,
		companyCode: authData?.session.company?.code,
	});

	const qForms = data || [];

	if (!isLoadingFindQuestionForm && _.isEmpty(qForms)) {
		return <EmptyQFormsList />;
	}

	return (
		<div>
			{/* <QuestionFormsGeneralKPIs /> */}
			<QuestionFormsList />
		</div>
	);
};

export default QuestionFormsSynthesis;

