import { useCallback, useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useParams } from 'react-router-dom';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { css } from '@emotion/css';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ClientReportsBlocInfo from './ClientReportsBlocInfo';
import ProductsClientReportsTable from './ProductsClientReportsTable';

const styles = {
	description: css({
		fontSize: '12px',
		fontWeight: 400,
		color: siteColors.grey8,
	}),
	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),
};

const ReportClientSales = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const params = useParams();
	const [searchQueryParams] = useSearchQueryParams();
	const { t } = useTranslation();
	const { indicator } = useApp();
	const { selectedYear } = useYearSelection();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { data: reportClientInfo, isLoading } = useReportClientInfo({
		objectId: params.slugClient!,
		viewMode: indicator,
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Report Client', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!reportClientInfo) return;

		trackEvent({ Client: reportClientInfo?.name });
	}, [reportClientInfo, trackEvent]);

	return (
		<ProductsClientReportsTable filterOptions={searchQueryParams} manualSortBy />
	);
};

export default ReportClientSales;
