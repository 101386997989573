import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';

import Skeleton from '../../../../../../components/Skeleton';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useRseQFormTabQueryParam } from '../../../../../supplier/general/tabs/rse/hooks/rse.hooks';
import { useFindSupplierQuestionFormsTabs } from '../hooks/rse.hooks';

const styles = {
	tabs: css({
		'.MuiTabs-flexContainer': {
			borderBottom: cn('1px solid', siteColors.grey500),
			// padding: '24 0',
			display: 'flex',
			gap: 16,
			// marginBottom: '24px',
		},

		'.MuiButtonBase-root': {
			textTransform: 'none',
			fontSize: '14px !important',
			fontWeight: '400',
			color: cn(siteColors.grey700, '!important'),
			paddingTop: '22px',
			paddingBottom: '20px',
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
			minWidth: '0px !important',
		},
		'.Mui-selected': {
			color: cn(siteColors.primary, '!important'),
		},
		'.MuiTab-textColorPrimary': {},
		'.MuiTabs-indicator': {
			backgroundColor: siteColors.primary,
		},
	}),

	tabsSelected: css({
		'.MuiTabs-indicator': {
			backgroundColor: `${siteColors.primary} !important`,
		},
	}),
};

const DetailsQuestionFormTabs = () => {
	const [qFormId, setQFormId] = useRseQFormTabQueryParam();
	const params = useParams();

	// const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();
	const { data: tabsData, isLoading: isLoadingTabsData } = useFindSupplierQuestionFormsTabs({
		supplierId: _.toString(_.get(params, 'supplierId')),
	});

	// console.log('****************', tabsData);

	useEffect(() => {
		if (tabsData) {
			if (qFormId && tabsData[qFormId]) {
				setQFormId(qFormId);
				return;
			}

			const qFormIdZero = _.get(_.entries(tabsData), '0.0');
			setQFormId(qFormIdZero);
		}
	}, [tabsData]);

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		// setTabValue(newValue);
		setQFormId(newValue);
	};

	if (isLoadingTabsData) {
		return <Skeleton width={400} height={48} sx={{ mt: '18px' }} />;
	}

	if (_.isEmpty(tabsData) || _.entries(tabsData).length < 2) {
		return <div className="width100" css={{ height: 24 }}></div>;
	}

	return (
		<div>
			<Tabs value={qFormId} onChange={handleChange} className={cx(styles.tabs, styles.tabsSelected)}>
				{_.entries(tabsData || {}).map(([key, value]) => {
					return <Tab key={nanoid()} value={key} label={_.get(value, 'displayName')} />;
				})}
			</Tabs>
		</div>
	);
};

export default DetailsQuestionFormTabs;
