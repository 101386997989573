import { Fragment } from 'react';

import { useTranslation } from '../hooks/useTranslation';

import { css, cx } from '@emotion/css';
import { Divider } from '@mui/material';
import { stylesOtherIndicator } from '../containers/productSheet/synthesis/OtherIndicatorsBlock';
import BlockCard2 from './BlockCard2';
import OtherIndicatorItem from './OtherIndicatorItem';
import PefNote from './PefNote';

type Props = {
	data: any
}

const OtherIndicatorBloc = ({ data }: Props) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexColumn gap8')}>
			<BlockCard2 cardClassName={cx(stylesOtherIndicator.otherStyle)}>
				{data.map((item: any, index: any) => {
					return (
						<Fragment key={index}>
							<OtherIndicatorItem
								lastItem={[12, 13, 14].includes(index) ? true : false}
								icon={item?.icon}
								label={item.label} value={item.measure} unity={item.unit}
								description={item.description}
								isDisabled={item?.isDisabled}
							/>
							{
								[0, 1, 3, 4, 6, 7, 9, 10, 12, 13].includes(index) &&
								(
									<><Divider
										orientation="vertical"
										flexItem
										className={cx(stylesOtherIndicator.divider)}
									/>
									</>
								)}

						</Fragment>

					)
				})
				}

			</BlockCard2>
			<PefNote />
		</div>
	);
};

export default OtherIndicatorBloc;
