import { siteColors } from '../../../apps/front/src/lib/colors';

export const SUPER_ROLE_NAME = 'super_admin';
export const ITEM_TYPE_INGREDIENT = 'ingredient';
export const ITEM_TYPE_PRODUCT = 'product';
export const INDEX_NAME_SEARCH = 'search';
export const INDEX_NAME_FACETS = 'facets';
export const FILTER_NAME_ECO_CONCEVABLE = 'ecoConcevable';
export const FOLDER_NAME_ECO_CONCEVABLE = 'Éco-concevable';

export const FILTER_NAME_TYPE_MODELIZATION_ING = 'typeModelizationIngredient';
export const FOLDER_NAME_TYPE_MODELIZATION_ING = 'Modélisation recette';

export const NOT_CONTACTED_MODELIZATION_TYPE = 1;
export const WAITING_MODELIZATION_TYPE = 2;
export const PARTIAL_DATA_MODELIZATION_TYPE = 2.5;
export const FULFILLED_MODELIZATION_TYPE = 3;

export const FRONT_PATH_NAMES = {
	home: '/dashboard',
	logIn: '/login',
	analyse: '/analyse',
	userSettings: '/user-settings',
	managerSettings: '/manager-settings',
	// products: '/products',
	products: {
		general: '/products/general',
		details: '/products/details',
	},
	ingredients: '/ingredients',
	suppliers: {
		general: '/suppliers/general',
		details: '/suppliers/details',
	},
	suggestions: '/suggestions',
	unauthorized: '/unauthorized',
	logInAs: '/login-as',
	notFound: '/not-found',
	glossary: '/glossary',
	help: '/help',
	sectorSheet: '/sector-sheet',
	changePassword: '/changer-mot-de-passe',
	invalidEmail: '/adresse-email-valide',
	invalidLink: '/lien-invalide',
	resendEmailConfirmation: '/resend-email-confirmation',
	resetPasswordNewAccount: '/reset-password-new-account',
	superAdminSettings: '/super-admin-settings',
	companies: '/super-admin-settings/companies',
	boGlossary: '/super-admin-settings/glossary',
	createCompany: '/super-admin-settings/create-company',
	editCompany: '/super-admin-settings/edit-company',
	audit: '/audit',
	audits: {
		general: '/audits/general',
	},
	chooseCompany: '/choose-company',
	simulation: '/simulation',
	documentation: '/documentation',
	faq: '/faq',
	releaseNotes: '/release-notes',
	quote: '/quote',
	// packagings: '/packagings',
	packagings: {
		general: '/packagings/general',
		details: '/packagings/details',
	},
	boSectorSheet: 'super-admin-settings/sector-sheet',
	cap2er: '/apbo',
	questionForms: '/question-forms',
	questionnaire: '/questionnaire',
	contributor: '/contributor',
	sales: '/sales',
} as const;

export const roleLabels = Object.freeze({
	ADMIN: 'administrateur',
	EDITOR: 'éditeur',
	USER: 'utilisateur',
	CONTRIBUTOR: 'contributeur',
	SUPER_ADMIN: 'super_admin',
});

export const roleSuffixName = Object.freeze({
	ADMIN: 'admin',
	EDITOR: 'editor',
	USER: 'user',
	CONTRIBUTOR: 'contributor',
	SUPER_ADMIN: 'super_admin',
});

export const tokenChangeEmail = encodeURIComponent(new Date().getTime() + 30 * 60000);

type IRole = {
	suffixName: string;
	label: string;
};

// the earlier in this array the most powerful it is
export const ROLES_HIERARCHY: readonly IRole[] = Object.freeze([
	{
		suffixName: 'admin',
		label: roleLabels.ADMIN,
	},
	{
		suffixName: 'editor',
		label: roleLabels.EDITOR,
	},
	{
		suffixName: 'user',
		label: roleLabels.USER,
	},
	{
		suffixName: 'contributor',
		label: roleLabels.CONTRIBUTOR,
	},
]);

/**
 * Parse Server class Names.
 * To avoid mistyping and centralize things.
 */
export const classNames = {
	// Core classes/collections of Parse server have a leading underscore
	USER: '_User',
	ROLE: '_Role',
	SESSION: '_Session',
	// The rest of our classes/collections
	COMPANY: 'Company',
	FINAL_PRODUCT: 'FinalProduct',
	PRODUCT: 'Product',
	CATEGORY: 'Category',
	CATEGORY_PRODUCT: 'CategoryProduct',
	FINAL_PRODUCT_NEW: 'FinalProductNew',
	PACKAGING: 'Packaging',
	INGREDIENT: 'Ingredient',
	SUPPLIER: 'Supplier',
	SIMULATION: 'Simulation',
	QUOTE: 'Quote',
	CLIENT: 'Client',
	QUOTE_GROUP: 'Group',
	COMPOSITION: 'Composition',
	UPDATE: 'Update',
	LOG: 'Log',
	TRANSLATION: 'Translation',
	COMPAGNIES_TRANSLATION: 'Compagnies_translation',
	GLOSSARY: 'Glossary',
	IMPACT_YEAR: 'ImpactYear',
	SECTOR_SHEET: 'SectorSheet',
	SECTOR_SHEET_CATEGORY: 'SectorSheetCategory',
	IMPACT_ESTIMATED: 'ImpactYearEstimated',
	EXTERNAL_DATA: 'ExternalData',
	MODELIZATION: 'Modelization',
	PRODUCT_APBO: 'ApboProduct',
	PRODUCT_VOLUME_APBO: 'ApboVolume',
	STAT_APBO: 'ApboStat',
	COMMENT_APBO: 'ApboComment',
	IMPACT_YEAR_APBO: 'ApboImpactYear',
	QUESTION_FORM: 'QuestionForm',
	QUESTION_CAMPAIGN: 'QuestionCampaign',
	CACHE_AUDIT_ACV: 'CacheAuditAcv',
	CACHE_AUDIT: 'CacheAudit',
	CACHE_AUDIT_GLOBAL: 'CacheAuditGlobal',
	JOB_QUEUE: 'JobQueue',
	ANALYZE: 'Analyze',
	CACHE_SUPPLIER_YEAR: 'CacheSupplierYear',
	CACHE_SUPPLIER_ALL_YEAR: 'CacheSupplierAllYear',
	CACHE_RSE_TABLE: 'CacheRseTable',
	EXPORT_LOG: 'LogExport',
	CACHE_REPORT_CLIENT: 'CacheReportClient',
	MODELIZATION_CMAPS: 'Modelization_CMAPS',
	CACHE_PACKAGING: 'CachePackaging',
	INGREDIENT_CMAPS: 'Ingredient_CMAPS',
	Material_CMAPS: 'Material_CMAPS',
	CARBON_FOOTPRINT: 'Carbon_footprint',
	IMPACT_ALL_YEAR: 'ImpactAll',
	SCHEMA: '_SCHEMA',
	CACHE_MATERIAL: 'CacheMaterial',
	CHAT: 'Chat',
	MATERIAL_CMAPS: 'Material_CMAPS',
	PACKAGING_CMAPS: 'Packaging_CMAPS',
	QUESTION_FORM_STATUS_UPDATE: 'QuestionFormStatusUpdate',
};

export const SUPER_ADMIN = {
	username: 'superAdminCarbonMaps',
	email: 'superadmin@carbonmaps.io',
	password: '1234567890@',
};

export const SUPER_EMAILS = [SUPER_ADMIN.email];

export const TYPE_MODELIZATION_INGREDIENT = {
	n1_standard_ingredient: 'Recette réelle',
	n2_standard_ingredient: 'Niveau 2',
	n1_inferred_ingredient: 'Données on-pack',
	n1_semantic_ingredient: 'Recette type',
	n1_portfolio_customer_ingredient: 'Moyenne gamme client',
	n1_portfolio_agb_ingredient: 'Moyenne gamme agribalyse',
	// === new as of 11 october 2023
	n1_standard: 'Recette réelle',
	n2_standard: 'Niveau 2',
	n1_inferred: 'Données on-pack',
	n1_infere: 'Données on-pack',
	n1_semantic: 'Recette type',
	n1_portfolio_customer: 'Moyenne gamme client',
	n1_portfolio_agb: 'Moyenne gamme agribalyse',
	n1_typical: 'Recette type',
	typical_ingredients: 'Recette type',
	portfolio_ingredient: 'Moyenne gamme client',
	custom_ingredients: 'Recette réelle',
} as const;

export const TYPE_MODELIZATION_PACKAGING = {
	n1_standard_packaging: 'Packaging réel',
	n1_portfolio_customer_packaging: 'Moyenne gamme client',
	n1_portfolio_agb_packaging: 'Moyenne gamme agribalyse',
	n1_typical_packaging: 'Packaging type',
	custom_packaging: 'Packaging réel',
} as const;

export type ITypeModelizationIngredientKey = keyof typeof TYPE_MODELIZATION_INGREDIENT;
export type ITypeModelizationPackagingKey = keyof typeof TYPE_MODELIZATION_PACKAGING;

export const TAG_ADVANCED_OPTION_LABEL = {
	yes: 'ACV spécifiques',
	no: 'ACV génériques',
};

export const ECO_CONCEVABLE_OPTION_LABEL = {
	all: 'Tous les produits',
	eco_concevable: 'Produits éco-concevables',
	eco_concu: 'Produits éco-conçus',
};

export const CARBON_INDICATOR = 'carbon';
export const WATER_INDICATOR = 'water';

export const GLOBAL_FACETS_OPTIONS = [
	{
		index: 'typeModelizationIngredient',
		libelle: 'Modélisation recette',
		type: 'string',
		global: true,
		width: 360,
		itemType: ITEM_TYPE_PRODUCT,
	},
	{
		index: 'tagAdvancedModelization',
		libelle: 'acv-ingredient',
		type: 'string',
		global: true,
		width: 360,
		itemType: ITEM_TYPE_PRODUCT,
	},
];

export const GLOBAL_FACETS_OPTIONS_ING = [
	{
		index: 'tagAdvanced',
		libelle: 'acv-ingredient',
		type: 'string',
		global: true,
		width: 360,
		itemType: ITEM_TYPE_INGREDIENT,
	},
];

export const FACET_STATUS = Object.freeze({
	IN_PROGRESS: 'En cours',
	STEADY: 'Active',
	FAILED: 'Echoué',
	MIGRATING: 'Migrating',
}) as any;

export const WORDING_TERMS = Object.freeze({
	INTENSITY: 'per_kilo', //Intensité / intensité carbone / intensité carbone moyenne
	INTENSITY_WEIGHTED_PRODUCT: 'per_kilo_of_product', //Intensité à la portion OU Intensité carbone pondérée
	INTENSITY_WEIGHTED_ING: 'per_kilo_of_ingredient', //Intensité à la portion OU Intensité carbone pondérée
	IMPACT: 'per_period2', //Impact / impact carbone
	IMPACT_PORTION: 'per_unit', // Impact carbone par portion
	INTENSITY_PACKAGING: 'per_kilo_of_packaging',
	IMPACT_ALL_YEAR: 'all_year',
});

export const functionName = {
	createUsersForCampaign: 'createUsersForCampaign',
	saveResponseQuestionnaire: 'saveResponseQuestionnaire',
	getDataFormQuestionnaire: 'getDataFormQuestionnaire',
	createClient: 'createClient',
	getCurrentUpdate: 'getCurrentUpdate',
	getCurrentSupplier: 'getCurrentSupplier',
	getSuppliersContacts: 'getSuppliersContacts',
	updateRelatedCampaigns: 'updateRelatedCampaigns',
	retrieveFormFromApi: 'retrieveFormFromApi',
	temporality: {
		findProduct: 'findProductTradeoff-temporality',
		findProductForEcoConceptionAutocomplete: 'findProductForEcoConceptionAutocomplete-temporality',
		findOneProduct: 'findOneProduct-temporality',
		findOneIngredient: 'findOneIngredient-temporality',
		findOnePackaging: 'findOnePackaging-temporality',
		findOneSupplier: 'findOneSupplier-temporality',
		findIngredient: 'findIngredient-temporality',
		findPackaging: 'findPackaging-temporality',
		findOneSegment: 'findOneSegment-temporality',
		findSupplier: 'findSupplier-temporality',
		findAnalyse: 'findAnalyse-temporality',
		getProductsIngredient: 'getProductsIngredient',

		getAnalyzeTradeoff: 'getAnalyzeTradeoff',
		findPeriod: 'findPeriod-temporality',
		countProductByPeriod: 'countProductByPeriod',
		countIngredientByPeriod: 'countIngredientByPeriod',
		getTotalImpactByPeriod: 'getTotalImpactByPeriod',

		getTemporalityData: 'getTemporalityData',
		saveImpactEstimated: 'saveImpactEstimated',
		getProductCategories: 'getProductCategories',
		findProductTable: 'findProductTable-temporality',
		findProductTablePackagingUses: 'findProductTablePackagingUses-temporality',
		findOneProductScatterPointData: 'findOneProductScatterPointData-temporality',
		findOneIngredientSegmentSheet: 'findOneIngredientSegmentSheet-temporality',
		findIngredientFamilyTradeoff: 'findIngredientFamilyTradeoff-temporality',
		findModelization: 'findModelization-temporality',
		countModelization: 'countModelization-temporality',
		findOneProductModelization: 'findOneProductModelization-temporality',
		findModelizationLifecycleBlock: 'findModelizationLifecycleBlock',
		countAnalyseByPeriod: 'countAnalyseByPeriod',
		findSuppliersListOptions: 'findSuppliersListOptions',
		findUserSuppliers: 'findUserSuppliers',
		findOneSupplierRsePolarChart: 'findOneSupplierRsePolarChart',
		findReportsClient: 'findReportsClient',
		countReportsClient: 'countReportsClient',

		findMaterialRecycled: 'findMaterialRecycled',
		finPackagingDetailRecycled: 'finPackagingDetailRecycled',
		findPackagingDetailEndOfLife: 'findPackagingDetailEndOfLife',

		findPackagingDetailsLifeCycle: 'findPackagingDetailsLifeCycle',
	},
	dashboard: {
		getSuppliersByTemporality: 'getSuppliersByTemporality',
		getTemporalityLifeChart: 'getTemporalityLifeChart',
		getProductCategories: 'getProductCategories',
		getTopSupplier: 'getTopSupplier',
		getCurrentCategory: 'getCurrentCategory',
		getImpactByCategory: 'getImpactByCategory',
	},
	product: {
		getFacets: 'getFacetsProduct',
		getProductInfo: 'getProductInfo',
		getProductImpact: 'getProductImpact',
		getDataPopoverProduct: 'getDataPopoverProduct',
		getLifeChartProduct: 'getLifeChartProduct',
		getModelizationProduct: 'getModelizationProduct',
		getIngredientsProduct: 'getIngredientsProduct',
		getPackagingProduct: 'getPackagingProduct',
		exportProducts: 'exportProducts',
		getRecycledMaterialsProduct: 'getRecycledMaterialsProduct',
		getACVPackagingProduct: 'getACVPackagingProduct',
		getExportPackagingProduct: 'getExportPackagingProduct',
	},
	suppliers: {
		exportSuppliers: 'exportSuppliers',
		rse: {
			findGeneralRSERadarChartData: 'findGeneralRSERadarChartData',
			findGeneralRSESuppliersRepartitionChart: 'findGeneralRSESuppliersRepartitionChart',
			getInfoNoteData: 'getInfoNoteData',
			findSupplierQuestionFormsTabs: 'findSupplierQuestionFormsTabs',
			getAnswerDateInfo: 'getAnswerDateInfo',
		},
		getSuppliersByName: 'getSuppliersByName',
		findOneSupplierData: 'findOneSupplierData',
		getFacetsSupplier: 'getFacetsSupplier',
		findSupplierScoreByUid: 'findSupplierScoreByUid',
		findSupplierImpactPercentForRow: 'findSupplierImpactPercentForRow',
	},

	packaging: {
		kpi: {
			getImpactTotalPackaging: 'getImpactTotal',
			getRecycledPercentage: 'getRecycledPercentage',
			getTonnagePackaging: 'getTonnagePackaging',
			getImpactRecycledPackaging: 'getImpactRecycledPackaging',
			isCmapsModelization: 'isCmapsModelization',
			getDataMaterialKpi: 'getDataMaterialKpi',
			getDataTonnageMaterial: 'getDataTonnageMaterial',
		},
		graph: {
			getRecycledPercentageComparaison: 'getRecycledPercentageComparaison',
		},
		table: {
			getDataMaterialRecycled: 'getDataMaterialRecycled',
			getDataMaterialTable: 'getDataMaterialTable',
		},
		endOfLife: {
			getEndOfLifePercent: 'getEndOfLifePercent',
			getRecycledEndOfLifePercentageComparaison: 'getRecycledEndOfLifePercentageComparaison',
		},
		getDataLifeCyclePackaging: 'getDataLifeCyclePackaging',
		getFacetsPackaging: 'getFacetsPackaging',
	},

	analyze: {
		getAnalyzeLifeChart: 'getAnalyzeLifeChart',
		getBreakDownCategoriesData: 'getBreakDownCategoriesData',
		getGlobalIndicatorCategory: 'getGlobalIndicatorCategory',
		getProductsByCategory: 'getProductsByCategory',
		getOtherIndicatorAnalyze: 'getOtherIndicatorAnalyze',
		getProductDonut: 'getProductDonut',
		getIngredientDonut: 'getIngredientDonut',
		getSupplierDonut: 'getSupplierDonut',
		getFirstSuppliers: 'getFirstSuppliers',
		getDataAnalyzeProductTable: 'getDataAnalyzeProductTable',
		getDataAnalyzeIngredientTable: 'getDataAnalyzeIngredientTable',
		getDataAnalyzeSupplierTable: 'getDataAnalyzeSupplierTable',
	},
	apbo: {
		getPeriod: 'getPeriod',
		globalIndictorData: 'globalIndictorData',
		getResultCount: 'getResultCount',
		getCarbonTrajectoryGraph: 'getCarbonTrajectoryGraph',
		getDataTooltip: 'getDataTooltip',
		getDataTable: 'getDataTable',
		addComment: 'addComment',
		removeComment: 'removeComment',
		getComments: 'getComments',
		getStatDiag: 'getStatDiag',
		getIndicatorStat: 'getIndicatorStat',
	},
	audit: {
		getAllDataAudit: 'getAllDataAudit',
	},
	job: {
		temporalityUpdated: 'temporalityUpdated',
		createFacets: 'createFacets',
		computeTemporality: 'computeTemporality',
		initAuditCache: 'initAuditCache',
		computeSuppliersQuestionForms: 'computeSuppliersQuestionForms',
		updateSimulation: 'updateSimulation',
		handleQFormSubmission: 'handleQFormSubmission',
		initReportClientCache: 'initReportClientCache',
		computePackaging: 'computePackaging',
		computeCachePackaging: 'computeCachePackaging',
		denormalizeProduct: 'denormalizeProduct',
		denormalizeProductImpactAll: 'denormalizeProductImpactAll',
		addPercentImpactAll: 'addPercentImpactAll',
		denormalizeIngredientImpactAll: 'denormalizeIngredientImpactAll',
		denormalizeSupplierAllYear: 'denormalizeSupplierAllYear',
		denormalizeSuppliers: 'denormalizeSuppliers',
		denormalizePackagingAllYear: 'denormalizePackagingAllYear',
		computeImpactAll: 'computeImpactAll',
		disableUpdate: 'disableUpdate',
		enableUpdate: 'enableUpdate',
		denormalizeQuoteClient: 'denormalizeQuoteClient',
		analyze: 'analyze',
		materialCache: 'materialCache',
		updateGroup: 'updateGroup',
		impactAllPackaging: 'impactAllPackaging',
	},
	devis: {
		findProductToAddToDevis: 'findProductToAddToDevis',
	},
	quote: {
		create: 'createQuote',
		import: 'importQuotes',
		deleteClient: 'deleteClient',
		delete: 'deleteQuote',
		deleteReportClient: 'deleteReportClient',
		findQuoteReportsClient: 'findQuoteReportsClient',
		findQuoteReportsClientGraph: 'findQuoteReportsClientGraph',
	},

	log: {
		getExportLog: 'getExportLog',
	},

	ingredient: {
		exportIngredients: 'exportIngredients',
	},

	questions: {
		findRseTableData: 'findRseTableData',
		// findGeneralRSERadarChartData: 'findGeneralRSERadarChartData',
		launchQuestionFormsScoring: 'launchQuestionFormsScoring',
		findQuestionFormsTabs: 'findQuestionFormsTabs',
		findLinkedSurveysForCompany: 'findLinkedSurveysForCompany',
		setPreferredSurveyForCompany: 'setPreferredSurveyForCompany',
		saveRSEObjectOnWidgetSubmitAction: 'saveRSEObjectOnWidgetSubmitAction',
		reOpenSurveyForSupplier: 'reOpenSurveyForSupplier',
		getSentCampaignsCount: 'getSentCampaignsCount',
		getContactedSuppliersCount: 'getContactedSuppliersCount',
		getReceivedResponsesCount: 'getReceivedResponsesCount',
		getStatusPercentagesForQuestionForm: 'getStatusPercentagesForQuestionForm',
		getStatusTopHistoryForQuestionForm: 'getStatusTopHistoryForQuestionForm',
		findSuppliersTrackingList: 'findSuppliersTrackingList',
		findContactsListForSupplierTracking: 'findContactsListForSupplierTracking',
		getSupplierTrackingGraphData: 'getSupplierTrackingGraphData',
		getSentCampaignsCountDetails: 'getSentCampaignsCountDetails',
		getContactedSuppliersCountDetails: 'getContactedSuppliersCountDetails',
		getReceivedResponsesCountDetails: 'getReceivedResponsesCountDetails',
	},
	users: {
		createContactSupplier: 'createContactSupplier',
		getExistingUserByEmail: 'getExistingUserByEmail',
		// findGeneralRSERadarChartData: 'findGeneralRSERadarChartData',
		// launchQuestionFormsScoring: 'launchQuestionFormsScoring',
	},
	sales: {
		findOneReportClientBasicInfo:'findOneReportClientBasicInfo',
		findOneReportClient: 'findOneReportClient',
		findReportClientKpi: 'findReportClientKpi',
		findOneReportClientPackaging: 'findOneReportClientPackaging',
	},
	carbonFootprint: {
		saveCarbonFootprint: 'saveCarbonFootprint',
		getCurrentCarbonFootPrint: 'getCurrentCarbonFootPrint',
		getCarbonFootprintByCategory: 'getCarbonFootprintByCategory',
		getCarbonFootprintByScope: 'getCarbonFootprintByScope',
		getCarbonFootprintProduct: 'getCarbonFootprintProduct',
		getDataSBTIFlag: 'getDataSBTIFlag',
		getTotalImpactByCategory: 'getTotalImpactByCategory',
	},
	chat: {
		sendMessage: 'sendMessage',
	},
} as const;

export const APBO_CURRENT_YEAR = 2023;

export const filterSimulationName = {
	all: 'all',
	eco_concevable: 'eco_concevable',
	eco_concu: 'eco_concu',
};

/**
 * Parse Server type in collection impactYear.
 * To avoid mistyping and centralize things.
 */
export const typeNames = {
	PRODUCT: 'product' as const,
	INGREDIENT: 'ingredient' as const,
	SUPPLIER_PRODUCT: 'product_supplier' as const,
	SUPPLIER_INGREDIENT: 'ingredient_supplier' as const,
	PACKAGING: 'packaging' as const,
	ALL: 'all' as const,
	CLIENT: 'client' as const,
	MATERIAL: 'material',
	END_OF_LIFE: 'endOfLife',
	PACKAGING_CLIENT: 'clientPackaging',
};

export const jobQueueStatus = {
	WAITING: 'waiting',
	RUNNING: 'running',
	FINISHED: 'finished',
	FAILED: 'failed',
};
export const TYPE_MODELIZATION_INGREDIENT_PERSONALIZED = [
	'n1_semantic',
	'n1_inferred',
	// 'typical_ingredients',
	'n1_standard',
	'n1_semantic_ingredient',
	'n1_inferred_ingredient',
	'n1_standard_ingredient',
	'custom_ingredients',
];

export const TYPE_MODELIZATION_PACKAGING_PERSONALIZED = [
	'n1_standard_packaging',
	'custom_Packaging',
	'custom_packaging',
];

// TYPE MODELIZATION COMPO INGREDIENT
export const TM_COMPO_REFERENCE = 'reference';
export const TM_COMPO_AGRIBALYSE = 'external';
export const TM_COMPO_CUSTOM = 'custom';
export const TM_COMPO_CMAPS = 'cmaps';

export const TM_COMPO_INGREDIENT = new Map();

TM_COMPO_INGREDIENT.set(TM_COMPO_REFERENCE, {
	id: TM_COMPO_REFERENCE,
	color: siteColors.text,
	backgroundColor: siteColors.text,
	borderColor: siteColors.text,
	keyGlossary: 'modelization-reference',
});

TM_COMPO_INGREDIENT.set(TM_COMPO_AGRIBALYSE, {
	id: TM_COMPO_AGRIBALYSE, // or externe
	color: siteColors.grey700,
	backgroundColor: '#FFF',
	borderColor: siteColors.grey700,
	keyGlossary: 'modelization-external',
});

TM_COMPO_INGREDIENT.set(TM_COMPO_CUSTOM, {
	id: TM_COMPO_CUSTOM,
	color: siteColors.primary,
	backgroundColor: siteColors.primary,
	borderColor: siteColors.primary,
	keyGlossary: 'modelization-custom',
});

TM_COMPO_INGREDIENT.set(TM_COMPO_CMAPS, {
	id: TM_COMPO_CUSTOM,
	color: siteColors.primary,
	backgroundColor: siteColors.primary,
	borderColor: siteColors.primary,
	keyGlossary: 'modelization-cmaps',
});
export const MESSAGE_KEY_APBO = {
	globalIndicator: 'global-indicator',
	carbonTrajectory: 'carbon-trajectory',
	methaneIndicator: 'methane-indicator',
	leversAction: 'levers-action',
	breederTraining: 'breeder-training',
	carbonLabel: 'carbon-label',
	answer: 'fill-answer',
	grossEmissionIndicator: 'gross-emission-indicator',
	gesNetIndicator: 'ges-net-indicator',
	carbonStorageIndicator: 'carbon-storage-indicator',
};

export const MIN_INGREDIENT_ECO_DESIGNED = 2;
export const MIN_PACKAGING_ECO_DESIGNED = 2;

export const readPreference = {
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
	PRIMARY_PREFERRED: 'PRIMARY_PREFERRED',
	SECONDARY_PREFERRED: 'SECONDARY_PREFERRED',
};

export const ANALYZE_TYPE = {
	facets: 'facette',
	supplier: 'supplier',
	ingredient: 'ingredient',
	facetsClient: 'facetteClient',
};

export const EXPORT_TYPE = {
	product: 'product',
	ingredient: 'ingredient',
	supplier: 'supplier',
};

export const PACKAGING_CACHE_TYPE = {
	materialRecycled: 'materialRecycled',
	endOfLifeGlobal: 'endOfLifeGlobal',
	globalIndicator: 'globalIndicator',
	lifeChartData: 'lifeChartData',
	facets: 'facets',
	impactMaterial: 'impactMaterial',
	tonnageMaterial: 'tonnageMaterial',
};

export const END_OF_LIFE_COLORS = {
	Recyclable: siteColors.green500,
	Recyclabilité: siteColors.green500,
	Décharge: siteColors.blue500,
	Incinération: siteColors.orange500,
} as any;

export const EndOFLifeMap = new Map([
	[
		'Recyclabilité',
		{
			translationKey: 'recyclable',
			color: END_OF_LIFE_COLORS.Recyclabilité,
		},
	],
	[
		'Recyclable',
		{
			translationKey: 'recyclable',
			color: END_OF_LIFE_COLORS.Recyclabilité,
		},
	],
	[
		'Décharge',
		{
			translationKey: 'decharge',
			color: END_OF_LIFE_COLORS.Décharge,
		},
	],
	[
		'Incinération',
		{
			translationKey: 'incineration',
			color: END_OF_LIFE_COLORS.Incinération,
		},
	],
]);

export const CARBON_FOOTPRINT_CATEGORIES_FIELDS = [
	'intrants',
	'packaging',
	'fret',
	'immobilisations',
	'energy',
	'transport',
	'direct_waste_emissions',
	'product_lifecycle',
];

export const CARBON_FOOTPRINT_CATEGORIES_CONFIG = new Map();
CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('intrants', {
	label: 'Intrants',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('packaging', {
	label: 'Emballages',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('fret', {
	label: 'Fret',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('immobilisations', {
	label: 'Immobilisations',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('energy', {
	label: 'Energie & hors énergie',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('transport', {
	label: 'Déplacements',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('direct_waste_emissions', {
	label: 'Déchets directs',
});

CARBON_FOOTPRINT_CATEGORIES_CONFIG.set('product_lifecycle', {
	label: 'Utilisation & fin de vie',
});

export const CARBON_FOOTPRINT_SCOPE_FIELDS = ['scope_1', 'scope_2', 'scope_3'];

export const CARBON_FOOTPRINT_SCOPE_CONFIG = new Map();

CARBON_FOOTPRINT_SCOPE_CONFIG.set('scope_1', {
	label: 'Scope 1',
	color: siteColors.scope1,
});

CARBON_FOOTPRINT_SCOPE_CONFIG.set('scope_2', {
	label: 'Scope 2',
	color: siteColors.scope2,
});

CARBON_FOOTPRINT_SCOPE_CONFIG.set('scope_3', {
	label: 'Scope 3',
	color: siteColors.scope3,
});

export const ALL_YEAR_OPTION_VALUE = -1;

export const FACETS_COLLECTION = {
	supplier: 'Supplier',
};


export const FACET_SUPPLIER_TYPE = 'supplier-facet';


export const TYPE_MODELIZATION_ING_CUSTOM = 'Custom_Ingredients';
