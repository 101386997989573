import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { FacetFormInput, FacetFormValidationSchema } from '@carbonmaps/shared/validations/facet.validations';
import { createFacetAction } from '@carbonmaps/ui/actions/company.actions';
import BSelectNative from '@carbonmaps/ui/components/saas/BSelectNative';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../../lib/react-query/features/img/img.hooks';

const FacetForm = ({ toggle }: any) => {
	const theme = useTheme();
	const [message, setMessage] = useState('');
	const styles = classes(theme);
	const queryClient = useQueryClient();
	// query params
	const queryParams = useQueryParams();

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isValid },
		// watch,
		reset,
	} = useForm<FacetFormInput>({
		resolver: zodResolver(FacetFormValidationSchema),
		mode: 'onChange',
	});

	const {
		mutate: createFacet,
		isError,
		isSuccess,
		isLoading,
	} = useMutation({
		mutationKey: ['createFacet'],
		mutationFn: createFacetAction,
		onSuccess: async () => {
			setMessage(t('save-facet-message'));
			queryClient.invalidateQueries({ queryKey: ['getCompanySheetFacet'] });
			toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<FacetFormInput> = async (values) => {
		createFacet({
			...values,
		} as any);
	};

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle={t('save-facet-button')}
				defaultLogo={authData?.session.company?.logo ? logoUrl : defaultLogo}
				// {isSuccessUserLoading && currentUser?.company?.logoUrl}
				onSubmit={handleSubmit(onSubmit)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				onCancel={() => {
					toggle();
				}}
				alertSuccess={
					<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
				}
				alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
				addStyles={{
					maxWidth: 528,
					// gap: 24,
					margin: 'auto',
				}}
			>
				<input type="hidden" {...register('companyId')} value={queryParams?.companyId[0]} />
				<p css={styles.subtitle}>{t('form-index-title')}</p>

				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						marginTop: '12px',
						marginBottom: '12px',
						gap: 24,
					}}
				>
					<BSelectNative
						{...register('collectionName')}
						id="collectionName"
						label={t('element-type-label')}
						options={[
							{ value: 'Product', label: 'Produit' },
							{ value: 'Ingredient', label: 'Ingrédient' },
							{ value: 'Supplier', label: 'Fournisseur' },
						]}
						name="collectionName"
						error={errors.collectionName ? true : false}
						errorMessage={errors?.collectionName?.message}
					/>
				</div>
				<input type="hidden" {...register('indexName')} value="facets" />
				{/* // disable select index name , set statique in code */}

				{/* <div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BSelectNative
						{...register('indexName')}
						id="indexName"
						label="Nom de l'index"
						options={[
							{ value: 'autocomplete', label: 'Autocomplete' },
							{ value: 'facets', label: 'Facets' },
						]}
						name="indexName"
						error={errors.indexName ? true : false}
						errorMessage={errors?.indexName?.message}
					/>
				</div> */}
			</FormContainer>
		</div>
	);
};

export default FacetForm;
