import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';
import { supplierCompanyCount } from '@carbonmaps/ui/actions/supplier.actions';

import IconContainer from '../../../../components/layout/list/IconContainer';
import ListHeader from '../../../../components/layout/list/ListHeader';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';

const SuppliersGeneralHeader = () => {
	const theme = useTheme();
	const { selectedYear } = useYearSelection();

	// translation
	const { t } = useTranslation();
	// ---- fetch count product ---- //
	// get count product for a company. this count will appear in header list
	const { data: supplierCount } = useQuery({
		queryKey: ['getSupplierCompanyCount', { selectedYear }],
		queryFn: supplierCompanyCount,
	});

	return (
		<ListHeader
			title={t('suppliers')}
			subtitle={`${supplierCount ?? ''} ${t('suppliers')}`}
			icon={
				<IconContainer
					element={<SupplierIcon color={theme.palette.common.white} width={32} height={32} />}
					color={siteColors.blueGray500}
				/>
			}
		/>
	);
};

export default SuppliersGeneralHeader;
