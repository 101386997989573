import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useParams } from 'react-router-dom';

import { TM_COMPO_AGRIBALYSE, TM_COMPO_CUSTOM, TM_COMPO_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import VsMarket from '@carbonmaps/ui/components/saas/Table/VsMarket';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import IndicatorSingleCard from '../../../components/card/IndicatorSingleCard';
import Diff from '../../../components/modelization/Diff';
import PointListLabel from '../../../components/modelization/PointListLabel';
import Skeleton from '../../../components/Skeleton';
import { indicatorConfigItems } from '../../../components/synthese/IndicatorsBlockLight';
import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetOneProductModelization } from '../../../lib/react-query/features/modelization/modelization.hooks';

const styles = {
	container: css({
		width: '100%',
	}),

	flex1: css({
		flex: 1,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: '16px',
	}),

	stretch: css({
		alignItems: 'stretch',
	}),

	p3: css({
		padding: 3,
	}),
	width100: css({
		width: '100%',
	}),
	skeleton: css({
		height: '175px !important',
		width: '100% !important',
	} as any),

	title: css({
		fontSize: '16px',
		fontWeight: 600,
		alignItems: 'stretch!important',
	}),

	content: css({
		fontSize: '16px',
		fontWeight: 600,
		alignItems: 'stretch!important',
		position: 'relative',
	}),
	contentTitle: css({
		padding: '16px',
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey900,
		borderBottom: cn('1px solid', siteColors.grey500),
		gap: '5px',
		background: siteColors.grey200,
		borderRadius: '16px 16px 0  0',
		textAlign: 'center',
		alignItems: 'center !important',
	}),
	subtitle: css({
		textDecorationLine: 'underline',
		color: siteColors.grey900,
		textAlign: 'center',
	}),

	cardContent: css({
		padding: '16px',
		alignItems: 'center!important',
	}),
	elementDifference: css({
		position: 'absolute',
		top: '95px',
		left: '50%' /* ajustez la valeur de top selon votre besoin */,
		zIndex: 100,
		transform: 'translate(-50%, -50%)',
	}),
};

const EmissionBlock = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const params = useParams();
	const { indicator } = useApp();

	const { data, isLoading, isError, error } = useGetOneProductModelization({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator ?? 'carbon',
	});
	console.log(' data?.dataCustom', data?.dataCustom);


	return (
		<div className={cx('flexColumn gap16', styles.container)}>
			<div className={cx(styles.title)}>
				{t('modelization-title-emission-composition', {
					recipe_lowercase: t('recipe').toLowerCase(),
				})}
			</div>

			<div className={cx('flexRow flex1 nowrap alignCenter stretch width100 gap24', styles.content)}>
				{isLoading
					? (
						<Skeleton width="100%!important" className={styles.skeleton} />
					)
					: (
						<>
							<div className={cx(styles.flex1)}>
								<div className={cx('flewRow', styles.contentTitle)}>
									<PointListLabel
										value={t('tm_external')}
										color={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.color}
										backgroundColor={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.backgroundColor}
										borderColor={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.borderColor}
										keyGlossary={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.keyGlossary}
									/>
								</div>
								<div className={cx(styles.cardContent)}>
									<IndicatorSingleCard
										indicator={indicator}
										value={displayValue(
											(indicator === CARBON_INDICATOR
												? data?.dataAgribalyse?.carbonIntensity
												: data?.dataAgribalyse?.waterIntensity) ?? '',
											undefined,
											2,
										)}
										icon={indicatorConfigItems.get(indicator)?.intensityIcon}
										title={''}
										unity={indicatorConfigItems.get(indicator)?.intensityUnity}
									/>
								</div>
							</div>

							{data?.modelizationActive?.toLowerCase() !== TM_COMPO_AGRIBALYSE && (
								<div className={cx(styles.flex1)}>
									<div className={cx('flewRow', styles.contentTitle)}>
										<PointListLabel
											value={t('tm_custom')}
											color={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.color}
											backgroundColor={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.backgroundColor}
											borderColor={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.borderColor}
											keyGlossary={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.keyGlossary}
										/>
									</div>
									<div className={cx(styles.cardContent)}>
										<div>
											{data?.dataCustom
												? <IndicatorSingleCard
													indicator={indicator}
													value={displayValue(
														(indicator === CARBON_INDICATOR
															? data?.dataCustom?.carbonIntensity
															: data?.dataCustom?.waterIntensity) ?? '',
														undefined,
														2,
													)}
													icon={indicatorConfigItems.get(indicator)?.intensityIcon}
													title={''}
													unity={indicatorConfigItems.get(indicator)?.intensityUnity}
												/>
												: <Typography className="grey800">{t('no-data-custom-modelization')}</Typography>}
										</div>
									</div>
								</div>
							)}
						</>
					)}

				{!isLoading && data?.modelizationActive?.toLowerCase() !== TM_COMPO_AGRIBALYSE && (
					<div className={cx(styles.elementDifference)}>
						<VsMarket column={{
							props: {
								t, page: 'view-modelization', showPopover: true,
							},
						}} row={{
							original: data,
						}} value={data?.VSExternePercent} />
					</div>
				)}
			</div>
		</div>
	);
};

export default EmissionBlock;
