import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { useYearSelection } from '../../../../../../../hooks/useImpactYear';

type Props = {
	supplier: Record<string, any> | null;
};

const SupplierImpactPercent = ({ supplier }: Props) => {
	const uid = _.get(supplier, '_id', null);
	const { selectedYear } = useYearSelection();

	const { data } = useQuery({
		queryKey: ['findSupplierImpactPercentForRow', { supplierUid: uid, selectedYear }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUid, selectedYear } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.suppliers.findSupplierImpactPercentForRow, { supplierUid, selectedYear });
			} catch (error) {
				console.log('--- findSupplierScore action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: !!uid,
	});

	return (
		<div css={{ paddingRight: '8px', position: 'absolute', top: 25, right: 0}}>{_.get(data, 'value') ? displayValue(_.get(data, 'value')) : '-'}{' '}%</div>
	);
};

export default SupplierImpactPercent;
