import { useCallback, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

import { getTotalImpactPerPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSize } from '../../../hooks/useSize';
import { useTranslation } from '../../../hooks/useTranslation';
import { stylesAnalyze } from '../../dashboard/v3/AnalyzeTab';
import ClientReportsBlocInfo from './ClientReportsBlocInfo';
import ImpactPerCategoryClient from './ImpactPerCategoryClient';
import LifeCycleGraphClient from './LifeCycleGraphClient';
import PackagingImpactClient from './PackagingImpactClient';
import TemporalityClient from './TemporalityClient';

const SynthesisReportClient = () => {

	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	const params = useParams();
	const { t } = useTranslation();
	const { indicator } = useApp();


	const [graphWidth, setGraphWidth] = useState('100%');

	const ref = useRef(null);
	const size = useSize(ref as never);

	useEffect(() => {
		if (((size as DOMRectReadOnly | undefined)?.width || 0) > 944) {
			setGraphWidth('445px');
		} else {
			setGraphWidth('100%');
		}
	}, [size]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();


	const { data, isLoading } = useQuery({
		queryKey: [
			'getTotalImpactPerPeriod',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
				slugClient: params.slugClient!,
			},
		],
		queryFn: getTotalImpactPerPeriod,
	});

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { data: reportClientInfo } = useReportClientInfo({
		objectId: params.slugClient!,
		viewMode: indicator,
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Report Client', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!reportClientInfo) return;

		trackEvent({ Client: reportClientInfo?.name });
	}, [reportClientInfo, trackEvent]);

	return (
		<>
			<div style={{ marginBottom: 32 }}>
				<ClientReportsBlocInfo />
			</div>
			<div style={{ marginBottom: 32 }}>
				{data && data?.length === 1 && (
					<div className={cx('flexRow width100 backgroundWhite nowrap ')}>
						<LifeCycleGraphClient iconSize={24} />
					</div>
				)}
			</div>
			<div ref={ref} className={cx(stylesAnalyze.container, 'width100')}>
				<div className={cx(stylesAnalyze.innerContainer, 'dashContent-innerContainer')}>
					{((data && data?.length > 1) || isLoading) && (
						<><LifeCycleGraphClient iconSize={24} />
							<TemporalityClient graphWidth={graphWidth} />	</>
					)}
					<ImpactPerCategoryClient />

					<PackagingImpactClient />
				</div>
			</div>
		</>
	);
};

export default SynthesisReportClient;
