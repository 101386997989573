import { Navigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

//import useGetCurrentSession from '../../hooks/useGetSession';
import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import LogInForm from './LogInForm';

const Login = () => {
	const hasRoles = useHasRoles();

	const analytics = useJuneTrack();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	if (authData) {
		// track login event
		analytics.track('General Logged In', {}, { context: { category: 'General' } });

		// identify user
		analytics.identify(authData?.user?.objectId, {
			email: authData?.user?.email,
			role:
				authData?.roles
					?.map((role) => {
						return role.label;
					})
					.join(', ') || 'no role',
			name: `${authData?.user?.firstName} ${authData?.user?.lastName}`,
		});

		if (hasRoles(['super_admin'])) return <Navigate replace to={FRONT_PATH_NAMES.superAdminSettings} />;

		if (!authData.session.company) return <Navigate replace to={FRONT_PATH_NAMES.chooseCompany} />;

		return <Navigate to={FRONT_PATH_NAMES.home} />;
	}

	return (
		<div css={{ width: '100%' }}>
			<LogInForm />
		</div>
	);
};

export default Login;
