import { css, cx } from '@emotion/css';

import { useTranslation } from '../../../../hooks/useTranslation';
import MaterialCard from '../analyze/kpi/MaterialCard';

import MaterialsTable from './MaterialsTable';
import TonnageMaterialCard from './TonnageMaterialCard';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	container: css({
		paddingBottom: 85,
		width: CONTENT_WIDTH,
		maxWidth: CONTENT_MAX_WIDTH,
		margin: 'auto',
	}),
};

const Materials = () => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexColumn width100 gap20', styles.container)}>
			<div className="flexRow stretch  nowrap gap24 width100">
				<TonnageMaterialCard />
				<MaterialCard legendTitle={t('percent-impact-total')} />
			</div>
			<MaterialsTable />
		</div>
	);
};

export default Materials;
