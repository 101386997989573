import { type QueryFunctionContext } from '@tanstack/react-query';
import _ from 'lodash';

type getProductSimulationDataType = {
	input?: string;
	facetFilters?: string;
	page: number;
	size: number;
};

export const getProductSimulationDataAction = async (
	context: QueryFunctionContext<readonly ['getProductSimulationDataAction', getProductSimulationDataType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const { data, count } = await Parse.Cloud.run('findProductEcoConception', {
			limit: size,
			isSourceSimulation: true,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations                                       //
//--------------------------------------------------------------------------------------//
type createSimulationInput = {
	name: string;
	productId: string;
	selectedSimulationId: string;
};

export const createSimulationAction = async ({ name, productId, selectedSimulationId }: createSimulationInput): Promise<void> => {
	try {

		if (!selectedSimulationId) {
			const res = await Parse.Cloud.run('createSimulation', { name, productId, selectedSimulationId });

			//reset scenario to avoid data inconsistency // TODO: check if this is still needed
			if (res) {
				console.log('scenario init', res.objectId);
				await Parse.Cloud.run('resetScenario', { simulationId: res.objectId });
			}

			return res;
		}

		const res = await Parse.Cloud.run('duplicateSimulation', { name, selectedSimulationId });
		return res;

	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *  Get simulation details
 * @param context
 */
export const getSimulationAction = async (context: any) => {
	const {
		queryKey: {
			1: { simulationId, itemType },
		},
	} = context;

	try {
		const result = await Parse.Cloud.run('getSimulation', {
			simulationId,
			itemType,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations delete                                //
//--------------------------------------------------------------------------------------//
type deleteSimulationInput = {
	objectId?: string;
	simulationIds?: string[];
	productSourceIds?: string[];
};

export const deleteSimulationAction = async ({
	objectId,
	productSourceIds,
	simulationIds,
}: deleteSimulationInput): Promise<void> => {
	try {
		await Parse.Cloud.run('deleteSimulation', { objectId, simulationIds, productSourceIds });
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations update soft                           //
//--------------------------------------------------------------------------------------//
type updateSimulationInput = {
	objectId: string;
	status: string;
};

export const updateStatusSimulationAction = async ({ objectId, status }: updateSimulationInput): Promise<void> => {
	try {
		await Parse.Cloud.run('updateStatusSimulation', { objectId, status });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateIngredientAction = async ({ simulationId, currentIngredientId, prevIngredientId, isRestore, ingredientToRestoreData }: any) => {
	try {
		return Parse.Cloud.run('updateIngredientSimulation', { simulationId, currentIngredientId, prevIngredientId, isRestore, ingredientToRestoreData });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteIngredientAction = async ({ simulationId, ingredientId }: any) => {
	try {
		return Parse.Cloud.run('deleteIngredientSimulation', { simulationId, ingredientId });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updatePackagingAction = async ({ simulationId, currentId, replaceId, isRestore, packagingToRestoreData }: any) => {
	try {
		console.log({ packagingToRestoreData });

		const materials = _.get(packagingToRestoreData, 'sim_modelization.materials');

		if (materials) {
			const newMaterials = materials.map((m: any) => {
				const rawMaterial = _.get(m, 'material');
				return {
					...m,
					rawMaterial: (rawMaterial instanceof Parse.Object ? rawMaterial.toJSON() : null),
				};
			});

			_.set(packagingToRestoreData, 'sim_modelization.materials', newMaterials);
		}

		return Parse.Cloud.run('updatePackagingSimulation', { simulationId, currentId, replaceId, isRestore, packagingToRestoreData });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deletePackagingAction = async ({ simulationId, packagingId }: any) => {
	try {
		return Parse.Cloud.run('deletePackagingSimulation', { simulationId, packagingId });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllProductSimulationAction = async (context: any) => {
	try {
		const {
			queryKey: {
				1: { simulationId, input },
			},
		} = context;
		const result = Parse.Cloud.run('getAllProductSimulation', {
			simulationId,
			input,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateScenarioAction = async (data: any) => {
	try {
		return Parse.Cloud.run('updateScenario', data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateSimulationDbAction = async (data: any) => {
	try {
		return Parse.Cloud.run('updateSimulationDb', data);
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                       Reset simulation with product initial                          //
//--------------------------------------------------------------------------------------//
export const resetScenarioAction = async (data: any) => {
	try {
		return Parse.Cloud.run('resetScenario', data);
	} catch (error) {
		return Promise.reject(error);
	}
};
