import { useCallback, useEffect } from 'react';

import { useJuneTrack } from '../../../../../hooks/useJuneTrack';

import QuestionFormDetailsKPIs from './blocks/QuestionFormDetailsKPIs';
import QuestionFormLatestStatusUpdates from './blocks/QuestionFormLatestStatusUpdates';
import QuestionFormStatusRepartition from './blocks/QuestionFormStatusRepartition';
import GraphBlock from './blocks/graph/GraphBlock';


const QuestionFromDetailsOverView = () => {
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Question Form' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Question Form Land');
	}, [trackEvent]);

	return (
		<div>
			<QuestionFormDetailsKPIs/>
			<GraphBlock />
			<div css={{
				display: 'grid',
				gap: '16px',
				gridTemplateColumns: '1fr 1fr',
				marginBottom: '48px',
			}}>
				<QuestionFormStatusRepartition />
				<QuestionFormLatestStatusUpdates />
			</div>
		</div>
	);
};

export default QuestionFromDetailsOverView;
