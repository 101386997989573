import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { HelpCircle, PackageOpen } from 'lucide-react';
import { createSearchParams } from 'react-router-dom';

import {
	ALL_YEAR_OPTION_VALUE,
	END_OF_LIFE_COLORS,
	FRONT_PATH_NAMES,
	WORDING_TERMS,
} from '@carbonmaps/shared/utils/constants';
// import { GetProductTableQueryParams } from '@carbonmaps/ui/actions/product.actions';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import ImpactCarbonePopover2 from '../../../../components/table/popover/ImpactCarbonePopover2';
import WaterImpactPopover2 from '../../../../components/table/popover/WaterImpactPopover2';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useFindPackagingsTable } from '../../../../lib/react-query/features/packaging/packaging.hooks';
import { CARBON_INDICATOR } from '../../../../utils/constants';
import { getRecycledArray, manageEndOfLife } from '../../../../utils/packaging';
import { useCmapsModelization } from '../../packagings.hooks';

const stickyHeaderStyle = getStickyHeaderStyle(1354, 200);

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		// 'th:nth-of-type(2)> div ': {
		// 	width: '120px !important',
		// 	maxWidth: '100%',
		// },

		'th:nth-of-type(5) > div, th:nth-of-type(6) > div ': {
			width: '105px !important',
			maxWidth: '100%',
		},

		'td:nth-of-type(5) > div, td:nth-of-type(6) > div ': {
			width: '105px !important',
			maxWidth: '100%',
		},

		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
	title: css({
		maxWidth: '200px !important',
	}),
};

const formatData = (res: any[], paramsUrlString: any, indicator: any, isVersion2x?:boolean) => {
	if (!res?.length) return [];

	return res.map((item: any) => {
		return {
			...item,
			link: `${FRONT_PATH_NAMES.packagings.details}/${item.objectId}/synthesis`,
			recycledValues: item?.recycled ? getRecycledArray(item?.recycled) : [],
			endOfLife: manageEndOfLife(item?.endOfLife || []),
			isVersion2x,
		};
	});
};

type PackagingsProps = {
	manualSortBy?: boolean;
};

const PackagingTable = ({ manualSortBy }: PackagingsProps) => {
	// ---- hooks ---- //
	// get current switch mode value ( carbon or water )
	const { indicator } = useApp();

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const { data: isVersion2x } = useCmapsModelization({});

	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('Emballage')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <PackageOpen size={20} color={siteColors.grey700} />,
				props: {
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					className: styles.title,
					onClick: (row: any) => {
						trackEvent('Explore Click Packaging', { PackagingLabel: row.label });
					},
				},
				disableSortBy: true,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover2,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.carbon500} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover2,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.water500} />,
						  }),
					decimal: 2,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Recyclé')} />;
				},
				component: 'BarField',
				accessor: 'recycledValues',
				type: 'percent',
				sortDescFirst: true,
				editable: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Fin de vie')} />;
				},
				component: 'BarField',
				accessor: 'endOfLife',
				type: 'percent',
				sortDescFirst: true,
				editable: true,
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('unites utilisées', {
								products: t('products'),
								products_lowercase: toLowerCase(t('products')),
							})}
							sortable={false}
						/>
					);
				},
				accessor: 'utilisation',
				editable: true,
				sortDescFirst: true,
				type: 'number',
				component: 'Ingredient',
				props: {
					page: 'packagings',
					iconColor: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
				},
				// disableSortBy: true,
			},
		];
	}, [indicator, siteColors.carbon500, siteColors.water500, t, selectedYear, trackEvent]);

	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //

	const [searchQueryParams] = useSearchQueryParams();

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
			...(searchQueryParams as any),
		},
	);

	// ---- fetch data --- //
	const {
		key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindPackagingsTable({
		params: {
			...paramsTable,
			indicator,
			packagingId: null, // to avoid the search params object id // TODO: ?? yes
		},
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;

	return (
		<TableComponent
			skeleton={isTableLoading}
			loading={false}
			fetchData={updateTableParams}
			pageCount={tableResultData?.meta?.last_page || 0}
			noDataMessage={t('packaging-table-empty-data')}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={formatData(tableResultData?.data || [], paramsUrlString, indicator, isVersion2x)}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			manualSortBy={manualSortBy}
			stickyHeader
			stickyHeaderStyles={stickyHeaderStyle}
			transFunc={t}
			onNextClick={() => {
				return trackEvent('Explore Packagings Next Page');
			}}
		/>
	);
};

export default PackagingTable;
