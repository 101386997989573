import dayjs from 'dayjs';
import _ from 'lodash';
import { utils, writeFile } from 'xlsx';

import {
	functionName,
	TYPE_MODELIZATION_PACKAGING,
} from '@carbonmaps/shared/utils/constants';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

//--------------------------------------------------------------------------------------//
//                                      Export                                          //
//--------------------------------------------------------------------------------------//
export type GetParamsExport = {
	ids?: string[];
	facetFilters?: any[];
	supplierIds?: any[];
	input?: string;
	format: string;
	ingredientId?: string;
	companyCode?: string;
	selectedYear?: string;
	exportKey?: string;
	language?: string;
};

const getValueToDisplay = (item: any) => {
	return item && item !== 0 ? item : '';
};

const getValue = (product: any, key: string, isN2 = true) => {
	if (isN2) {
		return product?.tagAdvancedModelization === 'yes' ? product[`${key}N2`] || product[key] || '' : '';
	}

	if (!isN2 && product.tagAdvancedModelization === 'yes') {
		return product['customN1'][key] || product['customN1'][`${key}N1`] || product[`${key}N1`] || '';
	}

	return product[key] || product[`${key}N1`] || '';
};

//todo remove this and adapt in the getValue
const getValueRef = (product: any, key: string, isN2 = true) => {
	if (isN2) {
		return product?.tagAdvancedModelization === 'yes' ? product[`${key}N2`] || product[key] || '' : '';
	}

	if (!isN2 && product.tagAdvancedModelization === 'yes') {
		return product['customN1'][key] || product['customN1'][`${key}N1`] || product[`${key}N1`] || product[key] || '';
	}

	return product[key] || product[`${key}N1`] || '';
};


const getCarbonPortion = (product: any) => {
	if (product.tagAdvancedModelization === 'yes') {
		return ((product['customN1']['gesTotal'] || 0) * product.netWeight) / 1000;
	}

	return product?.carbonImpactPortionN1 || '';
};

const getNotFlagRow = (product: any) => {
	let total = 0;
	['gesTransformation', 'gesPackaging', 'gesTransport', 'gesDistribution', 'gesConsumption'].forEach((key: string) => {
		total = total + product[`${key}Impact`];
	});
	return total;
};

/**
 * exportProductAction
 * @param params
 * @param t
 */
export async function exportProductAction(params: GetParamsExport, t: any) {
	await Parse.Cloud.run(functionName.product.exportProducts, {
		productIds: params?.ids,
		facetFilters: params.facetFilters,
		input: params?.input,
		ingredientId: params?.ingredientId,
		supplierIds: params?.supplierIds,
		selectedYear: params.selectedYear,
		exportKey: params.exportKey,
		format: params.format,
		language: params.language,
	});
}

export async function exportProductAction2(params: GetParamsExport, t: any) {
	const categoriesFieldName = `${params.companyCode ?? ''}Categories`;

	const products: any[] = await Parse.Cloud.run(functionName.product.exportProducts, {
		productIds: params?.ids,
		facetFilters: params.facetFilters,
		input: params?.input,
		ingredientId: params?.ingredientId,
		supplierIds: params?.supplierIds,

		selectedYear: params.selectedYear,
		exportKey: params.exportKey,
	});

	try {
		const facetHeaders = new Set<string>();

		const ROWS = products.map((row) => {
			const rowCategories = (row as any)[categoriesFieldName] ?? {};

			Object.keys(rowCategories).forEach((key) => {
				facetHeaders.add(key);
			});

			return {
				'ID produit fini': row.uid ?? '',
				'Libellé produit fini': row.label ?? '',
				'Gamme produit': row.labelPortfolio ?? '',
				'Référence CMAPS': t(row?.labelCmaps ?? row?.labelPortfolioCmaps ?? '', { product: t('product') }),
				'BDD de référence': row?.bddRefPortfolio ?? row?.bddRef ?? '',

				'Composition totale': row.totalComposition ?? '',

				'Quantité vendue': row.soldItems ?? '',
				'Poids net': row.netWeight ?? '',
				'Poids brut': row.grossWeight ?? '',
				Tonnage: row.tonnage ?? '',

				ingredient_calculation_method:
					t(_.toLower(row.typeModelizationIngredient), {
						recipe_lowercase: t('recipe'),
						recipe: t('recipe'),
					}) ?? '',
				'Méthode de modélisation emballage':
					(TYPE_MODELIZATION_PACKAGING as any)[_.toLower(row.typeModelizationPackaging) ?? ''] ?? '',

				GES_Agricultures_N1: getValue(row, 'gesAgriculture', false),
				GES_Agricultures_N2: getValue(row, 'gesAgriculture'),
				GES_Transformation_N1: getValue(row, 'gesTransformation', false),
				GES_Transformation_N2: getValue(row, 'gesTransformation'),
				GES_Emballage_N1: getValue(row, 'gesPackaging', false),
				GES_Emballage_N2: getValue(row, 'gesPackaging'),
				GES_Transport_N1: getValue(row, 'gesTransport', false),
				GES_Transport_N2: getValue(row, 'gesTransport'),
				GES_Distribution_N1: getValue(row, 'gesDistribution', false),
				GES_Distribution_N2: getValue(row, 'gesDistribution'),
				GES_Consommation_N1: getValue(row, 'gesConsumption', false),
				GES_Consommation_N2: getValue(row, 'gesConsumption'),
				// ! ===============
				// ! ===============
				GES_Total_N1_PAR_KILO: getValue(row, 'gesTotal', false),
				GES_Total_N1_PAR_UNITE: getCarbonPortion(row),
				GES_Total_N1_PAR_PERIODE: row.carboneImpactN1 ?? '',
				GES_Total_N2_PAR_KILO: getValue(row, 'gesTotal'),
				GES_Total_N2_PAR_UNITE: getValueToDisplay(row.carbonImpactPortionN2),
				GES_Total_N2_PAR_PERIODE: getValueToDisplay(row.carboneImpactN2),
				GES_Total_Ref_gamme_N1_PAR_KILO: getValueRef(row, 'gesTotalRef', false),
				GES_Total_FLAG_PAR_PERIODE: row.gesAgricultureImpact,
				GES_Total_Non_FLAG_PAR_PERIODE: getNotFlagRow(row),
				// ! ===============
				// ! ===============
				Ozone_1_Agricultures_N1: getValue(row, 'ozone1Agriculture', false),
				Ozone_1_Agricultures_N2: getValue(row, 'ozone1Agriculture'),
				Ozone_1_Transformation_N1: getValue(row, 'ozone1Transformation', false),
				Ozone_1_Transformation_N2: getValue(row, 'ozone1Transformation'),
				Ozone_1_Emballage_N1: getValue(row, 'ozone1Packaging', false),
				Ozone_1_Emballage_N2: getValue(row, 'ozone1Packaging'),
				Ozone_1_Transport_N1: getValue(row, 'ozone1Transport', false),
				Ozone_1_Transport_N2: getValue(row, 'ozone1Transport'),
				Ozone_1_Distribution_N1: getValue(row, 'ozone1Distribution', false),
				Ozone_1_Distribution_N2: getValue(row, 'ozone1Distribution'),
				Ozone_1_Consommation_N1: getValue(row, 'ozone1Consumption', false),
				Ozone_1_Consommation_N2: getValue(row, 'ozone1Consumption'),
				Ozone_1_Total_N1_PAR_KILO: getValue(row, 'ozone1Total', false),
				Ozone_1_Total_N2_PAR_KILO: getValue(row, 'ozone1Total'),

				Ozone_1_Total_Ref_N1: getValue(row, 'ozone1TotalRef', false),
				// ! ===============
				// ! ===============
				Rayonnements_Agricultures_N1: getValue(row, 'rayonnementsAgriculture', false),
				Rayonnements_Agricultures_N2: getValue(row, 'rayonnementsAgriculture'),
				Rayonnements_Transformation_N1: getValue(row, 'rayonnementsTransformation', false),
				Rayonnements_Transformation_N2: getValue(row, 'rayonnementsTransformation'),
				Rayonnements_Emballage_N1: getValue(row, 'rayonnementsPackaging', false),
				Rayonnements_Emballage_N2: getValue(row, 'rayonnementsPackaging'),
				Rayonnements_Transport_N1: getValue(row, 'rayonnementsTransport', false),
				Rayonnements_Transport_N2: getValue(row, 'rayonnementsTransport'),
				Rayonnements_Distribution_N1: getValue(row, 'rayonnementsDistribution', false),
				Rayonnements_Distribution_N2: getValue(row, 'rayonnementsDistribution'),
				Rayonnements_Consommation_N1: getValue(row, 'rayonnementsConsumption', false),
				Rayonnements_Consommation_N2: getValue(row, 'rayonnementsConsumption'),
				Rayonnements_Total_N1_PAR_KILO: getValue(row, 'rayonnementsTotal', false),
				Rayonnements_Total_N2_PAR_KILO: getValue(row, 'rayonnementsTotal'),

				Rayonnements_Total_Ref_N1: getValue(row, 'rayonnementsTotalRef', false),
				// ! ===============
				// ! ===============
				Ozone_2_Agricultures_N1: getValue(row, 'ozone2Agriculture', false),
				Ozone_2_Agricultures_N2: getValue(row, 'ozone2Agriculture'),
				Ozone_2_Transformation_N1: getValue(row, 'ozone2Transformation', false),
				Ozone_2_Transformation_N2: getValue(row, 'ozone2Transformation'),
				Ozone_2_Emballage_N1: getValue(row, 'ozone2Packaging', false),
				Ozone_2_Emballage_N2: getValue(row, 'ozone2Packaging'),
				Ozone_2_Transport_N1: getValue(row, 'ozone2Transport', false),
				Ozone_2_Transport_N2: getValue(row, 'ozone2Transport'),
				Ozone_2_Distribution_N1: getValue(row, 'ozone2Distribution', false),
				Ozone_2_Distribution_N2: getValue(row, 'ozone2Distribution'),
				Ozone_2_Consommation_N1: getValue(row, 'ozone2Consumption', false),
				Ozone_2_Consommation_N2: getValue(row, 'ozone2Consumption'),
				Ozone_2_Total_N1_PAR_KILO: getValue(row, 'ozone2Total', false),
				Ozone_2_Total_N2_PAR_KILO: getValue(row, 'ozone2Total'),

				Ozone_2_Total_Ref_N1: getValue(row, 'ozone2TotalRef', false),
				// ! ===============
				// ! ===============
				Particules_Agricultures_N1: getValue(row, 'particulesAgriculture', false),
				Particules_Agricultures_N2: getValue(row, 'particulesAgriculture'),
				Particules_Transformation_N1: getValue(row, 'particulesTransformation', false),
				Particules_Transformation_N2: getValue(row, 'particulesTransformation'),
				Particules_Emballage_N1: getValue(row, 'particulesPackaging', false),
				Particules_Emballage_N2: getValue(row, 'particulesPackaging'),
				Particules_Transport_N1: getValue(row, 'particulesTransport', false),
				Particules_Transport_N2: getValue(row, 'particulesTransport'),
				Particules_Distribution_N1: getValue(row, 'particulesDistribution', false),
				Particules_Distribution_N2: getValue(row, 'particulesDistribution'),
				Particules_Consommation_N1: getValue(row, 'particulesConsumption', false),
				Particules_Consommation_N2: getValue(row, 'particulesConsumption'),
				Particules_Total_N1_PAR_KILO: getValue(row, 'particulesTotal', false),
				Particules_Total_N2_PAR_KILO: getValue(row, 'particulesTotal'),

				Particules_Total_Ref_N1: getValue(row, 'particulesTotalRef', false),
				// ! ===============
				// ! ===============
				Toxicite_1_Agricultures_N1: getValue(row, 'toxicite1Agriculture', false),
				Toxicite_1_Agricultures_N2: getValue(row, 'toxicite1Agriculture'),
				Toxicite_1_Transformation_N1: getValue(row, 'toxicite1Transformation', false),
				Toxicite_1_Transformation_N2: getValue(row, 'toxicite1Transformation'),
				Toxicite_1_Emballage_N1: getValue(row, 'toxicite1Packaging', false),
				Toxicite_1_Emballage_N2: getValue(row, 'toxicite1Packaging'),
				Toxicite_1_Transport_N1: getValue(row, 'toxicite1Transport', false),
				Toxicite_1_Transport_N2: getValue(row, 'toxicite1Transport'),
				Toxicite_1_Distribution_N1: getValue(row, 'toxicite1Distribution', false),
				Toxicite_1_Distribution_N2: getValue(row, 'toxicite1Distribution'),
				Toxicite_1_Consommation_N1: getValue(row, 'toxicite1Consumption', false),
				Toxicite_1_Consommation_N2: getValue(row, 'toxicite1Consumption'),
				Toxicite_1_Total_N1_PAR_KILO: getValue(row, 'toxicite1Total', false),
				Toxicite_1_Total_N2_PAR_KILO: getValue(row, 'toxicite1Total'),

				Toxicite_1_Total_Ref_N1: getValue(row, 'toxicite1TotalRef', false),
				// ! ===============
				// ! ===============

				Toxicite_2_Agricultures_N1: getValue(row, 'toxicite2Agriculture', false),
				Toxicite_2_Agricultures_N2: getValue(row, 'toxicite2Agriculture'),
				Toxicite_2_Transformation_N1: getValue(row, 'toxicite2Transformation', false),
				Toxicite_2_Transformation_N2: getValue(row, 'toxicite2Transformation'),
				Toxicite_2_Emballage_N1: getValue(row, 'toxicite2Packaging', false),
				Toxicite_2_Emballage_N2: getValue(row, 'toxicite2Packaging'),
				Toxicite_2_Transport_N1: getValue(row, 'toxicite2Transport', false),
				Toxicite_2_Transport_N2: getValue(row, 'toxicite2Transport'),
				Toxicite_2_Distribution_N1: getValue(row, 'toxicite2Distribution', false),
				Toxicite_2_Distribution_N2: getValue(row, 'toxicite2Distribution'),
				Toxicite_2_Consommation_N1: getValue(row, 'toxicite2Consumption', false),
				Toxicite_2_Consommation_N2: getValue(row, 'toxicite2Consumption'),
				Toxicite_2_Total_N1_PAR_KILO: getValue(row, 'toxicite2Total', false),
				Toxicite_2_Total_N2_PAR_KILO: getValue(row, 'toxicite2Total'),

				Toxicite_2_Total_Ref_N1: getValue(row, 'toxicite2TotalRef', false),
				// ! ===============
				// ! ===============
				Acidification_Agricultures_N1: getValue(row, 'acidificationAgriculture', false),
				Acidification_Agricultures_N2: getValue(row, 'acidificationAgriculture'),
				Acidification_Transformation_N1: getValue(row, 'acidificationTransformation', false),
				Acidification_Transformation_N2: getValue(row, 'acidificationTransformation'),
				Acidification_Emballage_N1: getValue(row, 'acidificationPackaging', false),
				Acidification_Emballage_N2: getValue(row, 'acidificationPackaging'),
				Acidification_Transport_N1: getValue(row, 'acidificationTransport', false),
				Acidification_Transport_N2: getValue(row, 'acidificationTransport'),
				Acidification_Distribution_N1: getValue(row, 'acidificationDistribution', false),
				Acidification_Distribution_N2: getValue(row, 'acidificationDistribution'),
				Acidification_Consommation_N1: getValue(row, 'acidificationConsumption', false),
				Acidification_Consommation_N2: getValue(row, 'acidificationConsumption'),
				Acidification_Total_N1_PAR_KILO: getValue(row, 'acidificationTotal', false),
				Acidification_Total_N2_PAR_KILO: getValue(row, 'acidificationTotal'),

				Acidification_Total_Ref_N1: getValue(row, 'acidificationTotalRef', false),
				// ! ===============
				// ! ===============
				Eutrophisation_1_Agricultures_N1: getValue(row, 'eutrophisation1Agriculture', false),
				Eutrophisation_1_Agricultures_N2: getValue(row, 'eutrophisation1Agriculture'),
				Eutrophisation_1_Transformation_N1: getValue(row, 'eutrophisation1Transformation', false),
				Eutrophisation_1_Transformation_N2: getValue(row, 'eutrophisation1Transformation'),
				Eutrophisation_1_Emballage_N1: getValue(row, 'eutrophisation1Packaging', false),
				Eutrophisation_1_Emballage_N2: getValue(row, 'eutrophisation1Packaging'),
				Eutrophisation_1_Transport_N1: getValue(row, 'eutrophisation1Transport', false),
				Eutrophisation_1_Transport_N2: getValue(row, 'eutrophisation1Transport'),
				Eutrophisation_1_Distribution_N1: getValue(row, 'eutrophisation1Distribution', false),
				Eutrophisation_1_Distribution_N2: getValue(row, 'eutrophisation1Distribution'),
				Eutrophisation_1_Consommation_N1: getValue(row, 'eutrophisation1Consumption', false),
				Eutrophisation_1_Consommation_N2: getValue(row, 'eutrophisation1Consumption'),
				Eutrophisation_1_Total_N1_PAR_KILO: getValue(row, 'eutrophisation1Total', false),
				Eutrophisation_1_Total_N2_PAR_KILO: getValue(row, 'eutrophisation1Total'),

				Eutrophisation_1_Total_Ref_N1: getValue(row, 'eutrophisation1TotalRef', false),
				// ! ===============
				// ! ===============
				Eutrophisation_2_Agricultures_N1: getValue(row, 'eutrophisation2Agriculture', false),
				Eutrophisation_2_Agricultures_N2: getValue(row, 'eutrophisation2Agriculture'),
				Eutrophisation_2_Transformation_N1: getValue(row, 'eutrophisation2Transformation', false),
				Eutrophisation_2_Transformation_N2: getValue(row, 'eutrophisation2Transformation'),
				Eutrophisation_2_Emballage_N1: getValue(row, 'eutrophisation2Packaging', false),
				Eutrophisation_2_Emballage_N2: getValue(row, 'eutrophisation2Packaging'),
				Eutrophisation_2_Transport_N1: getValue(row, 'eutrophisation2Transport', false),
				Eutrophisation_2_Transport_N2: getValue(row, 'eutrophisation2Transport'),
				Eutrophisation_2_Distribution_N1: getValue(row, 'eutrophisation2Distribution', false),
				Eutrophisation_2_Distribution_N2: getValue(row, 'eutrophisation2Distribution'),
				Eutrophisation_2_Consommation_N1: getValue(row, 'eutrophisation2Consumption', false),
				Eutrophisation_2_Consommation_N2: getValue(row, 'eutrophisation2Consumption'),
				Eutrophisation_2_Total_N1_PAR_KILO: getValue(row, 'eutrophisation2Total', false),
				Eutrophisation_2_Total_N2_PAR_KILO: getValue(row, 'eutrophisation2Total'),

				Eutrophisation_2_Total_Ref_N1: getValue(row, 'eutrophisation2TotalRef', false),
				// ! ===============
				// ! ===============
				Eutrophisation_3_Agricultures_N1: getValue(row, 'eutrophisation3Agriculture', false),
				Eutrophisation_3_Agricultures_N2: getValue(row, 'eutrophisation3Agriculture'),
				Eutrophisation_3_Transformation_N1: getValue(row, 'eutrophisation3Transformation', false),
				Eutrophisation_3_Transformation_N2: getValue(row, 'eutrophisation3Transformation'),
				Eutrophisation_3_Emballage_N1: getValue(row, 'eutrophisation3Packaging', false),
				Eutrophisation_3_Emballage_N2: getValue(row, 'eutrophisation3Packaging'),
				Eutrophisation_3_Transport_N1: getValue(row, 'eutrophisation3Transport', false),
				Eutrophisation_3_Transport_N2: getValue(row, 'eutrophisation3Transport'),
				Eutrophisation_3_Distribution_N1: getValue(row, 'eutrophisation3Distribution', false),
				Eutrophisation_3_Distribution_N2: getValue(row, 'eutrophisation3Distribution'),
				Eutrophisation_3_Consommation_N1: getValue(row, 'eutrophisation3Consumption', false),
				Eutrophisation_3_Consommation_N2: getValue(row, 'eutrophisation3Consumption'),
				Eutrophisation_3_Total_N1_PAR_KILO: getValue(row, 'eutrophisation3Total', false),
				Eutrophisation_3_Total_N2_PAR_KILO: getValue(row, 'eutrophisation3Total'),

				Eutrophisation_3_Total_Ref_N1: getValue(row, 'eutrophisation3TotalRef', false),
				// ! ===============
				// ! ===============
				Ecotox_Agricultures_N1: getValue(row, 'ecotoxAgriculture', false),
				Ecotox_Agricultures_N2: getValue(row, 'ecotoxAgriculture'),
				Ecotox_Transformation_N1: getValue(row, 'ecotoxTransformation', false),
				Ecotox_Transformation_N2: getValue(row, 'ecotoxTransformation'),
				Ecotox_Emballage_N1: getValue(row, 'ecotoxPackaging', false),
				Ecotox_Emballage_N2: getValue(row, 'ecotoxPackaging'),
				Ecotox_Transport_N1: getValue(row, 'ecotoxTransport', false),
				Ecotox_Transport_N2: getValue(row, 'ecotoxTransport'),
				Ecotox_Distribution_N1: getValue(row, 'ecotoxDistribution', false),
				Ecotox_Distribution_N2: getValue(row, 'ecotoxDistribution'),
				Ecotox_Consommation_N1: getValue(row, 'ecotoxConsumption', false),
				Ecotox_Consommation_N2: getValue(row, 'ecotoxConsumption'),
				Ecotox_Total_N1_PAR_KILO: getValue(row, 'ecotoxTotal', false),
				Ecotox_Total_N2_PAR_KILO: getValue(row, 'ecotoxTotal'),

				Ecotox_Total_Ref_N1: getValue(row, 'ecotoxTotalRef', false),
				// ! ===============
				// ! ===============
				Land_use_Agricultures_N1: getValue(row, 'landUseAgriculture', false),
				Land_use_Agricultures_N2: getValue(row, 'landUseAgriculture'),
				Land_use_Transformation_N1: getValue(row, 'landUseTransformation', false),
				Land_use_Transformation_N2: getValue(row, 'landUseTransformation'),
				Land_use_Emballage_N1: getValue(row, 'landUsePackaging', false),
				Land_use_Emballage_N2: getValue(row, 'landUsePackaging'),
				Land_use_Transport_N1: getValue(row, 'landUseTransport', false),
				Land_use_Transport_N2: getValue(row, 'landUseTransport'),
				Land_use_Distribution_N1: getValue(row, 'landUseDistribution', false),
				Land_use_Distribution_N2: getValue(row, 'landUseDistribution'),
				Land_use_Consommation_N1: getValue(row, 'landUseConsumption', false),
				Land_use_Consommation_N2: getValue(row, 'landUseConsumption'),
				Land_use_Total_N1_PAR_KILO: getValue(row, 'landUseTotal', false),
				Land_use_Total_N2_PAR_KILO: getValue(row, 'landUseTotal'),

				Land_use_Total_Ref_N1: getValue(row, 'landUseTotalRef', false),
				// ! ===============
				// ! ===============
				Water_use_Agricultures_N1: getValue(row, 'waterUseAgriculture', false),
				Water_use_Agricultures_N2: getValue(row, 'waterUseAgriculture'),
				Water_use_Transformation_N1: getValue(row, 'waterUseTransformation', false),
				Water_use_Transformation_N2: getValue(row, 'waterUseTransformation'),
				Water_use_Emballage_N1: getValue(row, 'waterUsePackaging', false),
				Water_use_Emballage_N2: getValue(row, 'waterUsePackaging'),
				Water_use_Transport_N1: getValue(row, 'waterUseTransport', false),
				Water_use_Transport_N2: getValue(row, 'waterUseTransport'),
				Water_use_Distribution_N1: getValue(row, 'waterUseDistribution', false),
				Water_use_Distribution_N2: getValue(row, 'waterUseDistribution'),
				water_use_Consommation_N1: getValue(row, 'waterUseConsumption', false),
				water_use_Consommation_N2: getValue(row, 'waterUseConsumption'),

				// ! ===============
				Water_use_N1_PAR_KILO: getValue(row, 'waterUseTotal', false),
				Water_use_N1_PAR_UNITE: row.waterImpactPortionN1 ?? '',
				Water_use_N1_PAR_PERIODE: row.waterImpactN1 ?? '',
				Water_use_N2_PAR_KILO: getValue(row, 'waterUseTotal'),
				Water_use_N2_PAR_UNITE: getValueToDisplay(row.waterImpactPortionN2),
				Water_use_N2_PAR_PERIODE: getValueToDisplay(row.waterImpactN2),
				Water_use_Ref_gamme_N1_PAR_KILO: getValue(row, 'waterUseTotalRef'),
				// ! ===============
				Fossil_use_Agricultures_N1: getValue(row, 'fossilUseAgriculture', false),
				Fossil_use_Agricultures_N2: getValue(row, 'fossilUseAgriculture'),
				Fossil_use_Transformation_N1: getValue(row, 'fossilUseTransformation', false),
				Fossil_use_Transformation_N2: getValue(row, 'fossilUseTransformation'),
				Fossil_use_Emballage_N1: getValue(row, 'fossilUsePackaging', false),
				Fossil_use_Emballage_N2: getValue(row, 'fossilUsePackaging'),
				Fossil_use_Transport_N1: getValue(row, 'fossilUseTransport', false),
				Fossil_use_Transport_N2: getValue(row, 'fossilUseTransport'),
				Fossil_use_Distribution_N1: getValue(row, 'fossilUseDistribution', false),
				Fossil_use_Distribution_N2: getValue(row, 'fossilUseDistribution'),
				Fossil_use_Consommation_N1: getValue(row, 'fossilUseConsumption', false),
				Fossil_use_Consommation_N2: getValue(row, 'fossilUseConsumption'),
				Fossil_use_Total_N1_PAR_KILO: getValue(row, 'fossilUseTotal', false),
				Fossil_use_Total_N2_PAR_KILO: getValue(row, 'fossilUseTotal'),

				Fossil_use_Total_Ref_N1: getValue(row, 'fossilUseTotalRef', false),
				// ! ===============
				// ! ===============
				Mineral_use_Agricultures_N1: getValue(row, 'mineralUseAgriculture', false),
				Mineral_use_Agricultures_N2: getValue(row, 'mineralUseAgriculture'),
				Mineral_use_Transformation_N1: getValue(row, 'mineralUseTransformation', false),
				Mineral_use_Transformation_N2: getValue(row, 'mineralUseTransformation'),
				Mineral_use_Emballage_N1: getValue(row, 'mineralUsePackaging', false),
				Mineral_use_Emballage_N2: getValue(row, 'mineralUsePackaging'),
				Mineral_use_Transport_N1: getValue(row, 'mineralUseTransport', false),
				Mineral_use_Transport_N2: getValue(row, 'mineralUseTransport'),
				Mineral_use_Distribution_N1: getValue(row, 'mineralUseDistribution', false),
				Mineral_use_Distribution_N2: getValue(row, 'mineralUseDistribution'),
				Mineral_use_Consommation_N1: getValue(row, 'mineralUseConsumption', false),
				Mineral_use_Consommation_N2: getValue(row, 'mineralUseConsumption'),
				Mineral_use_Total_N1_PAR_KILO: getValue(row, 'mineralUseTotal', false),
				Mineral_use_Total_N2_PAR_KILO: getValue(row, 'mineralUseTotal'),

				Mineral_use_Total_Ref_N1: getValue(row, 'mineralUseTotalRef', false),
				// ! ===============
				// ! ===============
				...rowCategories,
			};
		});
		const columnNames = Object.keys(ROWS[0] || {}).map((key) => {
			return t(key, {
				products: t('products'),
				recipe: t('recipe'),
				recipe_lowercase: t('recipe'),
				ingredient: t('ingredient'),
				an_ingredient: t('an_ingredient'),
				ingredient_lowercase: toLowerCase(t('ingredient')),
				product: t('product'),
				of_product: t('of_product'),
				ingredients: t('ingredients'),
				produits_finis: t('produits_finis'),
			});
		});
		// generate worksheet and workbook
		const worksheet = utils.json_to_sheet(ROWS);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Dates');

		// fix headers
		utils.sheet_add_aoa(worksheet, [columnNames], { origin: 'A1' });

		const companyName = (params?.companyCode || 'Unknown').replaceAll(' ', '_');

		const nameSheet = `${companyName ?? ''}_${(t(/* 'produits_finis' */ 'products') as string | undefined)?.replaceAll(
			' ',
			'_',
		)}_${dayjs(new Date())?.format('DDMMYYYY')}.${params.format}`;
		// create an XLSX file and try to save
		writeFile(workbook, nameSheet, { compression: true });
	} catch (error) {
		console.log(error);
		return Promise.reject(error);
	}
}
