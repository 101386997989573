import React from 'react';

import { useMutation } from '@tanstack/react-query';

import { createUserRowAction } from '../actions/userRow';

type Props = {
	onSuccess?: () => void;
	onError?: (error: any) => void;
}

const useSaveUserMutation = ({ onSuccess, onError }: Props = {}) => {
	const key = ['createUserRow'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: createUserRowAction,
		onSuccess: () => {
			onSuccess?.();
			// reset();
			// queryClient.invalidateQueries({ queryKey: ['users'] });
		},
		onError: (error: any) => {
			onError?.(error);
			// if (error.message) {
			// 	setMessage(error.message);
			// }
		},
	});

	return { result, key };
};

export default useSaveUserMutation;
