import IngredientsList from './ProductIngredientsList';
import TopOverView from './ProductIngredientsTopOverView';

const Ingredients = () => {
	return (
		<>
			<TopOverView />
			<IngredientsList />
		</>
	);
};

export default Ingredients;
