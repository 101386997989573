import React from 'react';

import { css, cx } from '@emotion/css';
import { Dialog, DialogContent, type DialogProps, DialogTitle, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';


import { siteColors } from '../../../../../lib/colors';
import PackagingsFilter from '../../../../packagings/_/PackagingsFilter';

type Props = {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	children: React.ReactNode;
	title: string;
	subTitle: string;
};

const filterContainerStyle = css({
	'& > div': {
		padding: '0!important',
	},
});

const PackagingsModalSelect = ({ open, onClose, children, title, subTitle }: Props) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll="paper"
			PaperProps={{
				sx: {
					borderRadius: 3,
				},
			}}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle id="scroll-dialog-title" component="div" sx={{ padding: '32px', position: 'relative' }}>
				<div css={{ marginBottom: 16 }}>
					<Typography variant="h2" sx={{ color: siteColors.primary }}>
						{title}
					</Typography>
					<Typography>{subTitle}</Typography>
				</div>

				<div className={cx('flexRow alignCenter', filterContainerStyle)}>
					<PackagingsFilter withMore={false} />
				</div>

				<IconButton
					sx={{ position: 'absolute', right: 10, top: 10 }}
					onClick={(e) => {
						onClose?.(e, 'backdropClick');
					}}
				>
					<X size={20} />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers={true}
				sx={{
					paddingTop: 0,
				}}
			>
				{children}
			</DialogContent>
		</Dialog>
	);
};

export default PackagingsModalSelect;
