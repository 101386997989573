import { useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';

import { siteColors } from '../../lib/colors';
import { toPercentages } from '../../utils/num.utils';

import DashboardTooltip from './DashboardTooltip';

const styles = {
	container: css({
		position: 'relative',
		'&:hover .custom-tooltip': { display: 'block !important' },
	}),
};

type HorizontalGraphProps = {
	data: any;
	width?: number;
	height?: number;
	onClick?: (item: any) => void;
	renderToolTip?: (item: any) => React.ReactNode;
};

const HorizontalGraph = ({
	width = 567,
	height = 8,
	data,
	onClick,
	renderToolTip,
}: HorizontalGraphProps) => {
	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();

	const [selectedItem, setSelectedItem] = useState<any>(null);

	const viewBoxWidth = width;
	let startPosition = 0;

	const percentages = useMemo(() => {
		return toPercentages(
			data.map((item: any) => {
				return item?.y || 0;
			}),
		);
	}, [data]);

	const onMouseEnter = (evt: any, data: any) => {
		const CTM = svgRef.current.getScreenCTM();
		const mouseX = (evt.clientX - CTM.e) / CTM.a;
		tooltipRef.current.setAttributeNS(null, 'visibility', 'visible');
		tooltipRef.current.setAttributeNS(null, 'top', 50);
		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`bottom:120%;left: ${mouseX + 6 / CTM.a}px; position: absolute; display:none`,
		);
		setSelectedItem(data);
	};

	return (
		<div className={cx('flexRow width100', styles.container)}>
			<Box
				sx={{
					flex: 1,
					position: 'relative',
					'&:hover .custom-tooltip': { display: 'block !important' },
				}}
				onMouseOut={(e: any) => {
					return setSelectedItem(null);
				}}
				display="flex"
			>
				<svg ref={svgRef} viewBox={`0 0 ${viewBoxWidth} ${height}`} width={'100%'}>
					<defs>
						<filter id="shadow">
							<feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" flood-color="red" />
						</filter>
						<filter id="shadow2">
							<feDropShadow dx="0" dy="0" stdDeviation="0.5" flood-color="green" />
						</filter>
					</defs>
					{data.map((item: any, index: number) => {
						const barWidth = (percentages[index] * viewBoxWidth) / 100;
						const currentStartPosition = startPosition;
						startPosition += barWidth;
						return (
							<rect
								key={index}
								height={height}
								width={barWidth}
								x={currentStartPosition}
								fill={item.color}
								onMouseMove={(event: any) => {
									return onMouseEnter(event, item);
								}}
								style={{
									cursor: 'pointer',
								}}
								strokeWidth={2}
								stroke={siteColors.common.white}
								rx={2}
								onClick={() => {
									return onClick && onClick(item);
								}}
							/>
						);
					})}
				</svg>
				<Box className="custom-tooltip" ref={tooltipRef} style={{ display: 'none', position: 'absolute' }}>
					{renderToolTip
? (
						renderToolTip(selectedItem)
					)
: (
						<DashboardTooltip label={selectedItem?.label} value={selectedItem?.percentValue} />
					)}
				</Box>
			</Box>
		</div>
	);
};

export default HorizontalGraph;
