import { useEffect, useRef } from 'react';

import {
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	CreateLink,
	headingsPlugin,
	linkDialogPlugin,
	linkPlugin,
	listsPlugin,
	ListsToggle,
	tablePlugin,
	thematicBreakPlugin,
	toolbarPlugin,
	UndoRedo,
	type MDXEditorMethods,
} from '@mdxeditor/editor';
import { FormHelperText } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';

import MdxEditor from '../../../../../../components/MdxEditor';
import { useTranslation } from '../../../../../../hooks/useTranslation';

export const CUSTOM_PLUGINS = [
	toolbarPlugin({
		toolbarContents: () => {
			return (
				<>
					<UndoRedo />
					<BoldItalicUnderlineToggles />
					<BlockTypeSelect />
					<CreateLink />
					<ListsToggle options={['bullet', 'number']} />
				</>
			);
		},
	}),
	listsPlugin(),
	// quotePlugin(), // disabled because it's not working properly
	headingsPlugin({ allowedHeadingLevels: [1, 2] }),
	linkPlugin(),
	linkDialogPlugin(),
	tablePlugin(),
	thematicBreakPlugin(),
	// frontmatterPlugin(),
	// codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
	// sandpackPlugin({ sandpackConfig: virtuosoSampleSandpackConfig }),
	// codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS', txt: 'text', tsx: 'TypeScript' } }),
	// directivesPlugin({ directiveDescriptors: [/* YoutubeDirectiveDescriptor, */ AdmonitionDirectiveDescriptor] }),
	// diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
	// markdownShortcutPlugin(),
];

const MessageTranslation = ({
	lng,
	messageForm,
	customMessages,
}: {
	lng: string;
	messageForm: UseFormReturn;
	customMessages: any;
}) => {
	const editorRef = useRef<MDXEditorMethods>(null);
	// const { customMessages } = useQuestionFormStore();
	const { t } = useTranslation();

	useEffect(() => {
		editorRef.current?.setMarkdown((customMessages as any)?.[lng] || '');
	}, [lng, customMessages]);

	return (
		<div>
			<Controller
				name={`content_${lng}`}
				control={messageForm.control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<MdxEditor
								editorRef={editorRef}
								id={`content_${lng}`}
								markdown={field.value}
								onChange={field.onChange}
								error={!!error}
								disabled={field.disabled}
								helperText={
									!!error && (
										<FormHelperText error={!!error} sx={{ px: 2 }}>
											{t(error?.message)}
										</FormHelperText>
									)
								}
								plugins={CUSTOM_PLUGINS}


							/>
						</>
					);
				}}
			/>
		</div>
	);
};

export default MessageTranslation;
