import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { IUser } from '@carbonmaps/shared/types/user.types';

import ResetPasswordForm from './ResetPasswordForm';
import SendResetEmailForm from './SendResetEmailForm';

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const username = searchParams.get('username');
	const token = searchParams.get('token');

	const [user, setUser] = useState<IUser | null>(null);

	// TODO: change using react-query
	const findUser = useCallback(async function findUser(username: string) {
		const foundUser = (await Parse.Cloud.run('findUserByUsername', { username })) as Parse.Object | undefined;
		if (!foundUser) return;

		setUser(foundUser as unknown as IUser);
	}, []);

	useEffect(() => {
		if (username && token) {
			findUser(username);
		}
	}, [findUser, token, username]);

	if (username && token) {
		return user && <ResetPasswordForm user={user} token={token} />;
	}

	return <SendResetEmailForm />;
};

export default ResetPassword;
