import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { type CellContext, type RowData } from '@tanstack/react-table';
import _ from 'lodash';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';

import PercentBar from './PercentBar';

const styles = {
	container: css({
		width: '45%',
	}),
	text1: css({
		marginRight: '8px !important',
	}),
};

const ImpactCell = <TData extends RowData>({ info, value }: { value: number; info: CellContext<TData, any> }) => {
	const sortedRows = info.table.getSortedRowModel().rows;

	const currentIndex = sortedRows.findIndex((e) => {
		return e.index === info.row.index;
	});

	let start = 0;

	for (let index = 0; index < currentIndex; index++) {
		start = start + _.toNumber(_.get(sortedRows[index].original, 'impactPercent'));
	}

	const impactPercent = _.toNumber(_.get(info.row.original, 'impactPercent'));

	return (
		<div className="flexRow alignCenter">
			<Typography color={siteColors.primary} className={cx('number textNoWrap', styles.text1)}>
				{displayValue(impactPercent, undefined, 1)} %
			</Typography>

			<div className={cx('', styles.container)}>
				<PercentBar percentValue={impactPercent} start={start} />
			</div>
		</div>
	);
};

export default ImpactCell;
