import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';


const InvalidLink = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Box px={0} py={2} maxWidth={'386px'} minHeight={'300px'} /*  display="flex" alignItems="center" */>
			<Typography marginTop="32px" marginBottom="16px">
			{t('Lien expiré veuillez relancer')}
			</Typography>
			<Box className="flexColumn gap16">
			<Link
						// to='#'
						to="/changer-mot-de-passe"
						css={{
							textDecoration: 'none',
							color: theme.palette.primary.main,
							fontWeight: 600,
						}}
					>
						{t('password-form-button')}
					</Link>
					<Link
						// to='#'
						to="/login"
						css={{
							textDecoration: 'none',
							color: theme.palette.primary.main,
							fontWeight: 600,
						}}
					>
						{t('Se connecter')}
					</Link>
					</Box>
		</Box>
	);
};

export default InvalidLink;
