import { css, cx } from '@emotion/css';

import { useCmapsModelization } from '../../packagings.hooks';

import Facets from './Facets';
import EndOfLifeCard from './kpi/EndOfLifeCard';
import KpiWidget from './kpi/KpiWidget';
import MaterialCard from './kpi/MaterialCard';
import PackagingLifeChart from './PackagingLifeChart';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	container: css({
		paddingBottom: 85,
		width: CONTENT_WIDTH,
		maxWidth: CONTENT_MAX_WIDTH,
		margin: 'auto',
	}),
};

const Analyze = () => {
	const { data: isModelization2x } = useCmapsModelization({});

	return (
		<div className={cx('flexColumn width100 gap32', styles.container)}>
			<KpiWidget />
			<div className="width100">
				<PackagingLifeChart isModelizationN2={isModelization2x} />
			</div>
			{isModelization2x
? (
				<div className="width100 flexRow gap32 nowrap stretch">
					<EndOfLifeCard />
					<MaterialCard />
				</div>
			)
: null}
			<Facets />
		</div>
	);
};

export default Analyze;
