import { ReactComponent as SenderChatIcon } from '@carbonmaps/media/icons/senderChat.svg';
import { css, cx } from '@emotion/css';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';
import Button from '../v3/Button';

const styles = {
	title: css({
		color: siteColors.blackButton,
	}),
	blockContent1: css({
		width: '273px',
		textAlign: 'center',
	}),
	blockContent2: css({
		a: {
			textDecoration: 'none',
			color: `${siteColors.primary} !important`,
		},
	}),
	button: css({
		border: `1px solid ${siteColors.grey5} !important`,
		background: 'var(--Grey-0, #FFF)',
		boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.03)',
		color: `${siteColors.grey9} !important`,
	}),
};

type SuccessContentProps = {
	onClose: () => void;
	email: string;
};

const SuccessContent = ({ onClose, email }: SuccessContentProps) => {
	const { t } = useTranslation();
	return (
		<div className="flexCenter width100 gap20">
			<img src={'/iconChat.png'} width={48} alt="#" />
			<Typography variant="h3" className={styles.title}>
				{t('We will reach out soon!')}
			</Typography>
			<Typography className={cx('grey9', styles.blockContent1)}>
				{t('Thank you for your message. It has been received by our support team.')}
			</Typography>
			<div
				dangerouslySetInnerHTML={{
					__html: t('You will receive an answer at', { email }),
				}}
				className={cx('textAlignCenter grey9', styles.blockContent2)}
			/>
			<div>
				<Button label={t('close')} handleClick={onClose} className={styles.button} />
			</div>
		</div>
	);
};

export default SuccessContent;
