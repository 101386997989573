import dayjs from 'dayjs';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import 'dayjs/locale/fr';
import MetaDataCard from '../../../components/card/MetaDataCard';

dayjs.locale('fr');

const MetaDataBlock = () => {
	const params = useParams();

	const { data } = useGetProductSheetTimePeriod({
		objectId: _.toString(params.productId),
	});
	return <MetaDataCard importedOn={data?.product?.createdAt} updatedAt={data?.product?.updatedAt} />;
};

export default MetaDataBlock;
