import { css, cx } from '@emotion/css';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';

import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../../lib/colors';

import { getMinAndMaxDate, handleOneSeries } from './graph.utils';

type Props = {
	seriesData: [number, number][][];
};

const styles = {
	dot: css({
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		marginRight: '8px',
	}),
	tooltipContainer: css({
		zIndex: 50000,
		padding: 8,
		gap: 16,
	}),
};

const StatusTrackingGraph = ({ seriesData: inputSeriesData }: Props) => {
	const { t, i18n } = useTranslation();
	const locale = getIsoCountryCode(i18n.language);

	const notesMap = [t('surveys-sent'), t('registrations-done'), t('surveys-completed')];

	const tooltipFormatter =  function (this: any) {
		const previousPointYIndex = this.point.index - 1;
		const previousPointY = this.series.yData[previousPointYIndex];

		const value = this.y - (previousPointY || 0);

		return ReactDOMServer.renderToString(
			<div className={cx('flexColumn', styles.tooltipContainer)}>
				<div className="flexColumn">
					<span style={{ fontSize: '14px', fontWeight: 600, color: siteColors.grey900 }}>
						{dayjs(this.x).locale(locale).format('DD MMMM YYYY')}
					</span>
					<span style={{ fontSize: '14px', color: siteColors.grey900 }}>
						{_.isNaN(value) ? 0 : value} {notesMap[this.series.index]}
					</span>
				</div>
			</div>,
		);
	};

	const seriesData = _.map(inputSeriesData, (s: any) => {
		return handleOneSeries(s);
	});

	const minMax = getMinAndMaxDate(...seriesData);

	const _seriesData = seriesData.map((s, index) => {
		if (_.isEmpty(s)) {
			return [];
		}

		const _s = [...s];

		if (index !== minMax.minIndex) {
			_s.unshift([minMax.minDate as number, 0]);
		}

		if (index !== minMax.maxIndex) {
			_s.push([minMax.maxDate as number, _.last(_s)?.[1] as number]);
		}

		return _s;
	});

	const marker = {
		enabled: false,
		symbol: 'circle', // Points en forme de petit rond
		radius: 4, // Taille du cercle
		lineWidth: 2, // Épaisseur du contour du cercle
		fillColor: 'transparent', // Cercle vide
		lineColor: siteColors.primary, // Couleur du contour rouge
	};

	const commonOptionsImpact = {
		name: 'Impact',
		type: 'area',
		// type: 'spline',
		lineWidth: 2,
		dashStyle: 'Solid',
		marker,
		lineColor: siteColors.primary,
		fillColor: siteColors.primary10,
		opacity: 0.9, // Set the opacity of the area fill
		step: 'left',
	};

	const options = {
		chart: {
			width: null,
			reflow: true, // Active le redimensionnement automatique
		},
		title: {
			text: undefined, // Pas de titre au centre
		},
		xAxis: {
			labels: {
				formatter: function () {
					return dayjs((this as any).value as never).locale(locale).format('DD MMM');
				},
				style: {
					color: siteColors.grey800,
				},
			},
			lineColor: siteColors.grey500,
			min: minMax.minDate,
			minPadding: 0,
			maxPadding: 0,
		},
		yAxis: {
			title: {
				text: null,
			},
			min: 0,
		},
		legend: {
			align: 'right',
			verticalAlign: 'top',
			layout: 'vertical',
			x: 0,
			y: 0,
			floating: true,
			enabled: false,
		},
		series: [
			{
				...commonOptionsImpact,
				data: _seriesData[0],
				lineColor: siteColors.orange500,
				fillColor: siteColors.orange10,
				marker: {
					...marker,
					lineColor: siteColors.orange500,
					fillColor: siteColors.orange10,
				},
			},
			{
				...commonOptionsImpact,
				data: _seriesData[1],
			},
			{
				...commonOptionsImpact,
				data: _seriesData[2],
				lineColor: siteColors.green500,
				fillColor: siteColors.green10,
				marker: {
					...marker,
					lineColor: siteColors.green500,
					fillColor: siteColors.green10,
				},
			},
		],
		tooltip: {
			useHTML: true,
			backgroundColor: siteColors.common.white,
			formatter: tooltipFormatter,
			style: {
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
			},
			borderRadius: 8,
			borderWidth: 0,
			outside: true,
		},
		credits: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 200,
					},
					chartOptions: {
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							layout: 'horizontal',
						},
					},
				},
			],
		},
	};

	return (
		<div style={{ maxWidth: '100%', width: '100%', height: '100%', overflow: 'visible' }}>
			<div className="flexRow spaceBetween" css={{ marginBottom: 16 }}>
				<div css={{ fontWeight: 500 }}>{t('campaign-progress')}</div>
				<div className="flexRow justifyEnd grey800" css={{ gap: 16 }}>
					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.orange500 }}></div>
						{t('survey-sent')}
					</div>

					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.primary }}></div>
						{t('registration-done')}
					</div>

					<div className="flexRow alignCenter">
						<div className={cx('', styles.dot)} css={{ backgroundColor: siteColors.green500 }}></div>
						{t('survey-completed')}
					</div>
				</div>
			</div>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				containerProps={{
					style: {
						width: '100%',
						height: '200px',
					},
				}}
			/>
		</div>
	);
};

export default StatusTrackingGraph;
