
import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';

import Dialog from '../../components/dialogs/Dialog';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';



const styles = {
	link: css({
		textDecoration: 'none',
			color: `${siteColors.primary} !important`,
	}),
};


export type JobSuccessModalProps = {
	open?: boolean;
	onConfirm?:() => void;
	toggle?: () => void;

};

const JobSuccessModal = ({
	open = false,
	onConfirm,
	toggle,

}: JobSuccessModalProps) => {


	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	return (
		<Dialog
		title={t('Confirmation')}
			open={open}
			toggle={toggle}
			onPrimaryButtonAction={() => {
				onConfirm && onConfirm();
				toggle && toggle();
			}}
			primaryButtonText={t('valid-job-button')}
			secondaryButtonText={t('cancel-button')}
		>
				<Typography>{t('Souhaitez vous confirmer l’envoi de l’export à l’adresse email')} <a className={styles.link}>{authData?.user?.email}</a> ?</Typography>

		</Dialog>
	);
};

export default JobSuccessModal;
