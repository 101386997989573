import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Cloud, Cloudy, Droplet, Droplets } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetOneIngredientSegmentSheet } from '@carbonmaps/ui/hooks/useGetIngredientSheet';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import CategoriesGraph from '../../../components/charts/CategoriesGraph';
import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const indicatorConfigItems = new Map();

indicatorConfigItems.set(CARBON_INDICATOR, {
	impactTitle: WORDING_TERMS.IMPACT,
	impactIcon: Cloudy,
	impactUnity: 'tCO₂',
	intensityTitle: WORDING_TERMS.INTENSITY,
	intensityIcon: Cloud,
	intensityUnity: 'kgCO₂ / kg',
});
indicatorConfigItems.set(WATER_INDICATOR, {
	impactTitle: WORDING_TERMS.IMPACT,
	impactIcon: Droplets,
	impactUnity: 'M³',
	intensityTitle: WORDING_TERMS.INTENSITY,
	intensityIcon: Droplet,
	intensityUnity: 'M³ / KG',
});

const popoverIndicatorConfigItems = new Map();
popoverIndicatorConfigItems.set(CARBON_INDICATOR, {
	value: 'carbonImpact',
	label: 'Carbone',
	unit: (
		<Unit
			measure="carbonImpactTon"
			fontWeight={500}
			fontSize="12px"
			color={siteColors.grey700}
			lineHeight={1}
			// sx={{ textTransform: 'uppercase' }}
		/>
	),
	popover: [
		{
			label: WORDING_TERMS.IMPACT,
			key: 'carbonImpact',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 0,
		},
		{
			label: 'Volume',
			key: 'tonnage',
			unit: <span>Tonnes</span>,
			precision: 0,
		},
	],
});

popoverIndicatorConfigItems.set(WATER_INDICATOR, {
	value: 'waterImpact',
	label: 'Eau',
	unit: (
		<Unit
			measure="waterImpact"
			fontWeight={500}
			fontSize="12px"
			color={siteColors.grey700}
			lineHeight={1}
			// sx={{ textTransform: 'uppercase' }}
		/>
	),
	popover: [
		{
			label: WORDING_TERMS.IMPACT,
			key: 'waterImpact',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 2,
		},
		{
			label: 'Volume',
			key: 'tonnage',
			unit: (
				<Unit
					measure="massTon"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 0,
		},
	],
});

const IngredientSegmentBlock = () => {
	const { indicator, setIndicator } = useApp();
	const theme = useTheme();
	const params = useParams();

	const { t } = useTranslation();

	const [datas, setDatas] = useState<any>({});
	const { data, isLoading } = useGetOneIngredientSegmentSheet({
		objectId: params.ingredientId!,
	});

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator]);

	useEffect(() => {
		if (data?.dataSegment && !isLoading) {
			setDatas(data?.dataSegment);
		} else {
			setDatas([]);
		}
	}, [data, indicator]);

	return (
		<Box sx={{ padding: 0, marginBottom: pxToRem(40) }}>
			<Box className="flexRow alignCenter" marginBottom={'16px'} sx={{ gap: '8px' }}>
				<Typography variant="h3" color={theme.palette.common.black}>
					{t('ingredient-repartition-graph-title')} {popoverIndicatorConfigItems.get(indicator).unit}
				</Typography>
			</Box>

			{/* <Card
				title="Répartition dans mes categories"
				icon={<BarChart2 color={siteColors.grey700} size={20} />}
				sx={{ padding: 0, marginBottom: pxToRem(40), flexWrap: 'nowrap' }}
				withBorder={false}
				center={false}
			> */}
			<CategoriesGraph
				data={datas}
				color={indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500}
				facets={datas}
				isLoading={isLoading}
				title=""
				showToggle={false}
				defaultIndicator={popoverIndicatorConfigItems.get(indicator)}
			/>
			{/* </Card> */}
		</Box>
	);
};

export default IngredientSegmentBlock;
