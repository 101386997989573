import { END_OF_LIFE_COLORS } from '@carbonmaps/shared/utils/constants';
import { siteColors } from '../lib/colors';

export const getRecycledArray = (value: number) => {
	return [
		{
			label: 'recycled',
			value,
			color: siteColors.green500,
		},
		{
			label: 'other-recycled',
			value: 100 - value,
			color: siteColors.grey500,
		},
	];
};

export const manageEndOfLife = (items: Array<any>) => {
	const typesOrderedByImportance = ['Recyclable', 'Recyclabilité', 'Décharge', 'Incinération'];
	return items?.map((item: any, index: number) => {
		return {
			...item,
			value: item?.composition,
			color: END_OF_LIFE_COLORS[item?.type as any],
		};
	}).sort((a, b) => {
		return typesOrderedByImportance.indexOf(a.type) - typesOrderedByImportance.indexOf(b.type);
	});
};
