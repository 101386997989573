import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getTotalImpactByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { siteColors } from '../../lib/colors';
import Skeleton from '../Skeleton';
import Typography from '../Typography';
import Unit from '../units/Unit';

const styles = {
	container: css({
		minHeight: 42,
	}),
	value: css({
		color: 'var(--color) !important',
		fontSize: '32px !important',
		lineHeight: '21px !important',
	}),
	skeleton: css({
		height: '42px !important',
		width: '100% !important',
	} as any),

	contentContainer: css({
		alignItems: 'baseline !important',
	}),
};

type TotalImpactProps = {
	withUnit?: boolean;
	category?: boolean;
	value?: number | null;
	isLoading?: boolean;
};

const TotalImpact = ({ value = null, isLoading = false, withUnit = false, category = false }: TotalImpactProps) => {
	const { indicator } = useApp();
	return isLoading ? (
		<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
	) : value ? (
		<div className={cx('flexRow alignCenter gap16 nowrap', styles.container)}>
			<div className={cx(styles.contentContainer, 'flexRow gap8')}>
				<Typography
					className={cx('number', styles.value)}
					style={{ ['--color' as any]: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}
				>

					{formatNumber(value, undefined, 0)}
				</Typography>
				{withUnit ? <Unit measure={'carbonImpactTon'} className={'grey8'} /> : null}
			</div>
		</div>
	) : null;
};

export default TotalImpact;
