import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import CardContent from '../../../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../../../components/dashboard/v2/CardTitle';
import DashboardCard from '../../../../../../components/dashboard/v2/DashboardCard';
import PolarChart from '../../../../../../components/PolarChart';
import Skeleton from '../../../../../../components/Skeleton';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import {
	useFindGeneralRSERadarChartData,
	useFindQuestionFormsTabs,
	useRseQFormTabQueryParam,
} from '../../../../../supplier/general/tabs/rse/hooks/rse.hooks';
import { useFindOneSupplierRSERadarChartData, useSupplierSetup } from '../../../hooks/supplier.hooks';

type Props = {
	data?: any;
	indicator?: 'carbon' | 'water';
};

const styles = {
	cardContainer: css({
		// padding: '24px!important',
		// background: 'green'
	}),
	content: css({
		// minHeight: 321,
		marginBottom: pxToRem(40),
		// background: 'red',
		padding: '24px!important',
	}),
	content2: css({
		// minHeight: 321,
		marginBottom: pxToRem(40),
		// background: 'red',
		padding: '24px!important',
		// background: 'red',
		alignItems: 'center!important',
		justifyContent: 'center!important',
	}),
	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '200px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
	// content: css({
	// 	padding: 0,
	// 	// background: 'yellow'
	// }),
};

const SupplyRsePerformance = ({ data = [] }: Props) => {
	const params = useParams();
	const { t, i18n } = useTranslation();

	const [qFormId, setQFormId] = useRseQFormTabQueryParam();

	const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();
	useEffect(() => {
		// if (tabsData) {
		// 	if (qFormId && tabsData[qFormId]) {
		// 		setQFormId(qFormId);
		// 		return;
		// 	}
		// 	const qFormIdZero = _.get(_.entries(tabsData), '0.0');
		// 	setQFormId(qFormIdZero);
		// }
	}, [tabsData]);

	const [questionFormId] = useRseQFormTabQueryParam();

	// ---- fetch section data ---- //
	const {
		// result: { data, isLoading },
		data: dataAvg,
		// isLoading: isLoadingGeneralData,
		// isError,
		// error,
	} = useFindGeneralRSERadarChartData({
		questionFormId: _.toString(questionFormId),
	});

	const {
		data: dataDetails,
		isLoading: isLoadingDataPerformanceRse,
		// isError,
		// error,
	} = useFindOneSupplierRSERadarChartData({
		supplierId: params.supplierId || '',
		questionFormId: _.toString(qFormId!),
	});
	const locale = getIsoCountryCode(i18n.language);

	const {
		// supplierObject: supplier,
		// dataCategories: segmentationData,
		// productsSupplier,
		handleTabChange: onTabChange,
		// isLoading,
	} = useSupplierSetup();

	// Step 1: Create a map from code to index in dataAvg using a for loop
	const codeOrderMap: any = {};

	for (let i = 0; i < (dataAvg || []).length; i++) {
		codeOrderMap[dataAvg[i].code] = i;
	}

	// If you need to sort dataAvg too, but it looks like it's already sorted as needed.
	// const sortedDataAvg = _.sortBy(dataAvg, (item) => codeOrderMap[item.code]);

	// Step 2: Sort data based on the order in dataAvg using the codeOrderMap
	const sortedDataDetailsArray = _.sortBy(dataDetails?.sections || [], (item) => {
		return codeOrderMap[item.code];
	});

	// return <div>aaaaaaaaaa</div>;

	// if (isError && _.isError(error) && error.message === 'Object not found') {
	// 	return null;
	// }

	if (!_.isNil(dataAvg) && _.isEmpty(dataAvg)) {
		return null;
	}

	if (isLoadingTabsData || isLoadingDataPerformanceRse) {
		return (
			<DashboardCard title={<CardTitle title={t('performance-rse-per-category-card-title')} />} className="flex1">
				<CardContent className={cx(styles.content2, 'flex1')} loading={true}>
					<Skeleton width={400} height={48} sx={{ mb: '24px' }} />
				</CardContent>
			</DashboardCard>
		);
	}

	return (
		<DashboardCard title={<CardTitle title={t('performance-rse-per-category-card-title')} />} className="flex1">
			<CardContent
				button={
					dataDetails?.sections?.length ? (
						<BButton
							variant="secondary"
							label={t('see-performance-rse')}
							onClick={() => {
								onTabChange && onTabChange('_' as any, 'rse-performance');
							}}
							addStyles={{ width: '100%' }}
						/>
					) : null
				}
				className={cx(styles.content2, 'flex1')}
				loading={isLoadingDataPerformanceRse}
			>
				{/* <QuestionFormTabs /> */}
				{dataDetails?.sections?.length ? (
					<PolarChart
						//  data={dataPerformanceRse?.sections as any}
						data={formatData(sortedDataDetailsArray /* dataPerformanceRse?.sections || [], */, locale) as any}
						withLegend={false}
						withAvg={false}
					/>
				) : (
					<Typography padding={0}>{t('supplier-no-questionnaire-info')}</Typography>
				)}
			</CardContent>
		</DashboardCard>
	);
};

export default SupplyRsePerformance;

const formatData = (data: any, locale: any) => {
	if (!data) return [];

	// const maxValue = (_.maxBy(data, 'value') as any)?.value;

	const iData = data.map((item: any) => {
		let label = item.labelFr;

		if (locale === 'en') {
			label = item.labelEn;
		}

		return {
			label,
			// value: (item.value / maxValue) * 100 //pour avoir un percent
			value: item.value,
		};
	});
	// .filter((item: any) => {
	// 	return item.label;
	// });

	return iData;
	// .filter((item: any) => item.value > 0);
};
