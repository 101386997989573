import { useQuery } from '@tanstack/react-query';
import { type SortingState } from '@tanstack/react-table';
import _ from 'lodash';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';

import { functionName } from '@carbonmaps/shared/utils/constants';

export const useRseQFormTabQueryParam = () => {
	return useQueryParam('rse:questionFormId', StringParam);
};

export const useRseQFormMasterCategoryParam = () => {
	return useQueryParam('rse:questionFormMasterCategory', JsonParam);
};

export const useFindQuestionFormsTabs = () => {
	return useQuery({
		queryKey: ['FindQuestionFormsTabs'] as const,
		queryFn: async () => {
			try {
				const results = await Parse.Cloud.run(functionName.questions.findQuestionFormsTabs);
				return results;
			} catch (error) {

				return Promise.reject(error);
			}
		},
	});
};

export const useFindRseTableData = ({ questionFormId, sorting, page, pageSize }: { questionFormId: string; sorting: SortingState; page: number; pageSize: number; }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined;

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId', 'deId']);
	}

	return useQuery({
		queryKey: ['FindRSETableData', { questionFormId, masterCategory, sorting, page, pageSize }] as const,
		queryFn: async ({ queryKey }) => {
			const { questionFormId, masterCategory, sorting, page, pageSize } = queryKey[1];

			try {
				const result = await Parse.Cloud.run(functionName.questions.findRseTableData, { questionFormId, masterCategory, sorting, page, pageSize });

				return result;
			} catch (error) {

				return Promise.reject(error);
			}
		},
	});
};

export const useFindGeneralRSERadarChartData = ({ questionFormId }: { questionFormId: string }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined;

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId', 'deId']);
	}

	return useQuery({
		queryKey: ['FindGeneralRSERadarChartData', { questionFormId, masterCategory }] as const,
		queryFn: async (ctx) => {
			const { questionFormId, masterCategory } = ctx.queryKey[1];

			try {
				const result = await Parse.Cloud.run(functionName.suppliers.rse.findGeneralRSERadarChartData, { questionFormId, masterCategory });
				return result;
			} catch (error) {

				return Promise.reject(error);
			}
		},
	});
};

export const useFindGeneralRSESuppliersRepartitionChart = ({ questionFormId }: { questionFormId: string }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined;

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId', 'deId']);
	}

	const key = ['FindGeneralRSESuppliersRepartitionChart', { questionFormId, masterCategory }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const { questionFormId, masterCategory } = context.queryKey[1];
			return Parse.Cloud.run(functionName.suppliers.rse.findGeneralRSESuppliersRepartitionChart, { questionFormId, masterCategory });
		},
	});

	return { result, key };
};
