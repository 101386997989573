import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { styled, type CSSObject, type Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MUILink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Link } from 'react-router-dom';

import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';
import { SIDEBAR_WIDTH } from '../utils/constants';

import Lock from './Lock';
import Popover from './Popover';

type Props = {
	header: ReactNode;
	content: ReactNode;
	footer: ReactNode;
	open: boolean;
};

const sideBarNavStyles = {
	drawerContainer: css({
		width: SIDEBAR_WIDTH,
		'& > .MuiPaper-root': {
			width: SIDEBAR_WIDTH,
			borderRight: cn('1px solid', siteColors.grey400),
			// backgroundColor: siteColors.grey100,
			display: 'flex',
			flexDirection: 'column',
			overflowX: 'hidden',
		},
		'& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
	}),

	content: css({
		width: '6px',
		height: '100%',
		zIndex: 2,
	}),
};

const openedMixin = (theme: Theme): CSSObject => {
	return {
		width: SIDEBAR_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	};
};

const closedMixin = (theme: Theme): CSSObject => {
	return {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 0,
		[theme.breakpoints.up('sm')]: {
			width: 0,
		},
	};
};

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => {
		return prop !== 'open';
	},
})(({ theme, open }) => {
	return {
		width: SIDEBAR_WIDTH,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	};
});

const SidebarNav = ({ header, content, footer, open }: Props) => {
	return (
		<Drawer variant="permanent" open={open} className={sideBarNavStyles.drawerContainer}>
			{/* <div className={cx('fixed bgMain', sideBarNavStyles.content)} /> */}

			{/* Header */}
			<div>{header}</div>

			{/* main content */}
			<div
				css={{
					flexGrow: 1,
					'& nav.MuiList-root:first-of-type': {
						paddingTop: 0,
					},
				}}
			>
				{content}
			</div>

			{/* Footer */}
			<div>{footer}</div>
		</Drawer>
	);
};

type SidebarNavListProps = {
	children: ReactNode;
	title: string;
};

const sidebarNavListStyles = {
	container: css({
		marginBottom: 4,
	}),
	subheaderContainer: css({
		fontWeight: 600,
		textTransform: 'uppercase !important',
		paddingLeft: '35px !important',
		lineHeight: '2rem !important',
		fontFamily: 'Inter, Helvetica, Arial,sans-serif !important',
		fontSize: '12px !importanta',
	} as any),
};

export const SidebarNavList = ({ children, title }: SidebarNavListProps) => {
	return (
		<List
			className={sidebarNavListStyles.container}
			subheader={
				<ListSubheader
					component="div"
					disableSticky
					className={cx('mainColor', sidebarNavListStyles.subheaderContainer)}
				>
					{title}
				</ListSubheader>
			}
		>
			{children}
		</List>
	);
};

type ItemButtonProps = {
	icon: ReactNode;
	text: string;
	link: string;
	isActive: boolean;
	isLocked: boolean;
	// external?: boolean;
	// comingSoon?: boolean;
	// setFillPathOnHover?: boolean;
	// onClick?: () => void;
	// collapsible?: boolean;
	// open?: boolean;
	// isEmpty?: boolean;
	// isContributor?: boolean;
	// code?: string;
};

const itemButtonStyles = {
	item: css({
		padding: '8px 16px 8px 16px !important',
		borderRadius: '.5rem !important',
		transition: 'unset !important',
		marginBottom: '0px !important',
		fontSize: '14px !important',
		marginLeft: '8px !important',
		marginRight: '8px !important',

		'&:hover': {
			background: cn(siteColors.primary10, '!important'),
			'& svg': {
				color: siteColors.primary,
			},
			'& .MuiListItemText-root > .MuiTypography-root': {
				color: siteColors.primary,
			},
		},

		'&:hover .sidebar-icon': {
			path: {
				fill: siteColors.primary,
			},
		},
		'&:hover .sidebar-icon-lucide': {
			stroke: siteColors.primary,
			'& path': {
				stroke: siteColors.primary,
			},
		},
		'& [data-active="true"] .sidebar-icon': {
			path: {
				fill: siteColors.primary,
			},
		},
		'& [data-active="true"] .sidebar-icon-lucide': {
			stroke: siteColors.primary,
			'& path': {
				stroke: siteColors.primary,
			},
		},
	}),

	active: css({
		background: cn(siteColors.primary10, '!important'),
		'& svg': {
			color: siteColors.primary,
		},
		'& .MuiListItemText-root > .MuiTypography-root': {
			color: siteColors.primary,
		},
	}),
	inActive: css({
		'& .MuiListItemText-root > .MuiTypography-root': {
			color: siteColors.text,
		},
		'& svg': {
			color: siteColors.grey700,
		},
	}),

	itemTextContainer: css({
		marginLeft: '8px !important',
		marginTop: '0 !important',
		marginBottom: '0 !important',
		'& > .MuiTypography-root': {
			fontWeight: 500,
			lineHeight: 'normal',
		},
	}),

	popoverContainer: css({
		width: 220,
		marginLeft: 20,
		marginTop: 40,
		marginBottom: 32,
		cursor: 'pointer',
		borderRadius: 6,
		padding: '16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),

	locked: css({ cursor: 'not-allowed' }),
};
const headerStyles = {
	triggerContainer: css({
		// width: 210,
		// marginLeft: 35,
		// width: 220,
		marginLeft: 10,
		marginRight: 10,
		marginTop: 10,
		marginBottom: 4,
		cursor: 'pointer',

		borderRadius: 6,
		// background: 'red',
		// padding: '2px',
		padding: '8px',
		// marginLeft: '-16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
};

export const ItemButton = ({ icon, text, link, isActive = false, isLocked = false }: ItemButtonProps) => {
	const { t } = useTranslation();

	const renderButton = (
		<ListItemButton
			disableRipple
			{...(link &&
				!isLocked && {
					LinkComponent: Link,
					to: link,
				})}
			className={cx(
				itemButtonStyles.item,
				isLocked ? itemButtonStyles.locked : '',
				isActive ? itemButtonStyles.active : itemButtonStyles.inActive,
			)}
		>
			<ListItemIcon data-active={isActive} sx={{ minWidth: 'unset' }}>
				{icon}
			</ListItemIcon>
			<ListItemText
				primary={<div className="flexRow alignCenter nowrap">{text}</div>}
				className={itemButtonStyles.itemTextContainer}
			/>

			{isLocked ? <Lock /> : null}
		</ListItemButton>
	);

	return isLocked
? (
		<Popover
			disableScrollLock
			anchorOrigin={{
				horizontal: 'left',
				vertical: 'bottom',
			}}
			trigger={renderButton}
			renderContent={({ close }) => {
				return (
					<MUILink
						component={Link}
						to="mailto:support@carbonmaps.io"
						display="block"
						className={headerStyles.triggerContainer}
					>
						{t('active-module-text')}
					</MUILink>
				);
			}}
		/>
	)
: (
		renderButton
	);
};

export default SidebarNav;
