import GenericEmptyBlock from '../../../../../../components/GenericEmptyBlock';
import { useTranslation } from '../../../../../../hooks/useTranslation';

const EmptyQFormsList = () => {
	const { t } = useTranslation();

	return <GenericEmptyBlock text1={t('empty-qforms-list-text-1')} text2={t('empty-qforms-list-text-2')} />;
};

export default EmptyQFormsList;
