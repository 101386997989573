import ReactPDF, { Text } from '@react-pdf/renderer';
import { type TFunction } from 'i18next';
import _ from 'lodash';

import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { getUserFullName } from '@carbonmaps/shared/utils/utils';

import { siteColors } from '../../../../lib/colors';
import QuoteModel from '../../../../models/Quote.model';

export type UseQuoteReturn = ReturnType<typeof useQuote>;

const lifeCycleConfig = {
	gesAgriculture: {
		translationKey: 'agriculture',
		icon: '/pdf-lifecycle/wheat.png',
	},
	gesTransformation: {
		translationKey: 'transformation',
		icon: '/pdf-lifecycle/factory.png',
	},
	gesPackaging: {
		translationKey: 'packaging',
		icon: '/pdf-lifecycle/package-open.png',
	},
	gesTransport: {
		translationKey: 'transport',
		icon: '/pdf-lifecycle/truck.png',
	},
	gesDistribution: {
		translationKey: 'distribution',
		icon: '/pdf-lifecycle/store.png',
	},
	gesConsumption: {
		translationKey: 'consumption',
		icon: '/pdf-lifecycle/utensils.png',
	},
};

export const useQuote = ({
	indicator,
	t,
	form,
	quote,
	preferredFacet,
	facetsConfig,
	companyCode,
	languageCode = 'fr',
	translations,
}: {
	indicator?: string;
	t: TFunction;
	form: any | undefined;
	quote: QuoteModel | undefined;
	preferredFacet: string | undefined;
	facetsConfig: Record<string, any>[];
	companyCode: string | undefined;
	languageCode: string | undefined;
	translations: any[];
}) => {
	const _summaryTableData: { name: string; value: number }[] = quote?.getAllGroupsImpactData(indicator) || [];

	const meanSummary = _.meanBy(_summaryTableData, 'value');
	const maxValueSummary = _.maxBy(_summaryTableData, 'value');
	const totalImpactQuote = _.sumBy(_summaryTableData, 'value');

	const summaryTableData = [..._summaryTableData, { name: t('En moyenne'), value: meanSummary }].map((e) => {
		return {
			...e,
			percent: (e.value / (maxValueSummary?.value || 1)) * 100,
		};
	});

	const orderedFacetsColors = [
		{
			bg: siteColors.primary10,
			text: siteColors.primary,
		},
		{
			bg: siteColors.orange10,
			text: siteColors.orange500,
		},
		{
			bg: siteColors.blue10,
			text: siteColors.blue500,
		},
		{
			bg: siteColors.purple10,
			text: siteColors.purple500,
		},
		{
			bg: siteColors.green10,
			text: siteColors.green500,
		},
		{
			bg: siteColors.grey200,
			text: siteColors.grey900,
		},
	];

	const facetsCounts = quote?.getFacetsCounts() || [];

	const groupsData = quote?.getGroupsDataPDF({ preferredFacet, companyCode, facetsConfig, indicator }) || [];

	const preferredFacetSummary = quote?.getPreferredFacetSummaryData({
		preferredFacet,
		companyCode,
		facetsConfig,
		indicator,
		t,
	});

	const customMessage = form?.getValues('customMessage');

	const otherFacetSummary =
		quote?.getOtherFacetsSummaryData({
			preferredFacet,
			companyCode,
			facetsConfig,
			indicator,
			t,
		}) || [];

	const lifeCycleMap = quote?.getDataLifeCycle(indicator) || new Map();

	const lifeCycleData = _.entries(lifeCycleConfig).map((entry) => {
		const [key, value] = entry;
		const foundItem = lifeCycleMap.get(key);
		return {
			...value,
			...foundItem,
			label: t(value.translationKey),
			key: value.translationKey,
		};
	});

	const _translations = {
		'Détails de votre prestation': t('Détails de votre prestation'),
		Par: t('Par'),
		'Impact total': t('Impact total'),
		'Analyse du cycle de vie de la prestation': t('Analyse du cycle de vie de la prestation'),
		'Sans nom': t('Sans nom'),
		'Impact carbone pour cette section': t('Impact carbone pour cette section'),
		total: t('total'),
		unités: t('unités'),
		'Devis carbone': t('Devis carbone'),
		'Devis carbone généré avec': t('Devis carbone généré avec'),
		'Impact carbone de la prestation': t('Impact carbone de la prestation'),
		'Émissions dans le détails': t('Émissions dans le détails'),
		'Mieux comprendre cette prestation': t('Mieux comprendre cette prestation'),
	} as const;

	const clientInterlocutorFullName = getUserFullName({
		lastName: form?.getValues('clientInterlocutorName') ?? form?.getValues('interlocutorName'),
		firstName: form?.getValues('clientInterlocutorFirstName') ?? form?.getValues('interlocutorFirstName'),
	});

	return {
		summaryPage: {
			title: form.getValues('name'),
			sender: {
				companyName: quote?.quote?.company?.get('name'),
				name: form?.getValues('interlocutorUserName'),
				email: form?.getValues('interlocutorUserEmail'),
				tel: form?.getValues('interlocutorUserTel'),
			},
			receiver: {
				companyName: form?.getValues('clientEnterpriseName'),
				name: clientInterlocutorFullName,
				address: form?.getValues('clientInterlocutorAddress') ?? form?.getValues('address'),
				postalCode: form?.getValues('clientInterlocutorPostalCode') ?? form?.getValues('postalCode'),
			},
			summaryTableData,
			totalImpactQuote,
			customMessage,
		},

		facetsPage: {
			facetsCounts,
			preferredFacetSummary,
			otherFacetSummary,
			lifeCycleData,
		},

		groupPage: {
			groupsData,
		},

		orderedFacetsColors,
		color500: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
		color400: indicator === WATER_INDICATOR ? siteColors.water400 : siteColors.carbon400,
		color100: indicator === WATER_INDICATOR ? siteColors.water100 : siteColors.carbon100,

		// update worker
		indicator,
		languageCode,
		translations,
		_translations,
	};
};

export const getPDFArgs = useQuote;

export const getUnits = (indicator = CARBON_INDICATOR) => {
	return {
		Unit1: indicator === WATER_INDICATOR ? WaterUnitM3 : CarbonUnitKg, // impact kg
		Unit3: indicator === WATER_INDICATOR ? WaterUnitM3perKg : CarbonUnitKgperKg, // intensity
	};
};

const CarbonUnitKg = (props: ReactPDF.TextProps & { subFontSize?: number }) => {
	return (
		<Text {..._.omit(props, ['subFontSize'])}>
			kgCO<Text style={{ verticalAlign: 'sub', fontSize: props.subFontSize }}>2</Text>e
		</Text>
	);
};

const WaterUnitM3 = (props: ReactPDF.TextProps & { subFontSize?: number }) => {
	return (
		<Text {..._.omit(props, ['subFontSize'])}>
			m<Text style={{ verticalAlign: 'super', fontSize: props.subFontSize }}>3</Text>
		</Text>
	);
};

// const CarbonUnitTon = (props: ReactPDF.TextProps & { subFontSize?: number }) => {
// 	return (
// 		<Text {..._.omit(props, ['subFontSize'])}>
// 			tCO<Text style={{ verticalAlign: 'sub', fontSize: props.subFontSize }}>2</Text>e
// 		</Text>
// 	);
// };

const CarbonUnitKgperKg = (props: ReactPDF.TextProps & { subFontSize?: number; t: TFunction }) => {
	return (
		<Text {..._.omit(props, ['subFontSize', 't'])}>
			kgCO<Text style={{ verticalAlign: 'sub', fontSize: props.subFontSize }}>2</Text>e / {props.t('unité')}
		</Text>
	);
};

const WaterUnitM3perKg = (props: ReactPDF.TextProps & { subFontSize?: number; t: TFunction }) => {
	return (
		<Text {..._.omit(props, ['subFontSize'])}>
			m<Text style={{ verticalAlign: 'super', fontSize: props.subFontSize }}>3</Text> / {props.t('unité')}
		</Text>
	);
};
