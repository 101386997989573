import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { create } from 'zustand';

import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import { type AddSupplierWithContacts, type RemoveSupplierContactById } from './details/_old//screens/suppliers-selection/QuestionFormSuppliersTableSelect';
import { CustomMessageRowData, type SupplierContactRowData, type UpdateContactData } from './details/_old/screens/suppliers-selection/QuestionFormSuppliersTable';

export type AddContactForSupplier = (options: { supplierId: string; isEdited?: boolean }) => void
export type RemoveSupplierById = (options: { supplierId: string }) => void;

type QuestionFormState = {
	supplierContacts: SupplierContactRowData[];
	setSuppliersContacts: (value: SupplierContactRowData[]) => void;
	updateContactData: UpdateContactData;
	addContactForSupplier: AddContactForSupplier;
	addSupplierWithContacts: AddSupplierWithContacts;
	removeSupplierContactById: RemoveSupplierContactById;
	removeSupplierById: RemoveSupplierById;

	supplierErrorImportContacts: SupplierContactRowData[];
	addErrorSupplierImport: (value: SupplierContactRowData) => void;

	customMessages: CustomMessageRowData[];
	setCustomMessages: (value: CustomMessageRowData[]) => void;

};

export const useQuestionFormStore = create<QuestionFormState>((set) => {
	return {
		supplierContacts: [],
		supplierErrorImportContacts: [],
		customMessages: [],
		updateContactData: ({ supplierId, contactId, values }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					const contactIndex = subRows.findIndex((iData) => {
						return iData.id === contactId;
					});

					if (contactIndex !== -1) {
						subRows[contactIndex] = {
							...subRows[contactIndex],
							...values,
						};
					}
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			});
		},
		addContactForSupplier: ({ supplierId, isEdited = false }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					supplierContacts[supplierIndex].subRows = [
						{
							id: nanoid(7),
							..._.pick(supplier, ['supplierName', 'supplierUid']),
							isEdited,
						},
						...subRows,
					];
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			});
		},
		setSuppliersContacts: (value: SupplierContactRowData[]) => {
			return set({ supplierContacts: value });
		},
		addSupplierWithContacts: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierContacts: [...state.supplierContacts, value],
				};
			});
		},
		removeSupplierContactById: ({ supplierId, contactId }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					const contactIndex = subRows.findIndex((iData) => {
						return iData.id === contactId;
					});

					if (contactIndex !== -1) {
						const left = _.slice(subRows, 0, contactIndex);
						const right = _.slice(subRows, contactIndex + 1, subRows.length);

						supplier.subRows = [...left, ...right];
					}
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			});
		},
		addErrorSupplierImport: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierErrorImportContacts: [...state.supplierErrorImportContacts, value],
				};
			});
		},
		setCustomMessages: (value: CustomMessageRowData[]) => {
			return set({ customMessages: value });
		},
		removeSupplierById: ({ supplierId }) => {
			return set((state) => {
				let supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const left = _.slice(supplierContacts, 0, supplierIndex);
					const right = _.slice(supplierContacts, supplierIndex + 1, supplierContacts.length);

					supplierContacts = [...left, ...right];
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			});
		},
	};
});

export const useGetQuestionFormById = ({ id }: { id: string }) => {
	return useQuery({
		queryKey: ['getQuestionFormById', { id }] as const,
		queryFn: async (context) => {
			try {
				const { id } = context.queryKey[1];
				//
				const questionForm = await new Parse.Query(classNames.QUESTION_FORM).select(['translation']).get(id);
				return questionForm.toJSON();
			} catch (error) {
				console.log('---- getQuestionFormById action error ---------------', error);
				return Promise.reject(error);
			}
		},
	});
};

export const useGetSuppliersContacts = ({ supplierUids, enabled }: { supplierUids: string[], enabled: boolean }) => {
	return useQuery({
		queryKey: ['getSuppliersContacts', { supplierUids }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUids } = ctx.queryKey[1];
				const results: Record<string, any> = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
				return results;
			} catch (error) {
				console.log('---- getSuppliersContacts action error ---------------', error);
				return Promise.reject(error);
			}
		},
		enabled,
	});
};

export const fetchSuppliersContacts = async ({ supplierUids }: { supplierUids: string[] }) => {
	try {
		// const { supplierUids } = ctx.queryKey[1];
		const results: Record<string, any>[] = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
		return results;
	} catch (error) {
		console.log('---- getSuppliersContacts action error ---------------', error);
		// return Promise.reject(error);
	}
};

export const fetchSuppliersAndContact = async ({ nameSuppliers, contactsEmail }: { nameSuppliers: string[], contactsEmail: string[] }) => {
	try {
		const suppliersPromise: Record<string, any> = Parse.Cloud.run(functionName.suppliers.getSuppliersByName, { nameSuppliers });
		const existingContactPromise: Record<string, any> = Parse.Cloud.run(functionName.users.getExistingUserByEmail, { contactsEmail });

		const [suppliers, existingContact] = await Promise.all([suppliersPromise, existingContactPromise]);

		return {
			suppliers,
			existingContact,
		};
	} catch (error) {
		console.log('---- nameSuppliers action error ---------------', error);
		// return Promise.reject(error);
	}
};

export const useFindCampaign = ({ companyId }: { companyId: string }) => {
	return useQuery({
		queryKey: ['findCampaign', { companyId }] as const,
		queryFn: async (ctx) => {
			try {
				const { companyId } = ctx.queryKey[1];

				const campaignQuery = new Parse.Query(classNames.QUESTION_CAMPAIGN)
					.include('questionForm')
					.descending('createdAt')
					.equalTo('company', `Company$${companyId}`);

				const campaigns = await campaignQuery.find({ json: true });
				return campaigns as unknown as Record<string, any>[];
			} catch (error) {
				console.log('--- findCampaign action error -----------------', error);
				return Promise.reject(error);
			}
		},
	});
};

export const useGetSentCampaignsCount = () => {
	return useQuery({
		queryKey: [functionName.questions.getSentCampaignsCount] as const,
		queryFn: async () => {
			try {
				return await Parse.Cloud.run(functionName.questions.getSentCampaignsCount);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};


export const useGetContactedSuppliersCount = () => {
	return useQuery({
		queryKey: [functionName.questions.getContactedSuppliersCount] as const,
		queryFn: async () => {
			try {
				return await Parse.Cloud.run(functionName.questions.getContactedSuppliersCount);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};


export const useGetReceivedResponsesCount = () => {
	return useQuery({
		queryKey: [functionName.questions.getReceivedResponsesCount] as const,
		queryFn: async () => {
			try {
				return await Parse.Cloud.run(functionName.questions.getReceivedResponsesCount);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};



export const useGetSentCampaignsCountDetails = ({ questionFormId }: { questionFormId: string; }) => {
	return useQuery({
		queryKey: [functionName.questions.getSentCampaignsCountDetails, { questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				return await Parse.Cloud.run(functionName.questions.getSentCampaignsCountDetails, { questionFormId });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};


export const useGetContactedSuppliersCountDetails = ({ questionFormId }: { questionFormId: string; }) => {
	return useQuery({
		queryKey: [functionName.questions.getContactedSuppliersCount, { questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				return await Parse.Cloud.run(functionName.questions.getContactedSuppliersCountDetails, { questionFormId });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};


export const useGetReceivedResponsesCountDetails = ({ questionFormId }: { questionFormId: string; }) => {
	return useQuery({
		queryKey: [functionName.questions.getReceivedResponsesCount, { questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				return await Parse.Cloud.run(functionName.questions.getReceivedResponsesCountDetails, { questionFormId });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});
};

export const useGetStatusPercentage = ({ questionFormId }: { questionFormId: string }) => {
	return useQuery({ queryKey: [functionName.questions.getStatusPercentagesForQuestionForm, { questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				const result =  await Parse.Cloud.run(functionName.questions.getStatusPercentagesForQuestionForm, { questionFormId });
				return result;
			} catch (error) {
				console.log('---- getStatusPercentagesForQuestionFormAction ---', error);
				return Promise.reject(error);
			}
		} });
};

