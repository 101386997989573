import { cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Info } from 'lucide-react';

import { functionName } from '@carbonmaps/shared/utils/constants';

import Skeleton from '../../../../../../../components/Skeleton';
import { cn, siteColors } from '../../../../../../../lib/colors';
import StatusChip from '../StatusChip';

const ContactsList = ({ objectIds }: { objectIds: string[] }) => {
	const { data, isLoading } = useQuery({
		queryKey: ['contacts', { objectIds }] as const,
		queryFn: async (ctx) => {
			try {
				const { objectIds } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.questions.findContactsListForSupplierTracking, { objectIds });
			} catch (error) {
				console.log('--- fetchContacts action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: _.isArray(objectIds) && !_.isEmpty(objectIds),
	});

	if (isLoading) {
		return <Skeleton height={40} />;
		}


	return (
		<div
			css={{
				border: cn('1px solid', siteColors.grey400),
				boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
				borderRadius: '4px',
				['& > .contact-item' as never]: {
					padding: '6px 6px 6px 12px',
					borderBottom: cn('1px solid', siteColors.grey400),
					'&:last-child': {
						borderBottom: 'none',
					},
				},
			}}
		>
			{_.map(data, (item, index) => {
				return (
					<div className={cx('contact-item flexRow spaceBetween alignCenter')} key={index}>
						<div>{_.get(item, 'contact.email')}</div>

						<div className={cx('flexRow alignCenter grey700')} css={{ gap: 16 }}>
							{_.get(item, 'isError')
? (
															<div css={{ position: 'relative', top: 4 }}><Info color={siteColors.orange500} size={20} /></div>

							)
: null}
							<div>{dayjs(_.get(item, 'actionDate')).format('DD/MM/YYYY')}</div>
							<div>
								<StatusChip statusType={_.get(item, 'statusType')} />
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ContactsList;
