
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../hooks/useTranslation';
import Typography from '../Typography';

const ProductCount = ({ value, isLoading = false }: { value?: number, isLoading: boolean }) => {

	const { t } = useTranslation();
	return isLoading ? null : (
		<Typography className="number grey900">
			{formatNumber(value, undefined, 0)} {toLowerCase(t('products'))}
		</Typography>
	);
};

export default ProductCount;
