import { type QueryFunctionContext } from '@tanstack/react-query';

import { IIngredient } from '@carbonmaps/shared/types/ingredient.types';
import { IProduct } from '@carbonmaps/shared/types/product.types';
import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getFacetToShow } from '../../../apps/front/src/utils/utils';

export type GetParams = {
	size: number;
	page: number;
	direction?: number;
	id?: string;
	input?: string;
	facetFilters?: { values: string[]; path: string }[] | [];
	facetFiltersSelected?: { values: string[]; path: string }[] | [];
	sortingBy?: any;
};

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                       Queries                                        //
//                                                                                      //
//--------------------------------------------------------------------------------------//

export const getProductAction = async (context: QueryFunctionContext<readonly ['getProduct']>): Promise<any[]> => {
	try {
		const result = await Parse.Cloud.run('getProductByCategory', {});

		return result;
	} catch (error) {
		console.log(' ------ getProductAction error: ', error);
		return Promise.reject(error);
	}
};

// type FacetFilter = { values: string[]; path: string }

type GetCategoriesProductQueryParams = {
	input?: string;
	company?: string;
	facetFilters: IFacetFilter[];
	// in ingredient sheet
	ingredientId?: string;
	codeIngCmaps?: string;
	reportClientId?: string;
	category?: string;
	itemType?: string;
	path?: string;
	withGlobal?: boolean;
	packagingId?: string;
	withEcoConcevable?: boolean;
	period?: number;
	slugClient?:string;
};

export type GetCategoriesProductResultData = {
	count: {
		lowerBound: number;
	};
	facet: Record<
		string,
		{
			buckets: {
				_id: string | number;
				count: number;
			}[];
		}
	>;
};

/**
 * facets
 * @param context
 * @returns
 */
export const getCategoriesProduct = async (
	context: QueryFunctionContext<readonly ['getCategory', GetCategoriesProductQueryParams]>,
): Promise<GetCategoriesProductResultData> => {
	try {
		const {
			queryKey: {
				1: {
					input: search,
					packagingId,
					ingredientId,
					codeIngCmaps,
					facetFilters,
					category,
					itemType,
					path,
					withGlobal,
					withEcoConcevable,
					period,
					reportClientId,//todo remove
					slugClient,
				},
			},
		} = context;
		const params = {
			search,
			facetFilters,
			category,
			codeIngCmaps,
			ingredientId,
			packagingId,
			itemType,
			path,
			withGlobal,
			withEcoConcevable,
			period,
			reportClientId, //todo remove
			slugClient
		};

		const data = await Parse.Cloud.run(functionName.product.getFacets, params);

		// For a facet with a value already selected, I may be able to select another value
		// and see the products (OR ingredient) of one OR the other of the facet values appear
		if (facetFilters?.length && data?.length) {
			const allData = await Parse.Cloud.run(functionName.product.getFacets, {
				...params,
				facetFilters: [],
			});

			return getFacetToShow(facetFilters, data[0], allData[0]);
		}

		return data[0];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getProductSheetAction = async (
	context: QueryFunctionContext<readonly ['getProductSheet', { objectId: string }]>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId }],
		} = context;

		const product = await new Parse.Query(classNames.PRODUCT).get(objectId);

		return product.toJSON();
	} catch (error) {
		return Promise.reject(error);
	}
};

export type GetProductSheetNewActionData = {
	product: IProduct;
	ingredients: /* any  */ IIngredient[];
	emballages: any /*  IPackaging[] */;
	suppliers: {
		name: string;
		uid: string;
		itemType: 'product' | 'ingredient';
	}[];
	simulations?: any[];
};

export const getProductSheetNewAction = async (
	context: QueryFunctionContext<
		readonly ['getProductSheetNew', { objectId: string; sortingBy?: any; viewMode?: string; fromPath?: string }]
	>,
): Promise<GetProductSheetNewActionData> => {
	try {
		const {
			queryKey: [, { objectId, sortingBy, viewMode, fromPath }],
		} = context;

		// const product = await Parse.Cloud.run('getProductSheetNew', { productId: objectId }) as Parse.Object<IProductFinal>/*  | undefined */;
		const productSheetData = (await Parse.Cloud.run('getProductSheetNew', {
			productId: objectId,
			sortingBy,
			viewMode,
			fromPath,
		})) as GetProductSheetNewActionData; // Everything must be already JSONs from the server.
		// const product = await new Parse.Query(classNames.FINAL_PRODUCT_NEW).get(objectId);

		// return product.toJSON() as any;
		return productSheetData;
	} catch (error) {
		console.log(' ------ getProductSheetNewAction error: ', error);
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                         get data for one product with time period                    //
//--------------------------------------------------------------------------------------//
export const getProductSheetTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly [
			'getProductSheetTimePeriod',
			{ objectId: string; period?: string | number; sortingBy?: any; viewMode?: string; fromPath?: string },
		]
	>,
): Promise<GetProductSheetNewActionData> => {
	try {
		const {
			queryKey: [, { objectId, sortingBy, viewMode, fromPath, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOneProduct, {
			productId: objectId,
			period,
			sortingBy,
			viewMode,
			fromPath,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getProductInfoAction = async (
	context: QueryFunctionContext<
		readonly [
			'getProductInfo',
			{ objectId: string; period?: string | number; sortingBy?: any; viewMode?: string; fromPath?: string },
		]
	>,
): Promise<GetProductSheetNewActionData> => {
	try {
		const {
			queryKey: [, { objectId, sortingBy, viewMode, fromPath, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getProductInfo, {
			productId: objectId,
			period,
			sortingBy,
			viewMode,
			fromPath,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export type getImpactProductDataType = {
	carbonImpact?: number;
	carbonIntensity?: number;
	waterImpact?: number;
	waterIntensity?: number;
	carbonImpactPortion?: number;
	waterImpactPortion?: number;
};

export const getProductImpactAction = async (
	context: QueryFunctionContext<
		readonly ['getProductImpact', { objectId: string; period?: string | number; viewMode?: string; uid?: string }]
	>,
): Promise<getImpactProductDataType> => {
	try {
		const {
			queryKey: [, { objectId, viewMode, uid, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getProductImpact, {
			productId: objectId,
			period,
			uid,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export type LifeChartProductData = {
	carbonImpact: number;
	waterImpact: number;
	waterIntensity: number;
	carbonIntensity: number;
	gesAgriculture: number;
	gesAgricultureImpact: number;
	gesTransformation: number;
	gesTransformationImpact: number;
	gesPackagingImpact: number;
	gesPackaging: number;
	gesTransportImpact: number;
	gesTransport: number;
	gesDistributionImpact: number;
	gesDistribution: number;
	gesConsumptionImpact: number;
	gesConsumption: number;

	waterUseAgriculture: number;
	waterUseAgricultureImpact: number;
	waterUseTransformation: number;
	waterUseTransformationImpact: number;
	waterUsePackaging: number;
	waterUsePackagingImpact: number;
	waterUseTransport: number;
	waterUseTransportImpact: number;
	waterUseDistribution: number;
	waterUseDistributionImpact: number;
	waterUseConsumption: number;
	waterUseConsumptionImpact: number;
};

export const getLifeChartProduct = async (
	context: QueryFunctionContext<
		readonly ['getProductLifeChart', { productId: string; period?: string | number; viewMode?: string; uid?: string }]
	>,
): Promise<LifeChartProductData> => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getLifeChartProduct, {
			productId,
			period,
			uid,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export type ModelizationProduct = {
	typeModelizationCompoIngredient: string;
	tagAdvancedModelization: string;
	typeModelizationCompoPackaging: string;
	ingredients: any;
	codeCmaps: string;
	bddRef: string;
	packaging?: any;
	gesAgriculture: number;
	gesTransformation: number;
	gesPackaging: number;
	gesTransport: number;
	gesDistribution: number;
	gesConsumption: number;

	gesTotal: number;

	waterUseAgriculture: number;
	waterUseTransformation: number;
	waterUsePackaging: number;
	waterUseTransport: number;
	waterUseDistribution: number;
	waterUseConsumption: number;
	waterUseTotal: number;

	typeModelization: string;
	uidProduct?: string;
	product?: Record<string, any>;
};

export const getModelizationProductAction = async (
	context: QueryFunctionContext<
		readonly [
			'getModelizationProduct',
			{ productId: string; period?: string | number; viewMode?: string; uid?: string },
		]
	>,
): Promise<ModelizationProduct> => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getModelizationProduct, {
			productId,
			period,
			uid,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getRecycledMaterialsProduct = async (
	context: QueryFunctionContext<
		readonly [
			'getRecycledMaterialsProduct',
			{ productId: string; period?: string | number; viewMode?: string; uid?: string },
		]
	>,
) => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getRecycledMaterialsProduct, {
			productId,
			period,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getACVPackagingProduct = async (
	context: QueryFunctionContext<
		readonly [
			'getACVPackagingProduct',
			{ productId: string; period?: string | number; viewMode?: string; uid?: string },
		]
	>,
) => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getACVPackagingProduct, {
			productId,
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getIngredientsProductAction = async (
	context: QueryFunctionContext<
		readonly ['getIngredientsProduct', { productId: string; period?: string | number; viewMode?: string; uid?: string }]
	>,
): Promise<ModelizationProduct> => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getIngredientsProduct, {
			productId,
			period,
			uid,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getPackagingProductAction = async (
	context: QueryFunctionContext<
		readonly ['getPackagingProduct', { productId: string; period?: string | number; viewMode?: string; uid?: string }]
	>,
): Promise<ModelizationProduct> => {
	try {
		const {
			queryKey: [, { productId, viewMode, period, uid }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getPackagingProduct, {
			productId,
			period,
			uid,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};






export type PopoverProductData = {
	percent: number;
	supplierFilledCount: number;
	supplierCount: number;
};

export const getDataPopoverProductAction = async (
	context: QueryFunctionContext<readonly ['getDataPopoverProduct', { productId?: string }]>,
): Promise<PopoverProductData> => {
	try {
		const {
			queryKey: [, { productId }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.product.getDataPopoverProduct, {
			productId,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getProductInAllVersionAction = async (
	context: QueryFunctionContext<readonly ['getProductInAllVersion', { productId: string }]>,
) => {
	try {
		const {
			queryKey: [, { productId }],
		} = context;
		const result = await Parse.Cloud.run('getProductInAllVersion', { productId });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                      Mutations                                       //
//                                                                                      //
//--------------------------------------------------------------------------------------//

type UpdateProductSheetHeaderInput = {
	title: string;
	reference: string;
	objectId: string;
};

export const updateProductSheetHeaderAction = async ({ title, reference, objectId }: UpdateProductSheetHeaderInput) => {
	try {
		const result = await Parse.Cloud.run('updateProductSheetHeader', { title, reference, objectId });

		return result;
	} catch (error) {
		console.log('------updateProductSheetHeaderAction error------------------');
		console.log('====================================');
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                              count product for company                               //
//--------------------------------------------------------------------------------------//
export const productCompanyCount = async (
	context: QueryFunctionContext<readonly ['getProductCompanyCount', Record<string, any>]>,
) => {
	try {
		const params = context.queryKey[1];

		if (params.paramType === 'codeCmaps') {
			const query = new Parse.Query(classNames.INGREDIENT)
				.equalTo('codeIngCmaps', params.ingredientId)
				.select([])
				.equalTo('update', params.update);
			const ing = await query.first();

			params.ingredientId = ing?.id;
		}

		// const count = await Parse.Cloud.run('getProductCount');
		const count = await Parse.Cloud.run(functionName.temporality.countProductByPeriod, { ...params });
		return count;
	} catch (error) {
		return Promise.reject(error);
	}
};

//----------------------------------------------------------------------------------------------------//
//                           Data to display in product list for a company                            //
//----------------------------------------------------------------------------------------------------//
/**
 * @deprecated
 */
export const getProductCompany = async (context: QueryFunctionContext) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context as any;
		const { data, count } = await Parse.Cloud.run('findProduct', {
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

export type IFacetFilter = {
	path: string;
	type: string;
	global?: boolean;
	values: (string | number)[];
};
// export type GetProductTableQueryParams = {
// 	input: string;
// 	page: number;
// 	size: number;
// 	facetFilters: IFacetFilter[];
// 	supplierIds: string[]; // Suppliers filter
// 	direction: number; // ? is this used anyway?
// 	viewMode?: string;
// 	viaQuote?: boolean;
// 	packagingId?: string;
// };

export const getProductTableDataAction = async (
	context: QueryFunctionContext<readonly ['getProductTableData', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, viewMode, viaQuote, ...rest },
			},
		} = context;

		const result: {
			products: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(
			//
			functionName.temporality.findProductTable,
			{
				limit: size,
				skip: (page - 1) * size,
				pageIndex: page,
				viewMode,
				viaQuote,
				...rest,
			},
		);

		return {
			data: result.products,
			meta: {
				last_page: result.meta.last_page,
				count: result.meta.count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getProductTableDataActionPackagingUses = async (
	context: QueryFunctionContext<readonly ['getProductTableDataPackagingUses', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, viewMode, viaQuote, ...rest },
			},
		} = context;

		const result: {
			products: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(
			//
			functionName.temporality.findProductTablePackagingUses,
			{
				limit: size,
				skip: (page - 1) * size,
				pageIndex: page,
				viewMode,
				viaQuote,
				...rest,
			},
		);

		return {
			data: result.products,
			meta: {
				last_page: result.meta.last_page,
				count: result.meta.count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

// //--------------------------------------------------------------------------------------//
// //                             data graph tradeoff product                              //
// //--------------------------------------------------------------------------------------//
// export const getDataProductGraph = async (
// 	context: QueryFunctionContext<['getDataProductGraph', { searchQueryParams: any }]>,
// ) => {
// 	try {
// 		const {
// 			queryKey: {
// 				1: { input, facetFilters },
// 			},
// 		} = context as any;

// 		const result = await Parse.Cloud.run('findProduct', { input, facetFilters, unity: 1000 });
// 		return result;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// type GetProductTradeoffQueryParams = {
// 	input: string;
// 	facetFilters: IFacetFilter[];
// 	supplierIds: string[];
// 	packagingId?: string;
// };

export const getProductTradeoffDataAction = async (
	context: QueryFunctionContext<readonly ['getProductTradeoffData', Record<string, any>]>,
) => {
	try {
		const params = context.queryKey[1];

		const result = await Parse.Cloud.run(functionName.temporality.findProduct, {
			view: 'general-view-of-products->products-tradeoff',
			// input, facetFilters, supplierIds, unity: 1000, packagingId
			...params,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                             data autocomplete                                       //
//--------------------------------------------------------------------------------------//

type GetProductOptionsAutocompleteQueryParams = {
	input: string;
	facetFilters: IFacetFilter[];
	supplierIds: string[];
	period?: number;
};

export const getProductOptionsAutocompleteDataAction = async (
	context: QueryFunctionContext<readonly ['getOptionsAutocompleteData', GetProductOptionsAutocompleteQueryParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { input, facetFilters, period },
			},
		} = context;

		const { data } = await Parse.Cloud.run(functionName.temporality.findProductForEcoConceptionAutocomplete, {
			input,
			facetFilters,
			limit: 100,
			period,
		});

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                             list period                                              //
//--------------------------------------------------------------------------------------//

export const getListPeriodAction = async (context: QueryFunctionContext<readonly ['getListPeriod', any]>) => {
	try {
		// const {
		// 	queryKey: {
		// 		1: { limit, view },
		// 	},
		// } = context;

		const data = await Parse.Cloud.run(functionName.temporality.findPeriod);
		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};
