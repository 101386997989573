import { ReactNode } from 'react';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { type Theme as MuiTheme, type PaletteOptions } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme, { type ThemeOptions } from '@mui/material/styles/createTheme';
import { type TypographyOptions } from '@mui/material/styles/createTypography';
import _ from 'lodash';

import { GlobalStyles } from '@carbonmaps/ui/styles/GlobalStyles';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { siteColors } from '../lib/colors';

declare module '@emotion/react' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface Theme extends MuiTheme {}
}

//--------------------------------------------------------------------------------------//
//                                      palette                                         //
//--------------------------------------------------------------------------------------//
const theme = createTheme();
const {
	palette: { augmentColor },
} = theme;

declare module '@mui/material/styles' {
	interface Theme {
		parts: typeof parts;
	}

	interface Palette {
		border: {
			default: string;
			light: string;
			dark: string;
		};
	}

	interface PaletteOptions {
		border?: {
			default?: string;
			light?: string;
			dark?: string;
		};
	}
}

//--------------------------------------------------------------------------------------//
//                                       palette                                        //
//--------------------------------------------------------------------------------------//
const palette: PaletteOptions = {
	primary: augmentColor({
		color: {
			main: siteColors.primary,
			dark: siteColors.primaryHover,
		},
	}),

	warning: augmentColor({ color: { main: '#C74600', light: '#FEF7F1' } }),

	background: {
		default: siteColors.common.white,
		paper: siteColors.common.white,
	},

	text: {
		primary: siteColors.text,
		secondary: siteColors.grey800,
		disabled: siteColors.grey600,
	},

	border: {
		default: siteColors.grey500,
		light: siteColors.grey300,
	},

	grey: {
		100: siteColors.grey100,
		200: siteColors.grey200,
		300: siteColors.grey300,
		400: siteColors.grey400,
		500: siteColors.grey500,
		600: siteColors.grey600,
		700: siteColors.grey700,
		800: siteColors.grey800,
		900: siteColors.grey900,
	},
};

theme.palette = _.merge({}, theme.palette, palette);

const parts = {
	Card: {
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '16px',
		padding: '24px',
		borderColor: theme.palette.grey['500'],
	},
};

theme.parts = parts;

//--------------------------------------------------------------------------------------//
//                                      typography                                      //
//--------------------------------------------------------------------------------------//
const PRIMARY_FONT = ['Inter'].join(',');
// const PRIMARY_FONT = ['Arial'].join(',');
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_SEMI_BOLD = 500;
const FONT_WEIGHT_BOLD = 600;

const defaultTypographyStyles = {
	fontWeight: 400,
	fontStyle: 'normal',
	fontFamily: PRIMARY_FONT,
	color: theme.palette.text.primary,
	letterSpacing: 0,
};

const titleTypographyStyles = {
	...defaultTypographyStyles,
	fontWeight: FONT_WEIGHT_BOLD,
};

const typography: TypographyOptions = {
	fontFamily: PRIMARY_FONT,
	fontWeightRegular: FONT_WEIGHT_REGULAR,
	fontWeightBold: FONT_WEIGHT_BOLD,
	h1: {
		...titleTypographyStyles,
		fontSize: pxToRem(32),
	},
	h2: {
		...titleTypographyStyles,
		fontSize: pxToRem(24),
	},
	h3: {
		...titleTypographyStyles,
		fontSize: pxToRem(16),
	},
	h4: {
		...titleTypographyStyles,
		fontSize: pxToRem(14),
	},
	h5: {
		...defaultTypographyStyles,
		fontSize: pxToRem(13),
	},
	subtitle1: {
		...defaultTypographyStyles,
		fontSize: pxToRem(32),
	},
	subtitle2: {
		...defaultTypographyStyles,
		fontSize: pxToRem(24),
	},
	body1: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
	},
	body2: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
		fontWeight: FONT_WEIGHT_BOLD,
	},
	caption: {
		...defaultTypographyStyles,
		fontSize: pxToRem(12),
	},
	button: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
		fontWeight: FONT_WEIGHT_SEMI_BOLD,
	},
	overline: {
		...defaultTypographyStyles,
		fontSize: pxToRem(13),
		fontWeight: FONT_WEIGHT_BOLD,
		textTransform: 'uppercase',
	},
};

theme.typography = _.merge({}, theme.typography, typography);

//--------------------------------------------------------------------------------------//
//                                  components options                                  //
//--------------------------------------------------------------------------------------//
const components: ThemeOptions['components'] = {};

theme.components = _.merge({}, theme.components, components);

type Props = {
	children: ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
	const appTheme = createTheme(theme);

	return (
		<EmotionThemeProvider theme={appTheme}>
			<GlobalStyles theme={appTheme} />
			<CssBaseline />
			{children}
		</EmotionThemeProvider>
	);
};

export default ThemeProvider;
